import { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useParams } from "react-router-dom";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faArrowLeft, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Radio } from 'antd';

import DashboardSeverityIndicator from "../../shared/sharedComponents/dashboardSeverityIndicator";
import DastFileTabView from './dastFileTabView';
import DastVulnerabilityTabView from './dastVulnerabilityTabView';
import DastHistoryTabView from './dastHistoryTabView';

import TrendGraph from './trendGraph';
import Loader from '../../shared/sharedComponents/loader';
// import { getAssetName, getDummyHeader } from '../../shared/helper/genHelper';
import moment from 'moment';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import WelcomeLoader from "../welcomeScreen/loader";
import { useSelector } from 'react-redux';
import RescanConfigModal from '../../shared/sharedComponents/dastSharedComponents/rescanConfigModalHelper';
import URLDiscoveryTabView from './urlDiscoveryTabView';

export default function DastAdvanceDashboard() {

    const { id } = useParams();
    const reduxStore = useSelector(state => state);

    const [loadingRows, setLoadingRows] = useState(false);
    const [activeTabsKey, setActiveTabsKey] = useState('vulnerabilityView');

    const [showLoader, setShowLoader] = useState(false);

    const [reportDetails, setReportDetails] = useState({});

    const [trendValue, setTrendValue] = useState('7');
    const [userPrefrences, setUserPrefrences] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    useEffect(() => {
        setShowLoader(true);
        if (id) {
            getAdvDastDashboardData(id);
        }
    }, [id]);

    useEffect(() => {
        if (!_.isEmpty(reduxStore) && !_.isEmpty(reduxStore.authReducer) && !_.isEmpty(reduxStore.authReducer.userDetails) && !_.isEmpty(reduxStore.authReducer.userDetails.email)) {
            getPrefrences(reduxStore.authReducer.userDetails.email);
        }
    }, [reduxStore])

    const getAdvDastDashboardData = (reportId) => {
        const postBody = { "reportId": reportId };
        axios
            .post(`${GlobalConst.API_URL}/auth/dast/asset/report/file-view`, postBody)
            .then((op) => {
                setShowLoader(false);
                // console.log("Output is ::", op);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setReportDetails(op.data);
                }
            })
            .catch((e) => {
                setShowLoader(false);
                console.log("Exception: ", e);
                setReportDetails({});
            });
    }

    const getPrefrences = (userId) => {
        const postObj = { userId: userId };
        axios
            .post(
                `${GlobalConst.API_URL}/auth/foss/user/get-preference`,
                postObj
            )
            .then((op) => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setUserPrefrences(op.data);
                }
            })
            .catch((e) => { });
    };

    const getTotal = (vulCount) => {
        //console.log("vulCount : ", vulCount);
        return (vulCount.critical + vulCount.high + vulCount.medium + vulCount.low);
    }

    const handleRescanClick = (e) => {
        // console.log("reportDetails : ", reportDetails);
        e.preventDefault();
        if (!_.isEmpty(reportDetails)) {
            setLoadingRows(true);
            const postBody = { "configId": reportDetails.configId, "url": reportDetails.assetUrl };
            axios
                .post(`${GlobalConst.API_URL}/auth/dast/rescan`, postBody)
                .then((op) => {
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        handleStatusCheck(op.data);
                    }
                })
                .catch((e) => {
                    console.log("Exception: ", e);
                });
        }
    }
    const handleConfigClick = (e) => {
        if (!_.isEmpty(reportDetails)) {
            // reportDetails.configId = '66a799b9ce6443e11329b65a';
            const params = { "configId": reportDetails.configId };
            axios
                .post(`${GlobalConst.API_URL}/auth/dast/scan/config/fetch`, params)
                .then((op) => {
                    // console.log("response : ", op.data);
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        // console.log("Output", op.data);
                        selectedData.configType = op.data.authType;

                        if (op.data.authType == "OAUTH2") {
                            selectedData.details = op.data["oauth2"];
                        } else if (op.data.authType == "HEADERS") {
                            selectedData.details = { headers: op.data["headers"] }
                        }
                        if (selectedData.details) {
                            selectedData.details.url = op.data.url;
                            selectedData.details.scanType = op.data.scanType;
                            if ("api" === op.data.scanType) {
                                selectedData.details.fileId = op.data.fileId;
                                selectedData.details.fileName = op.data.fileName;
                            }
                            selectedData.details.configId = op.data.configId;
                            setSelectedData(selectedData);
                            
                        }
                        setIsModalVisible(true);
                    }
                })
                .catch((e) => {
                    console.log("Exception: ", e);
                });
        }
    }
    const handleConfigCloseModal = () => {
        setIsModalVisible(false);
        setSelectedData({});
    };

    const handleStatusCheck = (scannedRecordData) => {
        if (!_.isEmpty(scannedRecordData) && !_.isEmpty(scannedRecordData[0])) {
            const postBody = { reportId: [scannedRecordData[0].uuid] };
            const poll = () => {
                axios
                    .post(`${GlobalConst.API_URL}/auth/dast/asset/report/status`, postBody)
                    .then(op => {
                        if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data[0])) {
                            if (op.data[0].scanStatus.toLowerCase() === 'scanning' || op.data[0].scanStatus.toLowerCase() === 'initiated') {
                                setTimeout(poll, GlobalConst.DAST_POLLING_LIMIT);
                            } else {
                                setLoadingRows(false);
                                // window.location.reload();
                                window.location.href = `/dast-advance-dashboard/${op.data[0].reportId}`;
                            }
                        }
                    })
                    .catch(e => { console.log("Exception: ", e) });
            };
            poll();
        }
    };


    const handleTrendFilterChange = (e) => {
        setTrendValue(e.target.value);
    }

    const goBack = (e) => {
        if (window.opener && !window.opener.closed) {
            window.opener.focus();
            window.close();
        } else {
            window.location.href = '/dast-dashboard';
        }
    }

    if (showLoader) {
        return (<Loader />);
    }

    if (_.isEmpty(reportDetails)) {
        return (<>No data to display</>);
    }
    else {
        //  console.log("reportdetails : ", reportDetails);
        return (
            <section className="mx-3">
                <section className="mb-3 d-flex align-items-center justify-content-start">
                    <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start me-2">
                        <a className='me-2' onClick={goBack} href='#' style={{ fontSize: 22 }}><small ><FontAwesomeIcon icon={faArrowLeft} /></small></a><span className='ps-2' style={{ borderLeft: '1px solid rgba(108, 117, 125, 15%' }}>DAST Scan Report</span>
                    </h2>
                    <Button
                        onClick={handleRescanClick}
                        className="custom-button btn-sm ms-3"
                        loading={loadingRows}
                    >
                        <FontAwesomeIcon icon={faArrowsRotate} className="me-2" />
                        Re-Scan
                    </Button>
                    <Button
                        onClick={handleConfigClick}
                        className="custom-button btn-sm ms-3"
                        loading={loadingRows}
                    >
                        Edit Config
                    </Button>

                    {loadingRows && (
                        <span className="d-flex align-items-center justify-content-start ms-3">
                            <WelcomeLoader />{" "}
                            <small className="ms-1" style={{ fontSize: 14, fontWeight: 200 }}>
                                Scanning...
                            </small>
                        </span>
                    )}
                </section>
                <section>
                    <div className="d-flex align-items-center justify-content-start">
                        <span><FontAwesomeIcon icon={faGithub} className="me-1" /></span>
                        <span className="me-2"><a href={reportDetails.assetUrl} target='_blank' > {reportDetails.assetUrl}</a></span>
                        <span className="text-secondary"><small><i>(<strong>Last Scan:</strong>  {moment.utc(reportDetails.scanDateTime).local().fromNow()})</i></small></span>
                    </div>
                </section>
                <section className='mb-3'>
                    <div className="row">
                        <div className="col-lg-6 custom-border-right ">
                            <div className="py-3 d-flex align-items-center w-100 h-100">
                                <div className="card-body">
                                    <div className="mb-3 border-0">
                                        {/* <h6 className="fw-medium"><small>Total Statistics</small></h6> */}
                                    </div>
                                    <div className="d-flex flex-row align-items-start justify-content-between">
                                        <div className="me-3">
                                            <p className="mb-0 text-secondary" style={{ fontSize: 12 }}>Total Vulnerabilities</p>
                                            <h1 className="head-tag" style={{ lineHeight: 1.1 }}>{getTotal(reportDetails.vulnerabilityCounts)}</h1>
                                        </div>
                                        <div className="d-flex flex-wrap flex-row align-items-start justify-content-center">
                                            <div className="me-3 mb-2">
                                                <DashboardSeverityIndicator severity={'Critical'} loadingRows={loadingRows} dashboardData={reportDetails.vulnerabilityCounts.critical} />
                                            </div>
                                            <div className="me-3 mb-2">
                                                <DashboardSeverityIndicator severity={'High'} loadingRows={loadingRows} dashboardData={reportDetails.vulnerabilityCounts.high} />
                                            </div>
                                            <div className="me-3 mb-2">
                                                <DashboardSeverityIndicator severity={'Medium'} loadingRows={loadingRows} dashboardData={reportDetails.vulnerabilityCounts.medium} />
                                            </div>
                                            <div className="me-3 mb-2">
                                                <DashboardSeverityIndicator severity={'Low'} loadingRows={loadingRows} dashboardData={reportDetails.vulnerabilityCounts.low} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="p-2">
                                <div className="card-body">
                                    {/* <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                        <h5 className="fw-medium">Repository Vulnerability Trend</h5>
                                    </div> */}
                                    <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                        <h5 className="fw-medium">Repository Vulnerability Trend</h5>
                                        <div>
                                            <Radio.Group value={trendValue} size="small" buttonStyle="solid" onChange={(e) => handleTrendFilterChange(e)}>
                                                <Radio.Button value="7" style={{ fontSize: 12 }}>1 Week</Radio.Button>
                                                <Radio.Button value="14" style={{ fontSize: 12 }}>2 Weeks</Radio.Button>
                                                <Radio.Button value="30" style={{ fontSize: 12 }}>1 Month</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </div>


                                    <div style={{ height: 300 }}>
                                        <TrendGraph reportDetails={reportDetails} trendValue={trendValue} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{position: 'relative'}}>
                    <Tabs
                        className="mb-3 report-tabs"
                        activeKey={activeTabsKey}

                        onSelect={(k) => setActiveTabsKey(k)}
                        //   className="mt-4 mb-3 report-tabs text-white"
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        <Tab eventKey="vulnerabilityView" title="Vulnerability View">
                            <DastVulnerabilityTabView reportDetails={reportDetails} userPrefrences={userPrefrences} reportId={id} />
                        </Tab>
                        <Tab eventKey="fileView" title="File View">
                            <DastFileTabView reportDetails={reportDetails} userPrefrences={userPrefrences} reportId={id} />
                        </Tab>

                        <Tab eventKey="urlDiscovery" title="URL Discovery">
                            {/* <DastVulnerabilityTabView reportDetails={reportDetails} userPrefrences={userPrefrences} /> */}
                            <URLDiscoveryTabView reportId={id} configId={reportDetails.configId} />
                        </Tab>
                        <Tab eventKey="history" title="History">
                            <DastHistoryTabView reportDetails={reportDetails} id={id} />
                        </Tab>
                    </Tabs>
                </section>
                

                {isModalVisible && <RescanConfigModal
                    details={selectedData ? selectedData.details : {}}
                    configType={selectedData ? selectedData.configType : ""}
                    visible={isModalVisible}
                    onClose={handleConfigCloseModal}
                />}
                {showLoader && <Loader />}
            </section>
        );
    }
}