import React from 'react';
import { Button, Tag, Tooltip } from 'antd';
import { getStrokeColor, getFontColor } from '../../../shared/helper/genHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

const VulnerabilityContent = ({ counts, handleViewComponentDetails }) => {
    if (!counts) return null;

    return (
        <div className="d-flex align-items-center gap-2">
            <div className="d-flex align-items-center">
                {Object.entries(counts).map(([severity, count], index) => count > 0 && (
                    <Tooltip key={severity} title={`${count} ${severity}`}>
                        <Tag
                            color={getStrokeColor(severity)}
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                color: getFontColor(severity),
                                cursor: 'default',
                                fontSize: 10,
                                borderRadius: '50%',
                                width: 25,
                                height: 25,
                            }}
                        >
                            {count}
                        </Tag>
                    </Tooltip>
                ))}
            </div>
            {/* {totalVulnerabilities > 0 && (
                <div className="d-flex align-items-center small">
                    <Tooltip title={canUpgrade ? 'Click to fix vulnerabilities' : 'Upgrade not available'}>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onUpgrade?.();
                            }}
                            disabled={!canUpgrade}
                            className={`btn btn-warning d-flex align-items-center py-1 px-2`}
                        >
                            <FontAwesomeIcon className='me-1' icon={faArrowCircleUp} />
                            <small>Fix ({totalVulnerabilities})</small>
                        </button>
                    </Tooltip>
                </div>
            )} */}
            <div className='d-flex align-items-center'>
                <Button
                    type="primary"
                    onClick={handleViewComponentDetails}
                    className="mx-3 fix-btn">
                    <small>View Details</small>
                </Button>
            </div>
        </div>
    );
};

export default VulnerabilityContent