import React, { useEffect, useState, useRef } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip, Tag, Space, Table } from "antd";
import Highlighter from "react-highlight-words";

import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RiQrScan2Line } from "react-icons/ri";
import { AiOutlineDashboard } from "react-icons/ai";
import { MdAutoFixHigh } from "react-icons/md";
import { addGuid, setScanModalViewState, updateScannedRecord, setScanGuidResponse, setScannedRecords, setCurrentDashboardPageNumber, setSanningFromDrag } from "../../shared/redux/reducers/scannerReducer";
import { getStrokeColor, getFontColor } from "../../shared/helper/genHelper";
import Loader from "../welcomeScreen/loader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

import cli from '../../assets/img/scopy-tech-icons/cli.svg';
import awsCi from '../../assets/img/scopy-tech-icons/aws.svg';
import gcpCi from '../../assets/img/scopy-tech-icons/gcp.svg';
import jenkins from '../../assets/img/scopy-tech-icons/jenkins.svg';
import circleci from '../../assets/img/scopy-tech-icons/circleci.svg';
import bamboo from '../../assets/img/scopy-tech-icons/bamboo.svg';
import azure from '../../assets/img/scopy-tech-icons/azure.svg';
import { TbBinaryTree } from "react-icons/tb";
import { getAssetIcon } from "../../shared/helper/getAssetImagesHelper";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import VulView from "./componentTree/vulView";
import VulTreeView from "./componentTree/vulTreeView";


function ActionColumn({ columnText, row, index, scanRecord, reportId, showComponents }) {

  const items = [
    {
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/fix-vulnerabilities/${reportId}?type=1`}
        >
          Fix all
        </a>
      ),
      key: `1-${reportId}`,
      value: "1",
    },
    {
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/fix-vulnerabilities/${reportId}?type=2`}
        >
          Fix all critical
        </a>
      ),
      key: `2-${reportId}`,
      value: "2",
    },
    {
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/fix-vulnerabilities/${reportId}?type=3`}
        >
          Fix all critical and high
        </a>
      ),
      key: `3-${reportId}`,
      value: "3",
    },
  ];

  return (
    <div className="d-flex align-items-center justify-content-start">
      <Tooltip title={'Rescan'}>
        <a
          className={`me-3 details-hover-animate ${row.scanningRow ||
            row.rowStatus.toLowerCase() === 'initiated' ||
            row.rowStatus.toLowerCase() === 'scanning' ||
            row.assetType === "file" ? "disabled" : ""}`}
          href="#"
          onClick={(e) => {
            scanRecord(e, row);
          }}
        >

          <RiQrScan2Line style={{ fontSize: 20 }} />

        </a>
      </Tooltip>
      <Tooltip title="Advance Dashboard">
        <a
          className={`me-3 details-hover-animate ${row.scanningRow ||
            row.rowStatus.toLowerCase() === 'failed' ||
            row.rowStatus.toLowerCase() === 'initiated' ||
            row.rowStatus.toLowerCase() === 'scanning'
            ? "disabled"
            : ""
            }`}
          href={`/dashboard-scan-details/${row.reportId}`}
          target="_blank"
        >
          {/* Adv Dashboard */}

          <AiOutlineDashboard style={{ fontSize: 20 }} />

        </a>
      </Tooltip>
      <Tooltip title="Show Tree">
        <a
          className={`me-3 details-hover-animate ${row.scanningRow ||
            row.rowStatus.toLowerCase() === 'failed' ||
            row.rowStatus.toLowerCase() === 'initiated' ||
            row.rowStatus.toLowerCase() === 'scanning' ||
            row.assetType === "file"
            ? "disabled"
            : ""
            }`}
          href={`#`}
          onClick={showComponents}
        >
          <TbBinaryTree style={{ fontSize: 20 }} />
        </a>
      </Tooltip>
      {_.some(GlobalConst.AUTO_FIX_ECOSYSTEMS_TO_CHECK, ecosystem => _.includes(row.ecosystem, ecosystem)) &&
        <Tooltip title="Apply Auto Fix">
          <a
            className={`me-0 details-hover-animate ${row.scanningRow ||
              row.rowStatus.toLowerCase() === 'failed' ||
              row.rowStatus.toLowerCase() === 'initiated' ||
              row.rowStatus.toLowerCase() === 'scanning'
              ? "disabled"
              : ""
              }`}
            href={`/fix-vulnerabilities/${reportId}`}
            target="_blank"
          >
            {/* Apply Auto Fix */}

            <MdAutoFixHigh style={{ fontSize: 20 }} />

          </a>
        </Tooltip>
      }
    </div>
  );
}

export default function RepoListScannedDataTable({ getDashboardData }) {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);

  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [page, setPage] = useState(1);
  const [showLoadTree, setShowLoadTree] = useState(false);
  const [selectedReportId, setSelectedReport] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    if (
      !_.isEmpty(reduxState.scannerReducer) &&
      !_.isEmpty(reduxState.scannerReducer.scannedRecords)
    ) {
      setData([...reduxState.scannerReducer.scannedRecords]);
    } else { }
  }, [reduxState.scannerReducer.scannedRecords]);

  useEffect(() => {
    // Now here we have the guid we need to do polling and get all the guid

    let timer = "";
    if (!_.isEmpty(reduxState.scannerReducer.scanGuidResponse)) {
      timer = setInterval(async () => {
        let resp = await getScanStatus(
          reduxState.scannerReducer.scanGuidResponse
        ); // Get the status response of all guids
        // Once you receive the scan status loop though the whole object received and check for the status of each and every record being scanned.
        if (!_.isEmpty(resp)) {
          let completedAll = true;
          _.forIn(resp, function (value, key) {
            if (value.status.toLowerCase().toLowerCase() === 'initiated' || value.status.toLowerCase() === "scanning") {
              completedAll = false;
            }
          });

          if (completedAll) {
            // when all the scans are completed empty the guid response and also clear the timer.
            dispatch(setSanningFromDrag(false));
            // getDashboardData(true, false);
            dispatch(setScanGuidResponse([]));
            clearInterval(timer);
            window.location.reload();
          }
          //1) now loop through all the scanner records and update the one which are complete of failed.
          let clonedScannedRecords = _.cloneDeep(
            reduxState.scannerReducer.scannedRecords
          );
          let newDataSet = [];
          clonedScannedRecords.forEach((el) => {
            // check for the record in the scanned records which has status as scanning or initiated
            if (el.rowStatus.toLowerCase() === 'initiated' || el.rowStatus.toLowerCase() === 'scanning') {
              // find the current record in the response status and see if the scan is complete
              let foundRec = {};
              _.forIn(resp, function (value, key) {
                if (
                  (value.status.toLowerCase() === 'completed' || value.status.toLowerCase() === 'failed') &&
                  value.location === el.url
                ) {
                  foundRec = value;
                  if (
                    !_.isEmpty(value) &&
                    !_.isEmpty(value.scannerResponseEntity) &&
                    !_.isEmpty(value.scannerResponseEntity.assetType)
                  ) {
                    dispatch(setSanningFromDrag(false));
                  }
                  return false;
                }
              });
              if (!_.isEmpty(foundRec)) {
                if (foundRec.status.toLowerCase() === 'failed') {
                  newDataSet.push({
                    ...el,
                    rowStatus: foundRec.status,
                    total: 0,
                    critical: 0,
                    high: 0,
                    medium: 0,
                    low: 0,
                    na: 0,
                    reportId: foundRec.reportId,
                    source: foundRec.source,
                    scanDate: foundRec.scannerResponseEntity.scanDate,
                    errorMessage: foundRec.errorMessage,
                  });
                } else {
                  newDataSet.push({
                    ...el,
                    rowStatus: foundRec.status,
                    source: foundRec.source,
                    total: foundRec.scannerResponseEntity.totalCve,
                    critical: foundRec.scannerResponseEntity.cveCountDetails.CRITICAL,
                    high: foundRec.scannerResponseEntity.cveCountDetails.HIGH,
                    medium: foundRec.scannerResponseEntity.cveCountDetails.MEDIUM,
                    low: foundRec.scannerResponseEntity.cveCountDetails.LOW,
                    na: (foundRec.scannerResponseEntity.totalCve - (foundRec.scannerResponseEntity.cveCountDetails.CRITICAL + foundRec.scannerResponseEntity.cveCountDetails.HIGH + foundRec.scannerResponseEntity.cveCountDetails.MEDIUM + foundRec.scannerResponseEntity.cveCountDetails.LOW)),
                    reportId: foundRec.reportId,
                    scanDate: foundRec.scannerResponseEntity.scanDate,
                    errorMessage: "",
                  });
                }
              } else {
                newDataSet.push(el);
              }
            } else {
              // We don't need to create a new array instead we can update the existing record.
              newDataSet.push(el);
            }
          });
          if (!_.isEmpty(newDataSet)) {
            dispatch(setScannedRecords(newDataSet));
          }
        } else {
          // when the response from the bakend is empty or null we need to "handle the scenario here".
        }
      }, 5 * 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [reduxState.scannerReducer.scanGuidResponse]);

  useEffect(() => {
    if (reduxState.scannerReducer.currentDashboardPageNumber) {
      setPage(reduxState.scannerReducer.currentDashboardPageNumber);
    }
  }, [reduxState.scannerReducer.currentDashboardPageNumber]);

  // This function will only get the status of the
  const getScanStatus = async (allGuid) => {
    let reqBody = [];
    allGuid.forEach((el) => {
      reqBody.push(el.uuid);
    });

    return await axios
      .post(`${GlobalConst.API_URL}/auth/foss/report/status`, reqBody)
      .then((op) => {
        if (!_.isEmpty(op) && !_.isEmpty(op.data)) {

          return op.data;
        } else {
          return null;
        }
      })
      .catch((e) => {
        console.log("Exception: ", e);
        return null;
      });
  };

  const updateGuids = async (op) => {

    let guid = [];
    if (!_.isEmpty(op)) {
      op.forEach((el) => {
        if (!_.isEmpty(el)) {
          guid.push(el);
        }
      });
    }
    dispatch(addGuid(guid));
  };

  const scanRecord = async (e, scanTriggeredRecord) => {
    // This will be triggered only when the user has clicked on re-scan link on the table.
    e.preventDefault();
    dispatch(setScanModalViewState(false));

    // 1) Go through the records and get the current record on top of the array
    // 2) initiate the scan api
    // 3) get the response as guid and store it in the guid array
    // 4) poll against all the guids.

    if (!_.isEmpty(scanTriggeredRecord)) {
      let triggeredScanCopy = {
        ...scanTriggeredRecord,
        rowStatus: "INITIATED",
      };

      dispatch(updateScannedRecord(triggeredScanCopy));
      // Modify the current triggered scan and make its status to INITIATED. once done update the redux state for scannedrecords.
      const requestBody = {
        urlType: scanTriggeredRecord.subAssetType,
        scanRequestList: [
          {
            location: scanTriggeredRecord.url,
            urlType: scanTriggeredRecord.subAssetType,
          },
        ],
      };
      // 2) initiate the scan api
      await axios
        .post(
          `${GlobalConst.API_URL}/auth/foss/ascan?enableSbom=true`,
          requestBody
        )
        .then(async (op) => {
          if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
            // 3) get the response as guid and store it in the guid array
            // get the guid array list from the redux and push the new guid onto the array so that we can poll against the guid added in the system.
            await updateGuids(op.data);
          }
        })
        .catch((e) => {
          console.log("Exception: ", e);
        });
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex, columnName, si) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={si}
          placeholder={`Search ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            className="d-flex align-items-center justify-content-center"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) {
                clearFilters();
                setSearchText("");
              }
              confirm({
                closeDropdown: false,
              });
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => si.current?.select(), 100);
      }
    },
    render: (text, row) =>
      searchedColumn === dataIndex && !_.isEmpty(searchText)
        ? highlightText(searchedColumn, text, row)
        : getColumnText(dataIndex, text, row),
  });

  const getColumnText = (searchedColumn, text, row) => {

    if (searchedColumn === "name") {
      if (row.rowStatus.toLowerCase() === 'failed') {
        return (
          <span className="d-flex align-items-center">
            {!_.isEmpty(row) && !_.isEmpty(row.url) && isValidHttpUrl(row.url)
              ? <a
                title={row.url}
                href={row.subAssetType === 'azure-scm' ? `${row.url}?version=GB${row.branch}` : row.url}
                target="_blank"
                style={{
                  color: "inherit"
                }}
              >
                {getAssetIcon(row.subAssetType)}
              </a>
              : getAssetIcon(row.subAssetType)}
            {row.assetType !== "file" ? (
              <>
                <a
                  title={row.url}
                  className="d-flex align-items-center"
                  style={{ overflow: "hidden" }}
                  href={`/dashboard-scan-details/${row.reportId}`}
                  target="_blank"
                >
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {text}
                  </span>
                </a>{" "}
                <span>
                  <Tooltip title={row.errorMessage}>
                    <span>
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="ms-2 text-danger"
                      />
                    </span>
                  </Tooltip>
                </span>
              </>
            ) : (
              <span title={row.url}>
                {!_.isEmpty(row) &&
                  !_.isEmpty(row.reportId) &&
                  row.type === "github" ? (
                  <a
                    href={`/dashboard-scan-details/${row.reportId}`}
                    target="_blank">
                    {text}
                  </a>
                ) : (
                  text
                )}{" "}
                <span>
                  <Tooltip title={row.errorMessage}>
                    <span>
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="ms-2 text-danger"
                      />
                    </span>
                  </Tooltip>
                </span>
              </span>
            )}
          </span>
        );
      }
      return (
        <span>
          {!_.isEmpty(row) && !_.isEmpty(row.url) && isValidHttpUrl(row.url)
            ? <a
              title={row.url}
              href={row.subAssetType === 'azure-scm' ? `${row.url}?version=GB${row.branch}` : row.url}
              target="_blank"
              style={{
                color: "inherit"
              }}
            >
              {getAssetIcon(row.subAssetType)}
            </a>
            :
            getAssetIcon(row.subAssetType)
          }
          <span title={row.url}>
            {!_.isEmpty(row)
              ? getRowName(row, text)
              : text}
          </span>
        </span>
      );
    } else {
      return text;
    }
  };

  const isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const getRowName = (row, text) => {
    return (
      <a
        href={`/dashboard-scan-details/${row.reportId}`}
        target="_blank">
        {text}
      </a>
    );
  };

  const highlightText = (searchedColumn, text, row) => {
    if (searchedColumn === "name") {
      if (row.rowStatus.toLowerCase() === 'failed') {
        return (
          <span className="d-flex align-items-center">
            {!_.isEmpty(row) && !_.isEmpty(row.url) && isValidHttpUrl(row.url)
              ?
              <a
                title={row.url}
                href={row.subAssetType === 'azure-scm' ? `${row.url}?version=GB${row.branch}` : row.url}
                target="_blank"
                style={{
                  color: "inherit"
                }}
              >
                {getAssetIcon(row.subAssetType)}
              </a>
              :
              getAssetIcon(row.subAssetType)}
            {row.assetType !== "file" ? (
              <>
                <a
                  title={row.url}
                  className="d-flex align-items-center"
                  style={{ overflow: "hidden" }}
                  href={`/dashboard-scan-details/${row.reportId}`}
                  target="_blank"
                >
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {getHighlighted(text)}
                  </span>
                </a>{" "}
                <span>
                  <Tooltip title={row.errorMessage}>
                    <span>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="ms-2 text-danger"
                      />
                    </span>
                  </Tooltip>
                </span>
              </>
            ) : (
              <span title={row.url}>
                {!_.isEmpty(row) &&
                  row.type === "github" ? (
                  <a
                    href={`/dashboard-scan-details/${row.reportId}`}
                    target="_blank">
                    {text}
                  </a>
                ) : (
                  text
                )}{" "}
                <span>
                  <Tooltip title={row.errorMessage}>
                    <span>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="ms-2 text-danger"
                      />
                    </span>
                  </Tooltip>
                </span>
              </span>
            )}
          </span>
        );
      }
      return (
        <span>
          {!_.isEmpty(row) && !_.isEmpty(row.url) && isValidHttpUrl(row.url)
            ?
            <a
              title={row.url}
              href={row.subAssetType === 'azure-scm' ? `${row.url}?version=GB${row.branch}` : row.url}
              target="_blank"
              style={{
                color: "inherit"
              }}
            >
              {getAssetIcon(row.subAssetType)}
            </a>
            :
            getAssetIcon(row.subAssetType)}
          <span title={row.url}>
            {!_.isEmpty(row) && row.type === "github"
              ? getHighlightedRowName(row, text)
              : getHighlighted(text)}
          </span>
        </span>
      );
    } else {
      return getHighlighted(text);
    }
  };

  const getHighlightedRowName = (row, text) => {
    if (row.assetType !== "file") {
      return (
        <a
          href={`/dashboard-scan-details/${row.reportId}`}
          target="_blank">
          {getHighlighted(text)}
        </a>
      );
    } else {
      return getHighlighted(text);
    }
  };

  const getHighlighted = (text) => {
    return (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    );
  };

  const getSourceIcon = (source) => {
    if (_.includes(source, 'cli')) {
      return <span><Tooltip title="CLI"><img src={cli} alt="CLI" style={{ width: 22 }} /></Tooltip></span>;
    }
    else if (_.includes(source, 'git')) {
      return <span><Tooltip title="GitHub Actions"><FontAwesomeIcon title={'GitAction'} icon={faGithub} style={{ fontSize: 18 }} /></Tooltip></span>;
    }
    else if (_.includes(source, 'aws')) {
      return <span><Tooltip title="Amazon Web Services"><img src={awsCi} alt="AWS" style={{ width: 22 }} /></Tooltip></span>;
    }
    else if (_.includes(source, 'gcp')) {
      return <span><Tooltip title="Google Cloud Platform"><img src={gcpCi} alt="GCP" style={{ width: 22 }} /></Tooltip></span>;
    }
    else if (_.includes(source, 'jenkins')) {
      return <span><Tooltip title="Jenkins"><img src={jenkins} alt="Jenkins" style={{ width: 22 }} /></Tooltip></span>;
    }
    else if (_.includes(source, 'circleci')) {
      return <span><Tooltip title="CircleCI"><img src={circleci} alt="Circle-CI" style={{ width: 22 }} /></Tooltip></span>;
    }
    else if (_.includes(source, 'bamboo')) {
      return <span><Tooltip title="Bamboo"><img src={bamboo} alt="Baboo" style={{ width: 22 }} /></Tooltip></span>;
    }
    else if (_.includes(source, 'azure')) {
      return <span><Tooltip title="Microsoft Azure"><img src={azure} alt="Azure" style={{ width: 22 }} /></Tooltip></span>;
    }
    else {
      return '-';
    }
  }

  const showComponentView = (reportId) => {
    setShowLoadTree(true)
    setSelectedReport(reportId)
  }

  const hideTree = () => {
    setShowLoadTree(false)
  }

  const columns = [
    {
      title: "Sno",
      dataIndex: "serialNumber",
      width: 60,
      // sorter: (a, b) => a.serialNumber - b.serialNumber,
      // sortDirections: ['descend'],
      render: (val, row, index) => {
        return (page - 1) * 10 + index + 1;
      },
    },
    {
      title: "Artifact Name",
      dataIndex: "name",
      width: 290,
      vAlign: "top",
      ...getColumnSearchProps("name", "name", searchInput),
      ellipsis: true,
    },
    {
      title: "Source",
      dataIndex: "source",
      width: 80,
      vAlign: "top",
      align: "center",
      // ...getColumnSearchProps("name", "name", searchInput),
      ellipsis: true,
      // onCell: (record, rowIndex) => ({ colSpan: (record.rowStatus.toLowerCase() === 'failed') ? 7 : 1 }),
      render: (val, row) => {
        return val ? getSourceIcon(val) : '-';
      }
    },
    {
      title: "Total",
      dataIndex: "total",
      width: 95,
      sorter: (a, b) => a.total - b.total,
      sortDirections: ["descend"],
      align: (val, row) => {
        return row.colSpan ? "left" : "center";
      },
      render: (val, row) => {

        if (row.rowStatus.toLowerCase() === 'initiated' || row.rowStatus.toLowerCase() === 'scanning') {
          return (
            <div className="d-flex align-items-center justify-content-center"></div>
          );
        }
        if (row.rowStatus.toLowerCase() === 'failed') {
          return (
            <div className="d-flex align-items-start justify-content-center flex-column">
              <span
                style={{
                  color: "#adb5bd",
                  borderRadius: 50,
                  width: 40,
                  border: "none",
                  boxShadow: "none",
                  fontSize: 14,
                }}
                className={"me-0 text-center d-inline-block"}
              >
                -
              </span>
            </div>
          );
        }
        return val ? (
          <div className="d-flex align-items-start justify-content-center flex-column">
            <span
              style={{
                color: "#adb5bd",
                borderRadius: 50,
                width: 40,
                border: "none",
                boxShadow: "none",
                fontSize: 14,
              }}
              className={"me-0 text-center d-inline-block"}
            >
              {row.total}
            </span>
          </div>
        ) : (
          <span
            style={{
              color: "#adb5bd",
              borderRadius: 50,
              width: 40,
              border: "none",
              boxShadow: "none",
              fontSize: 14,
            }}
            className={"me-0 text-center d-inline-block"}
          >
            0
          </span>
        );
      },
      // onCell: (record, rowIndex) => ({ colSpan: (record.rowStatus.toLowerCase() === 'failed') ? 0 : 1 }),
    },
    {
      title: "Critical",
      dataIndex: "critical",
      width: 105,
      sorter: (a, b) => a.critical - b.critical,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) => {
        if (row.rowStatus.toLowerCase() === 'initiated' || row.rowStatus.toLowerCase() === 'scanning') {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          );
        }
        if (row.rowStatus.toLowerCase() === 'failed') {
          return (
            <div className="d-flex align-items-start justify-content-center flex-column">
              <span
                style={{
                  color: "#adb5bd",
                  borderRadius: 50,
                  width: 40,
                  border: "none",
                  boxShadow: "none",
                  fontSize: 14,
                }}
                className={"me-0 text-center d-inline-block"}
              >
                -
              </span>
            </div>
          );
        }
        if (val) {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("CRITICAL"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("CRITICAL")}
                className={"mx-auto text-center"}
              >
                {row.critical}
              </Tag>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("CRITICAL"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("CRITICAL")}
                className={"mx-auto text-center"}
              >
                0
              </Tag>
            </div>
          );
        }
      },
      // onCell: (record, rowIndex) => ({ colSpan: (record.rowStatus.toLowerCase() === 'failed') ? 0 : 1 }),
    },
    {
      title: "High",
      dataIndex: "high",
      width: 85,
      sorter: (a, b) => a.high - b.high,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) => {
        if (row.rowStatus.toLowerCase() === 'initiated' || row.rowStatus.toLowerCase() === 'scanning') {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          );
        }
        if (row.rowStatus.toLowerCase() === 'failed') {
          return (
            <div className="d-flex align-items-start justify-content-center flex-column">
              <span
                style={{
                  color: "#adb5bd",
                  borderRadius: 50,
                  width: 40,
                  border: "none",
                  boxShadow: "none",
                  fontSize: 14,
                }}
                className={"me-0 text-center d-inline-block"}
              >
                -
              </span>
            </div>
          );
        }
        if (val) {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("HIGH"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("HIGH")}
                className={"mx-auto text-center"}
              >
                {row.high}
              </Tag>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("HIGH"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("HIGH")}
                className={"mx-auto text-center"}
              >
                0
              </Tag>
            </div>
          );
        }
      },
      // onCell: (record, rowIndex) => ({ colSpan: (record.rowStatus.toLowerCase() === 'failed') ? 0 : 1 }),
    },
    {
      title: "Medium",
      dataIndex: "medium",
      width: 105,
      sorter: (a, b) => a.medium - b.medium,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) => {
        if (row.rowStatus.toLowerCase() === 'initiated' || row.rowStatus.toLowerCase() === 'scanning') {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          );
        }
        if (row.rowStatus.toLowerCase() === 'failed') {
          return (
            <div className="d-flex align-items-start justify-content-center flex-column">
              <span
                style={{
                  color: "#adb5bd",
                  borderRadius: 50,
                  width: 40,
                  border: "none",
                  boxShadow: "none",
                  fontSize: 14,
                }}
                className={"me-0 text-center d-inline-block"}
              >
                -
              </span>
            </div>
          );
        }
        if (val) {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("MEDIUM"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("MEDIUM")}
                className={"mx-auto text-center"}
              >
                {row.medium}
              </Tag>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("MEDIUM"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("MEDIUM")}
                className={"mx-auto text-center"}
              >
                0
              </Tag>
            </div>
          );
        }
      },
      // onCell: (record, rowIndex) => ({ colSpan: (record.rowStatus.toLowerCase() === 'failed') ? 0 : 1 }),
    },
    {
      title: "Low",
      dataIndex: "low",
      width: 95,
      sorter: (a, b) => a.low - b.low,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) => {
        if (row.rowStatus.toLowerCase() === 'initiated' || row.rowStatus.toLowerCase() === 'scanning') {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          );
        }
        if (row.rowStatus.toLowerCase() === 'failed') {
          return (
            <div className="d-flex align-items-start justify-content-center flex-column">
              <span
                style={{
                  color: "#adb5bd",
                  borderRadius: 50,
                  width: 40,
                  border: "none",
                  boxShadow: "none",
                  fontSize: 14,
                }}
                className={"me-0 text-center d-inline-block"}
              >
                -
              </span>
            </div>
          );
        }
        if (val) {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("LOW"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("LOW")}
                className={"mx-auto text-center"}
              >
                {row.low}
              </Tag>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("LOW"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("LOW")}
                className={"mx-auto text-center"}
              >
                {0}
              </Tag>
            </div>
          );
        }
      },
      // onCell: (record, rowIndex) => ({ colSpan: (record.rowStatus.toLowerCase() === 'failed') ? 0 : 1 }),
    },
    {
      title: "NA",
      dataIndex: "na",
      width: 95,
      sorter: (a, b) => a.na - b.na,
      sortDirections: ["descend"],
      align: "center",
      render: (val, row) => {
        if (row.rowStatus.toLowerCase() === 'initiated' || row.rowStatus.toLowerCase() === 'scanning') {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          );
        }
        if (row.rowStatus.toLowerCase() === 'failed') {
          return (
            <div className="d-flex align-items-start justify-content-center flex-column">
              <span
                style={{
                  color: "#adb5bd",
                  borderRadius: 50,
                  width: 40,
                  border: "none",
                  boxShadow: "none",
                  fontSize: 14,
                }}
                className={"me-0 text-center d-inline-block"}
              >
                -
              </span>
            </div>
          );
        }
        if (val) {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("NA"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("NA")}
                className={"mx-auto text-center"}
              >
                {row.na}
              </Tag>
            </div>
          );
        } else {
          return (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Tag
                style={{
                  color: getFontColor("NA"),
                  borderRadius: 50,
                  width: 40,
                  fontSize: 14,
                }}
                color={getStrokeColor("NA")}
                className={"mx-auto text-center"}
              >
                {0}
              </Tag>
            </div>
          );
        }
      },
      // onCell: (record, rowIndex) => ({ colSpan: (record.rowStatus.toLowerCase() === 'failed') ? 0 : 1 }),
    },
    {
      title: "Last Scan",
      dataIndex: "",
      width: 150,
      sorter: (a, b) => moment(a.scanDate).unix() - moment(b.scanDate).unix(),
      sortDirections: ["ascend", "descend"],
      render: (val, row) => {
        if (row.rowStatus.toLowerCase() === 'initiated' || row.rowStatus.toLowerCase() === 'scanning') {
          return (
            <div className="d-flex align-items-center justify-content-start text-info">Scan Initiated</div>
          );
        }
        // return <div className="d-flex align-items-center justify-content-start text-info">Scan Initiated</div>;
        return moment.utc(row.scanDate).local().fromNow();
      },
      ellipsis: true,
    },
    {
      title: "Actions",
      dataIndex: "",
      width: 160,
      render: (val, row, index) => {
        return (
          <ActionColumn
            columnText={val}
            row={row}
            index={index}
            scanRecord={scanRecord}
            reportId={row.reportId}
            showComponents={() => showComponentView(row.reportId)}
          />
        );
      },
      fixed: "right",
    }
  ];

  if (reduxState.scannerReducer.noScannedRecords) {
    return (
      <div
        className={"card custom-card p-2 px-3 text-left mb-2 mt-3"}
        style={{
          background: "#1e464d",
          border: "1px solid #2c6a74",
          width: "100%",
        }}
      >
        <div className="d-flex align-items-center justify-content-start">
          <span className="ms-2">
            No scans found please{" "}
            <a
              onClick={(e) => {
                e.preventDefault();
              }}
              href={"#"}
            >
              click here
            </a>{" "}
            to scan a repository
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="mt-3">
        {
          !_.isEmpty(reduxState.scannerReducer.scannedRecords) && (
            <div style={{ overflow: "auto" }}>
              <Table
                size={"small"}
                columns={columns}
                dataSource={reduxState.scannerReducer.scannedRecords}
                className="custom-table mb-0 fixedScrollCustomTable"
                pagination={{
                  showTotal: (total, range) => {
                    return (
                      <span className="text-white">
                        {range[0]}-{range[1]} of {total && total.format()}{" "}
                        repositories
                      </span>
                    );
                  },
                  current: page,
                  onChange(current) {
                    dispatch(setCurrentDashboardPageNumber(current));
                  },
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  position: ["bottom", "left"],
                  className: "searchPagePagination px-0",
                  pageSizeOptions: [10, 20, 25],
                }}
                locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
              />
            </div>
          )
        }
        {/* {showLoadTree && <VulView reportId={selectedReportId} showComponentTreeModal={showLoadTree} setShowComponentTreeModal={hideTree} />} */}
        {showLoadTree && <VulTreeView reportId={selectedReportId} showComponentTreeModal={showLoadTree} setShowComponentTreeModal={hideTree} />}

      </div>
    );
  }
}