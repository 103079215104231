import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import DashboardSeverityIndicator from "../../shared/sharedComponents/dashboardSeverityIndicator";
import axios from 'axios';
import _ from 'lodash';
import { useParams } from "react-router-dom";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { Button, Radio, Tooltip } from "antd";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Loader from '../../shared/sharedComponents/loader';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import WelcomeLoader from "../welcomeScreen/loader";
import VulnerablePods from "./Tabs/VulnerablePods";
import ResourceDetailsModal from "./resourceDetailsModal";
import K8TreeModal from "./k8TreeModal";
import { TbBinaryTree } from "react-icons/tb";
import SecurityPostureTab from "./Tabs/securityPostureTab";
import RulesTab from "./Tabs/rulesTab";
import { updateSelectedK8sRecord } from "../../shared/redux/reducers/kubernetesSecurityReducer";
import { useDispatch } from "react-redux";
import TrendGraph from "./trendGraph";
import HistoryTab from "./Tabs/historyTab";
import { getAssetIcon } from "../../shared/helper/getAssetImagesHelper";
import AssetViewTab from "./Tabs/assetViewTab";
import { useLocation } from "react-router-dom";
import moment from "moment";
import PieChart from "./pieChart";

export default function KubernetesSecurityAdvanceDashboard() {
    const { id, integrationId } = useParams();
    const [loadingRows, setLoadingRows] = useState(false);
    const [activeTabsKey, setActiveTabsKey] = useState('vulnerablePods');

    const [loading, setLoading] = useState(false);
    const [advDashboardData, setAdvDashboardData] = useState({});
    const [showResourceDetailsModal, setShowResourceDetailsModal] = useState(false);
    const [showK8sTreeModal, setShowK8sTreeModal] = useState(false);
    const [podSecurityTriggeredScans, setPodSecurityTriggeredScans] = useState([])
    const [trendData, setTrendData] = useState({});
    const [trendValue, setTrendValue] = useState('7');
    const [loadingTrendData, setLoadingTrendData] = useState(false);
    const [issuesDistribution, setIssuesDistribution] = useState([]);
    const intervalId = useRef(null);
    const dispatch = useDispatch()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isViewTree = queryParams.get("viewTree");

    useEffect(() => {
        getClusterDashboard()
        getHierarchyData()
        if (isViewTree)
            setShowK8sTreeModal(true)
    }, []);

    useEffect(() => {
        if (!_.isEmpty(podSecurityTriggeredScans)) {
            // Clear previous interval if it exists
            if (intervalId.current) {
                clearInterval(intervalId.current);
            }
            // Set a new interval for polling
            intervalId.current = setInterval(() => {
                poll();
            }, GlobalConst.POLLING_LIMIT);

            // Cleanup interval on component unmount
            return () => {
                if (intervalId.current) {
                    clearInterval(intervalId.current);
                }
            };
        }

    }, [podSecurityTriggeredScans]);

    useEffect(() => {
        if (!_.isEmpty(trendValue)) {
            let postData = { days: trendValue ? trendValue : "7", integrationId: integrationId };
            setLoadingTrendData(true);
            setTrendData({});
            axios
                .post(`${GlobalConst.API_URL}/auth/cloud-api/k8s/dashboard/trend`, postData)
                .then(op => {
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        setTrendData(op.data);
                    }
                })
                .catch(e => {
                    console.log("Exception: ", e);
                })
                .finally(e => {
                    setLoadingTrendData(false);
                });
        }
    }, [trendValue, advDashboardData]);

    const goBack = (e) => {
        if (window.opener && !window.opener.closed) {
            window.opener.focus();
            window.close();
        } else {
            window.location.href = '/k8s-dashboard';
        }
    }


    const handleDetailsClick = (record) => {
        const activeRecordData = {
            "targetSystem": "k8s",
            "integrationId": integrationId,
            "reportId": id,
            "docType": record.docType ? record.docType : "pod",
            "name": record.podName,
            "namespace": record.namespace
        };
        dispatch(updateSelectedK8sRecord(activeRecordData));
        setShowResourceDetailsModal(true)
    }

    const handleShowK8sTreeClick = (e) => {
        e.preventDefault();
        setShowK8sTreeModal(true);
    }

    const getClusterDashboard = () => {
        setLoading(true);
        const postBody = {
            "integrationId": integrationId,
            "reportId": id,
            "targetSystem": "k8s",
        };
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/k8s/cluster/dashboard`, postBody)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {

                    if (!_.isEmpty(op.data.contsec) && !_.isEmpty(op.data.contsec.pods)) {
                        const scanningOrInitiated = _.chain(op.data.contsec.pods)
                            .filter(record => record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toLowerCase() === 'initiated')
                            .map('reportId')
                            .value();
                        if (!_.isEmpty(scanningOrInitiated)) {
                            setPodSecurityTriggeredScans(scanningOrInitiated)
                        } else {
                            clearInterval(intervalId.current);
                            setPodSecurityTriggeredScans([])
                        }
                    } else {
                        setPodSecurityTriggeredScans([])
                    }
                    setAdvDashboardData(op.data)

                } else {
                    setPodSecurityTriggeredScans([])
                }

            })
            .catch(e => {
                setPodSecurityTriggeredScans([])
            })
            .finally(o => {
                setLoading(false);
            })
    }

    const poll = () => {
        getClusterDashboard()
    };

    const handleTrendFilterChange = (e) => {
        setTrendValue(e.target.value);
    };

    const handleStatusCheck = (scannedRecordData) => {
        if (!_.isEmpty(scannedRecordData) && !_.isEmpty(scannedRecordData[0])) {
            const postBody = { reportId: [scannedRecordData[0].reportId] };
            const poll = () => {
                axios
                    .post(`${GlobalConst.API_URL}/auth/cloud-api/report/status`, postBody)
                    .then(op => {
                        if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data[0])) {
                            if (op.data[0].status.toLowerCase() === "initiated") {
                                setTimeout(poll, GlobalConst.POLLING_LIMIT);
                            } else {
                                setLoadingRows(false);
                                // window.location.reload();
                                window.location.href = `/k8s-advance-dashboard/${op.data[0].reportId}/${op.data[0].integrationId}`;
                            }
                        }
                    })
                    .catch(e => { });
            };
            poll();
        }
    };

    const handleRescanClick = (e) => {
        e.preventDefault();

        if (!_.isEmpty(advDashboardData)) {
            setLoadingRows(true);
            const postBody = {
                "integrationId": integrationId,
                "targetSystem": "k8s"
            };

            axios
                .post(`${GlobalConst.API_URL}/auth/cloud-api/k8s/ascan`, postBody)
                .then((op) => {
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        handleStatusCheck(op.data);
                    }
                })
                .catch((e) => {

                });
        }
    }

    const getHierarchyData = () => {
        setLoading(true);

        let postData = {
            "reportId": id,
            "integrationId": integrationId,
            "targetSystem": "k8s",
        }

        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/hierarchy`, postData)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.children)) {
                    const distributionData = processIssueDistributionData(op.data.children);
                    setIssuesDistribution(distributionData);
                }
            })
            .catch(e => { setIssuesDistribution([]); })
            .finally(o => {
                setLoading(false);
            })
    }

    const processIssueDistributionData = (clusterHeirarchyData) => {
        const groupedData = _.chain(clusterHeirarchyData).groupBy('name').map((group, name) => {
            // Calculate total vulnerabilities for the group
            const total = group.reduce((sum, item) => {
                const vuln = item.vulnerability;
                return sum + (vuln.critical || 0)
                    + (vuln.high || 0)
                    + (vuln.low || 0)
                    + (vuln.medium || 0)
                    + (vuln.na || 0);
            }, 0);
            return {
                name: group[0].displayName ? group[0].displayName : name,
                podName: name,
                namespace: group[0].namespace,
                docType: group[0].docType,
                value: total
            }
        }).value()

        return groupedData
    }

    const onPieClick = (data) => {
        const record = {
            docType: data.docType,
            podName: data.podName,
            namespace: data.namespace
        }
        handleDetailsClick(record)
    }

    return (
        <section className="mx-3 my-2">
            <section className="mb-3 d-flex align-items-center justify-content-start">
                <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start me-2">
                    <a className='me-2' onClick={goBack} href='#' style={{ fontSize: 22 }}><small ><FontAwesomeIcon icon={faArrowLeft} /></small></a>
                    <span className='ps-2' style={{ borderLeft: '1px solid rgba(108, 117, 125, 15%' }}>Kubernetes Security Scan Report</span>
                </h2>
                <Button
                    disabled={loadingRows}
                    className="custom-button btn-sm ms-3"
                    onClick={handleRescanClick}
                >
                    <FontAwesomeIcon icon={faArrowsRotate} className="me-2" />
                    Re-Scan
                </Button>
                <Button
                    className="custom-button btn-sm ms-3"
                    onClick={handleShowK8sTreeClick}
                >
                    <TbBinaryTree className="me-2" />
                    Visualize Cluster
                </Button>
                {loadingRows && (
                    <span className="d-flex align-items-center justify-content-start ms-3">
                        <WelcomeLoader />{" "}
                        <small className="ms-1" style={{ fontSize: 14, fontWeight: 200 }}>
                            Scanning...
                        </small>
                    </span>
                )}
            </section>
            <div className="">
                <div>
                    {!_.isEmpty(advDashboardData) && (
                        <>
                            <span
                                className="d-flex align-items-center justify-content-start"
                            >
                                <span className="text-truncate me-3">{!_.isEmpty(advDashboardData.provider) && getAssetIcon(advDashboardData.provider)}{!_.isEmpty(advDashboardData.clusterName) && <Tooltip title={advDashboardData.clusterName}><span>{advDashboardData.clusterName}</span></Tooltip>}</span>
                                {/* <span className="text-truncate d-flex"><span >&#40;{!_.isEmpty(advDashboardData.provider) && getAssetIcon(advDashboardData.provider)}</span>{advDashboardData.projectId}&#41;</span> */}
                                <span>
                                    {!_.isEmpty(advDashboardData.lastScanTime) && <small className="d-block text-secondary" style={{}}><i>(<strong>Last Scan:</strong> {moment.utc(advDashboardData.lastScanTime).local().fromNow()})</i></small>}
                                </span>
                            </span>
                        </>
                    )}

                </div>
            </div>

            {!_.isEmpty(advDashboardData) && !loading ?
                <>
                    <section className="mb-2">
                        <div className="row">
                            <div className="col-lg-5 custom-border-right">
                                <div className="p-3 ps-0 d-flex align-items-center w-100 h-100">
                                    <div className="card-body">
                                        <div className="d-flex flex-row align-items-start justify-content-between">
                                            <div className="me-3">
                                                <p className="mb-0 text-secondary" style={{ fontSize: 12 }}>Total Vulnerabilities</p>
                                                <h1 className="head-tag" style={{ lineHeight: 1.1 }}>{advDashboardData.totalCve}</h1>
                                            </div>
                                            <div className="d-flex flex-wrap flex-row align-items-start justify-content-center">
                                                <div className="me-3 mb-2">
                                                    <DashboardSeverityIndicator severity={'Critical'} loadingRows={loadingRows} dashboardData={advDashboardData.critical} />
                                                </div>
                                                <div className="me-3 mb-2">
                                                    <DashboardSeverityIndicator severity={'High'} loadingRows={loadingRows} dashboardData={advDashboardData.high} />
                                                </div>
                                                <div className="me-3 mb-2">
                                                    <DashboardSeverityIndicator severity={'Medium'} loadingRows={loadingRows} dashboardData={advDashboardData.medium} />
                                                </div>
                                                <div className="me-3 mb-2">
                                                    <DashboardSeverityIndicator severity={'Low'} loadingRows={loadingRows} dashboardData={advDashboardData.low} />
                                                </div>
                                                <div className="me-3 mb-2">
                                                    <DashboardSeverityIndicator severity={'NA'} loadingRows={loadingRows} dashboardData={advDashboardData.na} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-6 custom-border-right">
                                        <div className="p-2">
                                            <div className="card-body">
                                                <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                                    <h5 className="fw-medium">Issues Distribution</h5>
                                                </div>
                                                <div style={{ height: 300 }}>
                                                    <PieChart data={issuesDistribution} legendHeading={'Issues Distribution'} onPieClick={onPieClick} hideLegend={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6  mb-3">
                                        <div className="p-2">
                                            <div className="card-body">
                                                <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                                    <h5 className="fw-medium">Trend</h5>
                                                    <div>
                                                        <Radio.Group value={trendValue} size="small" buttonStyle="solid" onChange={(e) => handleTrendFilterChange(e)}>
                                                            <Radio.Button value="7" style={{ fontSize: 12 }}>1 Week</Radio.Button>
                                                            <Radio.Button value="14" style={{ fontSize: 12 }}>2 Weeks</Radio.Button>
                                                            <Radio.Button value="30" style={{ fontSize: 12 }}>1 Month</Radio.Button>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                                <div style={{ height: 300 }}>
                                                    <TrendGraph hasCloudData={integrationId ? true : false} loadingData={loadingTrendData} trendData={trendData} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="mb-5">
                        <Tabs
                            className="mb-3 report-tabs"
                            activeKey={activeTabsKey}
                            onSelect={(k) => setActiveTabsKey(k)}
                            mountOnEnter={true}
                            unmountOnExit={true}
                        >
                            <Tab eventKey="vulnerablePods" title="Vulnerability View">
                                <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <VulnerablePods data={advDashboardData.contsec} handleDetailsClick={handleDetailsClick} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Tab>
                            <Tab eventKey="securityPosture" title="Security Posture">
                                <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <SecurityPostureTab data={advDashboardData.cspm} setShowResourceDetailsModal={setShowResourceDetailsModal} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Tab>
                            <Tab eventKey="rules" title="Rules">
                                <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <RulesTab setShowResourceDetailsModal={setShowResourceDetailsModal} dashboardData={advDashboardData} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Tab>
                            <Tab eventKey="assetView" title="Asset View">
                                <section className="mb-5">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <AssetViewTab id={id} setShowResourceDetailsModal={setShowResourceDetailsModal} />
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </Tab>
                            <Tab eventKey="history" title="History">
                                <HistoryTab id={integrationId} />
                            </Tab>
                        </Tabs>
                    </section>
                </>
                : <></>}
            {showResourceDetailsModal && <ResourceDetailsModal showResourceDetailsModal={showResourceDetailsModal} setShowResourceDetailsModal={setShowResourceDetailsModal} setLoading={setLoading} />}
            {showK8sTreeModal && <K8TreeModal showK8sTreeModal={showK8sTreeModal} setShowK8sTreeModal={setShowK8sTreeModal} setLoading={setLoading} setShowResourceDetailsModal={setShowResourceDetailsModal} advDashboardData={advDashboardData} />}
            {loading && <Loader />}

        </section>
    );
}