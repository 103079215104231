import { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useParams } from "react-router-dom";
import { faArrowLeft, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Radio, Button } from 'antd';
import DashboardSeverityIndicator from "../../shared/sharedComponents/dashboardSeverityIndicator";
import SastFileTabView from './sastFileTabView';
import SastVulnerabilityTabView from './sastVulnerabilityTabView';
import SastHistoryTabView from './sastHistoryTabView';
import TrendGraph from './trendGraph';
import Loader from '../../shared/sharedComponents/loader';
import { getAssetName } from '../../shared/helper/genHelper';
import moment from 'moment';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import WelcomeLoader from "../welcomeScreen/loader";
import { useSelector } from 'react-redux';
import SastFalsePositiveTabView from './sastFalsePositiveView';
import { getAssetIcon } from '../../shared/helper/getAssetImagesHelper';
import OWASP10TabView from './OWASP10TabView';
import ComplianceStandardTabView from './complianceStandardTabView';

export default function SastAdvanceDashboard() {

    const { id } = useParams();
    const reduxStore = useSelector(state => state);
    const [loadingRows, setLoadingRows] = useState(false);
    const [activeTabsKey, setActiveTabsKey] = useState('vulnerabilityView');
    const [showLoader, setShowLoader] = useState(false);
    const [reportDetails, setReportDetails] = useState({});
    const [trendValue, setTrendValue] = useState('7');
    const [userPrefrences, setUserPrefrences] = useState(null);

    useEffect(() => {
        setShowLoader(true);
        if (id) {
            getAdvSastDashboardData(id);
        }
    }, [id]);

    useEffect(() => {

        if (!_.isEmpty(reduxStore) && !_.isEmpty(reduxStore.authReducer) && !_.isEmpty(reduxStore.authReducer.userDetails) && !_.isEmpty(reduxStore.authReducer.userDetails.email)) {
            getPrefrences(reduxStore.authReducer.userDetails.email);
        }
    }, [reduxStore])

    const getAdvSastDashboardData = (reportId) => {
        const postBody = { "reportId": reportId };
        axios
            .post(`${GlobalConst.API_URL}/auth/sast/asset/report/file-view`, postBody)
            .then((op) => {
                setShowLoader(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setReportDetails(op.data);
                }
            })
            .catch((e) => {
                setShowLoader(false);
                console.log("Exception: ", e);
                setReportDetails({});
            });
    }

    const getPrefrences = (userId) => {
        const postObj = { userId: userId };
        axios
            .post(
                `${GlobalConst.API_URL}/auth/foss/user/get-preference`,
                postObj
            )
            .then((op) => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setUserPrefrences(op.data);
                }
            })
            .catch((e) => { });
    };

    const getTotal = (vulCount) => {
        return (vulCount.critical + vulCount.high + vulCount.medium + vulCount.low);
    }

    const handleRescanClick = (e) => {
        e.preventDefault();

        if (!_.isEmpty(reportDetails)) {
            setLoadingRows(true);
            const postBody = { "urlType": reportDetails.assetType, "scanRequestList": [{ "urlType": reportDetails.assetType, "location": reportDetails.assetUrl, "branchName": reportDetails.branch }] };
            axios
                .post(`${GlobalConst.API_URL}/auth/foss/sast/ascan`, postBody)
                .then((op) => {
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        handleStatusCheck(op.data);
                    }
                })
                .catch((e) => {
                    console.log("Exception: ", e);
                });
        }
    }

    const handleStatusCheck = (scannedRecordData) => {
        if (!_.isEmpty(scannedRecordData) && !_.isEmpty(scannedRecordData[0])) {
            const postBody = { reportId: [scannedRecordData[0].uuid] };
            const poll = () => {
                axios
                    .post(`${GlobalConst.API_URL}/auth/sast/asset/report/status`, postBody)
                    .then(op => {
                        if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data[0])) {
                            if (op.data[0].scanStatus.toLowerCase() === 'scanning' || op.data[0].scanStatus.toLowerCase() === 'initiated') {
                                setTimeout(poll, GlobalConst.POLLING_LIMIT);
                            } else {
                                setLoadingRows(false);
                                window.location.href = `/sast-advance-dashboard/${op.data[0].reportId}`;
                            }
                        }
                    })
                    .catch(e => { console.log("Exception: ", e) });
            };
            poll();
        }
    };

    const handleTrendFilterChange = (e) => {
        setTrendValue(e.target.value);
    }

    const goBack = (e) => {
        if (window.opener && !window.opener.closed) {
            window.opener.focus();
            window.close();
        } else {
            window.location.href = '/sast-dashboard';
        }
    }

    if (showLoader) {
        return (<Loader />);
    }

    if (_.isEmpty(reportDetails)) {
        return (<>No data to display</>);
    }
    else {
        return (
            <section className="mx-3">
                <section className="mb-3 d-flex align-items-center justify-content-start">
                    <h2 style={{ lineHeight: 1 }} className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start me-2">
                        <a className='me-2' onClick={goBack} href='#' style={{ fontSize: 22 }}>
                            <small ><FontAwesomeIcon icon={faArrowLeft} /></small>
                        </a>
                        <span className='ps-2' style={{ borderLeft: '1px solid rgba(108, 117, 125, 15%' }}>SAST Scan Report</span>
                    </h2>
                    {
                        (!_.isEmpty(reportDetails.assetType) && reportDetails.assetType != 'file-cli') &&
                        <Button
                            onClick={handleRescanClick}
                            className="custom-button btn-sm ms-3"
                            loading={loadingRows}
                        >
                            <FontAwesomeIcon icon={faArrowsRotate} className="me-2" />
                            Re-Scan
                        </Button>
                    }

                    {loadingRows && (
                        <span className="d-flex align-items-center justify-content-start ms-3">
                            <WelcomeLoader />{" "}
                            <small className="ms-1" style={{ fontSize: 14, fontWeight: 200 }}>
                                Scanning...
                            </small>
                        </span>
                    )}
                </section>
                <section>
                    <div className="d-flex align-items-center justify-content-start">
                        <span>{getAssetIcon(reportDetails.assetType)}</span>
                        <span className="me-2"><a href={reportDetails.assetUrl} target='_blank' > {getAssetName(reportDetails.assetUrl)}</a></span>
                        {reportDetails.branch && <span className="me-2">&lt;{reportDetails.branch}&gt;</span>}
                        <span className="text-secondary"><small><i>(<strong>Last Scan:</strong>  {moment.utc(reportDetails.scanDateTime).local().fromNow()})</i></small></span>
                    </div>
                </section>
                <section className='mb-3'>
                    <div className="row">
                        <div className="col-lg-6 custom-border-right ">
                            <div className="py-3 d-flex align-items-center w-100 h-100">
                                <div className="card-body">
                                    <div className="d-flex flex-row align-items-start justify-content-between">
                                        <div className="me-3">
                                            <p className="mb-0 text-secondary" style={{ fontSize: 12 }}>Total Vulnerabilities</p>
                                            <h1 className="head-tag" style={{ lineHeight: 1.1 }}>{getTotal(reportDetails.vulnerabilityCounts)}</h1>
                                        </div>
                                        <div className="d-flex flex-wrap flex-row align-items-start justify-content-center">
                                            <div className="me-3 mb-2">
                                                <DashboardSeverityIndicator severity={'Critical'} loadingRows={loadingRows} dashboardData={reportDetails.vulnerabilityCounts.critical} />
                                            </div>
                                            <div className="me-3 mb-2">
                                                <DashboardSeverityIndicator severity={'High'} loadingRows={loadingRows} dashboardData={reportDetails.vulnerabilityCounts.high} />
                                            </div>
                                            <div className="me-3 mb-2">
                                                <DashboardSeverityIndicator severity={'Medium'} loadingRows={loadingRows} dashboardData={reportDetails.vulnerabilityCounts.medium} />
                                            </div>
                                            <div className="me-3 mb-2">
                                                <DashboardSeverityIndicator severity={'Low'} loadingRows={loadingRows} dashboardData={reportDetails.vulnerabilityCounts.low} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="p-2">
                                <div className="card-body">
                                    <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                        <h5 className="fw-medium">Repository Vulnerability Trend</h5>
                                        <div>
                                            <Radio.Group value={trendValue} size="small" buttonStyle="solid" onChange={(e) => handleTrendFilterChange(e)}>
                                                <Radio.Button value="7" style={{ fontSize: 12 }}>1 Week</Radio.Button>
                                                <Radio.Button value="14" style={{ fontSize: 12 }}>2 Weeks</Radio.Button>
                                                <Radio.Button value="30" style={{ fontSize: 12 }}>1 Month</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    <div style={{ height: 300 }}>
                                        <TrendGraph reportDetails={reportDetails} trendValue={trendValue} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{position: 'relative'}}>
                    <Tabs
                        className="mb-3 report-tabs"
                        activeKey={activeTabsKey}
                        onSelect={(k) => setActiveTabsKey(k)}
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        <Tab eventKey="vulnerabilityView" title="Vulnerability View">
                            <SastVulnerabilityTabView reportDetails={reportDetails} userPrefrences={userPrefrences} />
                        </Tab>
                        <Tab eventKey="fileView" title="File View">
                            <SastFileTabView userPrefrences={userPrefrences} id={id} />
                        </Tab>
                        <Tab eventKey="OWASP10View" title="OWASP Top 10">
                            <OWASP10TabView reportDetails={reportDetails} userPrefrences={userPrefrences} />
                        </Tab>
                        <Tab eventKey="complianceStandardView" title="Compliance Standard">
                            <ComplianceStandardTabView reportDetails={reportDetails} userPrefrences={userPrefrences} />
                        </Tab>
                        <Tab eventKey="falsePositive" title="False Positive">
                            <SastFalsePositiveTabView id={id} />
                        </Tab>
                        <Tab eventKey="history" title="History">
                            <SastHistoryTabView reportDetails={reportDetails} id={id} />
                        </Tab>
                    </Tabs>
                </section>
                {showLoader && <Loader />}
            </section>
        );
    }
}