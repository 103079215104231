import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { faBook, faCloud, faCode, faDatabase, faDownload, faLayerGroup, faLock, faMicrochip, faPeopleGroup, faSliders, faTrowelBricks } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown, faAngleRight, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { BiSolidBellRing } from "react-icons/bi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserOutlined } from '@ant-design/icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Avatar } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash';
import axios from "axios";
import qs from 'qs';
import logo from '../../../assets/img/sec1-logo-svg-orange.svg';
import { GlobalConst } from '../../appConfig/globalConst';
import { MdDashboard } from "react-icons/md";
import { getUserDisplayName, getUserDisplayEmail, getUserDisplayChar, deleteCookie } from '../../helper/genHelper';
import { loaderState } from '../../redux/reducers/scannerReducer';
import { SiKubernetes } from "react-icons/si";
import { FaDocker } from "react-icons/fa6";
import { VscDebugConsole } from "react-icons/vsc";
import { TbLogin2 } from "react-icons/tb";

// SidebarSection Component
const SidebarSection = ({ title, children, defaultOpen = false }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    return (
        <div className="sidebar-section mb-0">
            <div
                className="d-flex align-items-center justify-content-between section-header"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="menu-title mb-0">{title}</span>
                <FontAwesomeIcon
                    icon={isOpen ? faAngleDown : faAngleRight}
                    className={`ms-2 section-arrow ${isOpen ? 'rotated' : ''}`}
                    style={{ fontSize: 11, color: '#838fb9' }}
                />
            </div>
            <div className={`sidebar-section-content ${isOpen ? 'open' : ''}`}>
                {children}
            </div>
        </div>
    );
};

// Main Component
export default function SidebarLinks({ userPrefrences, handleMobileSidebarClose }) {
    const authState = useSelector((state) => state.authReducer);
    const productsReducer = useSelector(state => state.productsReducer);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleLoginClick = (e) => {
        e.preventDefault();
        history.push('/login');
    }

    const handleLogoutClick = async (e) => {
        e.preventDefault();
        let refreshToken = await localStorage.getItem("__r");
        let accessToken = await localStorage.getItem("__t");
        const payload = qs.stringify({
            "refresh_token": refreshToken,
            "client_id": GlobalConst.CLIENT_ID,
            "client_secret": GlobalConst.CLIENT_SECRET
        });
        const headers = { headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8', "Authorization": `Bearer ${accessToken}` } };
        dispatch(loaderState(true));
        axios.post(`${GlobalConst.AUTH_API_URL}/realms/cve-buster-prod/protocol/openid-connect/logout`, payload, headers)
            .then(async (op) => {
                dispatch(loaderState(false));
                if (!_.isEmpty(op) && (op.status === 204 || op.status === 200)) {
                    localStorage.removeItem('__t');
                    localStorage.removeItem('__r');
                    deleteCookie();
                    window.location.href = GlobalConst.AUTH_APP_URL + '/login?logout=yes';
                }
            })
            .catch(e => {
                dispatch(loaderState(false));
                console.log("exception: ", e)
            });
    }

    const getAvatar = (size, addClass) => {
        if (!_.isEmpty(authState) && !_.isEmpty(authState.userDetails)) {
            return <Avatar style={{ backgroundColor: '#266FF2', color: '#ffffff' }} className={addClass} size={size ? size : 'default'}><span className='text-white'>{getUserDisplayChar(authState.userDetails)}</span></Avatar>;
        }
        else {
            return <Avatar icon={<UserOutlined />} />;
        }
    }

    const popover = (
        <Popover id="userloginPopover">
            <Popover.Body>
                <div className={'text-body border-bottom pb-3'}>
                    <div className='d-flex align-items-center justify-content-start'>
                        {getAvatar('large', 'me-2')}
                        <div className='ms-1 text-white'>
                            <p className='mb-0' style={{ lineHeight: 1 }}>Signed in as</p>
                            {(!_.isEmpty(getUserDisplayName(authState.userDetails))) && <p className='mb-0' style={{ fontWeight: 600 }}>{getUserDisplayName(authState.userDetails)}</p>}
                            <p className='mb-0 text-secondary' style={{ fontWeight: 300, fontSize: 11 }}>{getUserDisplayEmail(authState.userDetails)}</p>
                        </div>
                    </div>
                </div>
                <ul className='list-group list-group-flush'>
                    <li className="list-group-item pt-3 pb-1">
                        <button type="button" className="btn btn-link btn-sm p-0" onClick={handleLogoutClick}>
                            <FontAwesomeIcon icon={faRightFromBracket} className={'me-2'} />
                            Sign out
                        </button>
                    </li>
                </ul>
            </Popover.Body>
        </Popover>
    );

    const isProductSubscribed = (productkey) => {
        const matchingRecord = _.find(productsReducer.subscribedProducts.activePlans, plan => plan.sProductKey.includes(productkey.toLowerCase()))
        return !_.isEmpty(matchingRecord)
    }

    return (
        <div className="text-white min-vh-100">
            <div className="logo mx-auto text-center mt-25 mb-25">
                <img src={logo} style={{ height: 40 }} alt="Sec1 Logo" />
            </div>
            <div className="scroll-style" style={{ overflowY: 'auto', overflowX: 'none', paddingLeft: 10, paddingRight: 10 }}>
                <div className="d-flex align-items-center justify-content-between flex-column" style={{ height: 'calc(100vh - 91px)' }}>
                    <div className="d-flex align-items-center flex-column w-100">
                        <SidebarSection title="Menu" defaultOpen={true}>
                            <ul className="list-unstyled sidebar-nav">
                                <li>
                                    <NavLink
                                        to="/dashboard"
                                        activeClassName="active"
                                        className={`nav-link align-middle pe-0`}
                                        isActive={(match, location) => {
                                            return !_.isEmpty(location) && (location.pathname === '/' || location.pathname === '/dashboard');
                                        }}
                                        onClick={handleMobileSidebarClose}
                                    >
                                        <MdDashboard style={{ fontSize: 16, minWidth: '1.75rem' }} /> <span className="ms-1 d-flex align-items-baseline flex-grow-1">Unified Security</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/sca-dashboard"
                                        className="nav-link align-middle"
                                        activeClassName="active"
                                        onClick={handleMobileSidebarClose}
                                    >
                                        <FontAwesomeIcon
                                            icon={faLayerGroup}
                                            style={{ fontSize: 16, minWidth: '1.75rem' }}
                                        />
                                        <span className="ms-1">SCA</span>
                                    </NavLink>
                                    <ul className="list-unstyled sidebar-nav">
                                        <li>
                                            <NavLink to="/sast-dashboard" activeClassName="active" className={`nav-link align-middle pe-0 ${!isProductSubscribed(GlobalConst.PRODUCT_KEYS.SAST) ? 'pe-none link-disabled' : ''}`} onClick={handleMobileSidebarClose}>
                                                <FontAwesomeIcon icon={faCode} style={{ fontSize: 16, minWidth: '1.75rem' }} /> <span className="ms-1 d-flex align-items-baseline flex-grow-1">SAST {!isProductSubscribed(GlobalConst.PRODUCT_KEYS.SAST) && <FontAwesomeIcon icon={faLock} className='ps-1 feature-locking-icon ms-auto' />}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink to="/dast-dashboard" activeClassName="active" className={`nav-link align-middle pe-0 ${!isProductSubscribed(GlobalConst.PRODUCT_KEYS.DAST) ? 'pe-none link-disabled' : ''}`} onClick={handleMobileSidebarClose}>
                                        <VscDebugConsole style={{ fontSize: 16, minWidth: '1.75rem' }} /> <span className="ms-1 d-flex align-items-baseline flex-grow-1">DAST  {!isProductSubscribed(GlobalConst.PRODUCT_KEYS.DAST) && <FontAwesomeIcon icon={faLock} className='ps-1 feature-locking-icon ms-auto' />}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/container-security" activeClassName="active"
                                        className={`nav-link align-middle pe-0  ${!isProductSubscribed(GlobalConst.PRODUCT_KEYS.CONTEK) ? 'pe-none link-disabled' : ''}`} onClick={handleMobileSidebarClose}>
                                        <FaDocker style={{ fontSize: 16, minWidth: '1.75rem' }} /> <span className="ms-1 d-flex align-items-baseline flex-grow-1">Container Security  {!isProductSubscribed(GlobalConst.PRODUCT_KEYS.CONTEK) && <FontAwesomeIcon icon={faLock} className='ps-1 feature-locking-icon ms-auto' />}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/cloud-security-dashboard" activeClassName="active" className={`nav-link align-middle pe-0 ${!isProductSubscribed(GlobalConst.PRODUCT_KEYS.CLOUD_SECURITY) ? 'pe-none link-disabled' : ''}`}>
                                        <FontAwesomeIcon icon={faCloud} style={{ fontSize: 16, minWidth: '1.75rem' }} /> <span className="ms-1 d-flex align-items-baseline flex-grow-1">Cloud Security {!isProductSubscribed(GlobalConst.PRODUCT_KEYS.CLOUD_SECURITY) && <FontAwesomeIcon icon={faLock} className='ps-1 feature-locking-icon ms-auto' />}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/k8s-dashboard" activeClassName="active" className={`nav-link align-middle pe-0  ${!isProductSubscribed(GlobalConst.PRODUCT_KEYS.KUBERNETES) ? 'pe-none link-disabled' : ''}`}>
                                        <SiKubernetes style={{ fontSize: 16, minWidth: '1.75rem' }} alt="Kubernetes" /> <span className="ms-1 d-flex align-items-baseline flex-grow-1">Kubernetes Security</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/ai-threat-vision" activeClassName="active"
                                        className={`nav-link align-middle pe-0  ${!isProductSubscribed(GlobalConst.PRODUCT_KEYS.THREAT_VISION) ? 'pe-none link-disabled' : ''}`} onClick={handleMobileSidebarClose}>
                                        <FontAwesomeIcon icon={faMicrochip} style={{ fontSize: 16, minWidth: '1.75rem' }} /> <span className="ms-1 d-flex align-items-baseline flex-grow-1">Threat Vision {!isProductSubscribed(GlobalConst.PRODUCT_KEYS.THREAT_VISION) && <FontAwesomeIcon icon={faLock} className='ps-1 feature-locking-icon ms-auto' />}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/user-prefrences" activeClassName="active" className="nav-link align-middle" onClick={handleMobileSidebarClose}>
                                        <FontAwesomeIcon icon={faSliders} style={{ fontSize: 16, minWidth: '1.75rem' }} /> <span className="ms-1">Configure</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/integrations-list" activeClassName="active" className="nav-link align-middle" onClick={handleMobileSidebarClose}>
                                        <FontAwesomeIcon icon={faTrowelBricks} style={{ fontSize: 16, minWidth: '1.75rem' }} /> <span className="ms-1">Integrations</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/team" activeClassName="active" className="nav-link align-middle" onClick={handleMobileSidebarClose}>
                                        <FontAwesomeIcon icon={faPeopleGroup} style={{ fontSize: 16, minWidth: '1.75rem' }} /> <span className="ms-1">Team</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </SidebarSection>

                        <SidebarSection title="Utils">
                            <ul className="list-unstyled sidebar-nav">
                                <li>
                                    <a href="https://galaxyguard.sec1.io" className="nav-link align-middle" target="_blank" rel="noopener noreferrer" onClick={handleMobileSidebarClose}>
                                        <FontAwesomeIcon icon={faDatabase} style={{ fontSize: 16, minWidth: '1.75rem' }} />
                                        <span className="ms-1">Galaxy Guard</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://hawk.sec1.io/" className="nav-link align-middle" target="_blank" rel="noopener noreferrer" onClick={handleMobileSidebarClose}>
                                        <BiSolidBellRing style={{ fontSize: 16, minWidth: '1.75rem' }} />
                                        <span className="ms-1">Sec1Hawk</span>
                                    </a>
                                </li>
                            </ul>
                        </SidebarSection>

                        <SidebarSection title="Other Links">
                            <ul className="list-unstyled sidebar-nav">
                                <li>
                                    <NavLink to="/downloads" activeClassName="active" className="nav-link align-middle" onClick={handleMobileSidebarClose}>
                                        <FontAwesomeIcon icon={faDownload} style={{ fontSize: 16, minWidth: '1.75rem' }} />
                                        <span className="ms-1">Downloads</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <a href="https://docs.sec1.io" className="nav-link align-middle" target="_blank" rel="noopener noreferrer" onClick={handleMobileSidebarClose}>
                                        <FontAwesomeIcon icon={faBook} style={{ fontSize: 16, minWidth: '1.75rem' }} />
                                        <span className="ms-1">Documentation</span>
                                    </a>
                                </li>
                            </ul>
                        </SidebarSection>
                    </div>

                    <div className="d-flex align-items-center flex-column">
                        <ul className="list-unstyled sidebar-nav pb-4">
                            <li>
                                {!_.isEmpty(authState) && !_.isEmpty(authState.token) ? (
                                    <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
                                        <span className='text-white' style={{ cursor: 'pointer' }}>
                                            {getAvatar()}
                                            {(!_.isEmpty(getUserDisplayName(authState.userDetails))) &&
                                                <span className="ms-2">{getUserDisplayName(authState.userDetails)}</span>
                                            }
                                            <FontAwesomeIcon className="ms-1" icon={faAngleDown} />
                                        </span>
                                    </OverlayTrigger>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={handleLoginClick}
                                        className="btn btn-link btn-sm d-flex align-items-center justify-content-center flex-row"
                                    >
                                        <TbLogin2 className="me-2" /> Login
                                    </button>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}