import axios from "axios";
import _ from 'lodash';
import { getDummyHeader } from "../../shared/helper/genHelper";
import { GlobalConst } from "../appConfig/globalConst";
import store from '../redux/store';
import { setIScanModalState } from "../redux/reducers/dastReducer";

export const handleDastFormSubmit = (values, opType) => {
    const formData = new FormData();
    //console.log("values : ", values, authType);
    let scanType = values.scanType;
    let scanAuthType = values.scanAuthType;

    formData.append('scanType', scanType);
    formData.append('authType', scanAuthType);
    formData.append('url', values.url);
    if (scanType === 'api') {
        if (values.schemaFile) {
            formData.append('apiCollection', values.schemaFile);
        } else {
            if (values.fileId) {
                formData.append('fileId', values.fileId);
            }
            if (values.fileName) {
                formData.append('fileName', values.fileName);
            }
        }
    }
    if (scanAuthType === 'OAUTH2') {
        let oAuth2Configuration = { "authUrl": values.authUrl, "realmName": values.realmName, "clientId": values.clientId, "clientSecret": values.clientSecret, "userId": values.userId, "password": values.password };
        if (values.configId && opType === 'config') {
            oAuth2Configuration.configId = values.configId;
        }
        formData.append('oauth2', JSON.stringify(oAuth2Configuration));
    }
    if (scanAuthType === 'HEADERS' && !_.isEmpty(values.headers)) {
        console.log("headers in api : ", values.headers)
        let headersJson = {};
        values.headers.forEach((header, index) => {
            headersJson[header.name] = header.value;
        });
        if (values.configId && opType === 'config') {
            formData.append("configId", values.configId);
        }
        console.log("headers in api json : ", headersJson)
        formData.append('headers', JSON.stringify(headersJson));
    }

    // const dummyHeaders = getDummyHeader();
    // dummyHeaders["Content-Type"] = "multipart/form-data";
    let response = null;
    let url = opType && opType === 'config' ? `${GlobalConst.API_URL}/auth/dast/scan/config` : `${GlobalConst.API_URL}/auth/dast/ascan`;

    const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
    axios
        .post(url, formData, config)
        .then((op) => {
            if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                response = op.data;
                store.dispatch(setIScanModalState(false));
                window.location.reload();
            }
        })
        .catch((e) => { });
    return response;
}