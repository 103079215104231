import React, { useState } from 'react';
import { Field } from 'formik';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

// Define the functional component
export const HeadersOptionsHelper = ({headers, setHeaders, values, updateHeaders}) => {
    console.log("headers : ", headers);
    const addHeader = () => {
        if (headers.length < 5) {
            const newHeaders = [...headers, { name: '', value: '' }];
            setHeaders(newHeaders);
            updateHeaders(newHeaders);
        }
    };

    const removeHeader = (index) => {
        if (headers.length > 1) { // Ensure at least one header remains
            const newHeaders = headers.filter((_, i) => i !== index);
            setHeaders(newHeaders);
            updateHeaders(newHeaders);
        }
    };

    const handleHeaderChange = (index, field, value) => {
        const newHeaders = [...headers];
        newHeaders[index] = { ...newHeaders[index], [field]: value };
        setHeaders(newHeaders);
        updateHeaders(newHeaders);
    };
    return (
        <>
            <div className="mb-3">
                <div className='row text-left'>
                    <div className='col-sm-5'>Key</div>
                    <div className='col-sm-5'>Value</div>
                    <div className='col-sm-2'></div>
                </div>
                {headers.map((header, index) => (
                    <div key={index} className="row mb-2 align-items-center">
                        <div className="col-sm-5">
                            <div className="input-group">
                                <Field
                                    name={`headers[${index}].name`}
                                    type="text"
                                    className="form-control"
                                    placeholder={`Key`}
                                    value={header.name}
                                    onChange={(e) => handleHeaderChange(index, 'name', e.target.value)}
                                    style={{ padding: '10px 20px' }}
                                />
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="input-group">
                                <Field
                                    name={`headers[${index}].value`}
                                    type="text"
                                    className="form-control"
                                    placeholder={`Value`}
                                    value={header.value}
                                    onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
                                    style={{ padding: '10px 20px' }}
                                />
                            </div>
                        </div>
                        <div className="col-sm-2 d-flex justify-content-end">
                            {headers.length == 5 && (
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                            )}
                            {headers.length < 5 && (
                                 <span className="input-group-text" onClick={addHeader}>
                                 <FontAwesomeIcon icon={faPlus} />
                             </span>
                            )}
                            
                            {index == 0 && (
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={faMinus} />
                                </span>
                            )}
                            {index > 0 && (
                                <span className="input-group-text"  onClick={() => removeHeader(index)}>
                                    <FontAwesomeIcon icon={faMinus} />
                                </span>
                            )}

                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};


