import moment from "moment";
import _ from 'lodash';
import { GlobalConst } from "../appConfig/globalConst";
// import { useSelector } from "react-redux";
import { getAWSIcon } from "./getAWSIconsHelper";
import { getGCPIcon } from "./getGCPIconsHelper";
import cloudIcon from '../../assets/img/cloud.svg';
import { getK8sIcon } from "./getK8sIconHelper";



export function deleteCookie() {
  document.cookie = 'session_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=localhost';
  document.cookie = 'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=localhost';
}

export const getAgoDate = (date) => {
  return moment(date).fromNow();
  // return moment().diff(moment(date), "hours") > 24
  //   ? moment(date).format("DD MMM YYYY")
  //   : moment(date).fromNow();
};

export const getDate = (date) => {
  return moment(date).format("DD MMM YYYY");
}


export const getStrokeColor = (severity) => {

  // console.log("Severity::", severity);
  if (severity) {
    if (severity.toLowerCase() === "high") {
      return GlobalConst.CHART_COLORS[13];
    }
    else if (severity.toLowerCase() === 'critical') {
      return GlobalConst.CHART_COLORS[12];
    }
    else if (severity.toLowerCase() === 'medium') {
      return GlobalConst.CHART_COLORS[14];
    }
    else if (severity.toLowerCase() === 'low') {
      return GlobalConst.CHART_COLORS[15];
    }
  }

}

export const getFontColor = (severity) => {
  if (severity) {
    if (severity.toLowerCase() === "high") {
      return '#212121';
    }
    else if (severity.toLowerCase() === 'critical') {
      return '#212121';
    }
    else if (severity.toLowerCase() === 'medium') {
      return '#212121';
    }
    else if (severity.toLowerCase() === 'low') {
      return '#212121';
    }
    else {
      return '#919191';
    }
  }

}

export const getSeverityInitial = (severity) => {
  if (severity) {
    if (severity.toLowerCase() === "high") {
      return 'H';
    }
    else if (severity.toLowerCase() === 'critical') {
      return 'C';
    }
    else if (severity.toLowerCase() === 'medium') {
      return 'M';
    }
    else if (severity.toLowerCase() === 'low') {
      return 'L';
    }
  }

}

// if (severity === "HIGH") {
//   return "#f86318";
// } else if (severity === "CRITICAL") {
//   return "#cc3300";
// } else if (severity === "MEDIUM") {
//   return "#ffa435";
// } else if (severity === "MODERATE") {
//   return "#ffa435";
// } else if (severity === "LOW") {
//   return "#ebe438";
// }

export const getApplicationRag = (ragStatus) => {

  // red - this application is not ready for prod deployment
  // amber - this application is ready for prod deployment with risk
  // green - your application is ready for production

  switch (ragStatus) {

    case 'red':
      return 'Red'
    case 'ambar':
      return 'Amber';
    default:
      return 'Green';
  }

  // if (!_.isEmpty(severityCount)) {
  //   if (severityCount.critical) {
  //     return 'CRITICAL';
  //   }
  //   else if (severityCount.critical === 0 && severityCount.high) {
  //     return 'HIGH';
  //   }
  //   else if (severityCount.critical === 0 && severityCount.high === 0 && severityCount.medium) {
  //     return 'MEDIUM';
  //   }
  //   else if (severityCount.critical === 0 && severityCount.high === 0 && severityCount.medium === 0 && severityCount.low) {
  //     return 'LOW';
  //   }
  //   else {
  //     return 'LOW';
  //   }
  // }
}

export const getCWENumber = (CWEId) => {
  if (!_.isEmpty(CWEId)) {
    let splited = CWEId.split('-');
    return splited[1];
  }
  else {
    return '';
  }
}

export const getPackageName = (val) => {
  let result = '';
  if (!_.isEmpty(val)) {
    let splitedText = val.split(":");
    result = splitedText[1];
  }
  return result;
}

export const getUserDisplayChar = (user) => {
  // Check if the input is empty (null or undefined)
  if (_.isEmpty(user)) {
    return '';
  }

  let text = (user.preferred_username) ? user.preferred_username : user.email;
  // Extract the first character of the text and capitalize it
  const firstInitial = _.upperCase(_.head(text));

  return firstInitial;
}

export const getUserDisplayName = (user) => {
  // console.log("user", user);
  if (!_.isEmpty(user)) {
    if (user.name) {
      return user.name;
    }
    else if (user.preferred_username) {
      return user.preferred_username;
    }
    else {
      return null;
    }
  }
  else {
    return null;
  }
}

export const getUserDisplayEmail = (user) => {
  if (!_.isEmpty(user)) {
    if (user.email) {
      return user.email;
    }
  }
  else {
    return null;
  }
}

export const getUserAvatarInitials = (userName) => {
  if (!_.isEmpty(userName)) {
    return Array.from(userName)[0];
  }
  else {
    return null;
  }
}

export const removeGitSuffix = (text) => {
  if (text && text.endsWith('.git')) {
    return text.slice(0, -4);
  }
  return text;
}

export const randomNumberLess100 = () => {
  return Math.floor(Math.random() * 90) + 10;
}

export const getIntegratedState = (integratedSystems, keyToCheck) => {
  // let integratedSystems = useSelector(state => state.integrationReducer.allIntegratedSystems);
  // if(!_.isEmpty(integratedSystems)){
  if (_.has(integratedSystems, keyToCheck)) {
    const value = integratedSystems[keyToCheck];
    if (value.isIntegrated) {
      return true;
    }
    else {
      return false;
    }
  } else {
    return false;
  }
  // }
  // else{
  //   return false;
  // }

}


function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}


export const getWebAppName = (key) => {
  let name = "";
  if (isValidHttpUrl(key)) {
    var pathname = new URL(key).pathname;
    if (!_.isEmpty(pathname) && pathname.length > 2) {
      pathname = pathname.substring(1);
      name = pathname;
    }
    else {
      name = key;
    }
  } else {
    name = key;
  }
  return name;
}


export const getAssetName = (key) => {
  let name = "";
  if (isValidHttpUrl(key)) {
    var pathname = new URL(key).pathname;
    pathname = pathname.substring(1);
    name = pathname;
  } else {
    name = key;
  }
  return name;
}

export const getDummyHeader = () => {
  const headers = {
    'Content-Type': 'application/json',
    'x-userinfo': 'eyJpc3MiOiJodHRwczovL2F1dGguc2VjMS5pby9yZWFsbXMvY3ZlLWJ1c3Rlci1wcm9kIiwiYXpwIjoiY3ZlLWJ1c3Rlci1hcGlzaXgtcHJvZCIsImFjciI6IjEiLCJzZXNzaW9uX3N0YXRlIjoiNWRlMzU2OWMtNjM5OC00NGY2LThmYzEtNDk1ZmRmOWNlNmYyIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwiaWF0IjoxNzE4Mjg1MDkwLCJhY3RpdmUiOnRydWUsInVzZXJuYW1lIjoiZGluZXNocmF3YXQ3OCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZW1haWwiOiJkaW5lc2gucmF3YXQ3OEBnbWFpbC5jb20iLCJuYW1lIjoiQ0dUTVNFIFN1cHBvcnQiLCJhdWQiOiJicm9rZXIiLCJzaWQiOiI1ZGUzNTY5Yy02Mzk4LTQ0ZjYtOGZjMS00OTVmZGY5Y2U2ZjIiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwicmVzb3VyY2VfYWNjZXNzIjp7ImJyb2tlciI6eyJyb2xlcyI6WyJyZWFkLXRva2VuIl19fSwic3ViIjoiNDIwM2JmZDQtM2VmZC00NmM5LWJhMWYtNzc0NDhkYjQwNjFmIiwidHlwIjoiQmVhcmVyIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwiZGVmYXVsdC1yb2xlcy1jdmUtYnVzdGVyLXByb2QiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwiY2xpZW50X2lkIjoiY3ZlLWJ1c3Rlci1hcGlzaXgtcHJvZCIsImV4cCI6MTcxODI4NTk5MCwianRpIjoiYmVkYTZlMzQtMjJhYy00NjkxLTllYTUtMDNkMTY1NDA1MzU4IiwiZ2l2ZW5fbmFtZSI6IkRpbmVzaCBSYXdhdCIsImZhbWlseV9uYW1lIjoiU3VwcG9ydCIsInByZWZlcnJlZF91c2VybmFtZSI6ImRpbmVzaC5yYXdhdDc4QGdtYWlsLmNvbSJ9'
  };
  return headers;
}

export const shortenPath = (path) => {
  const parts = path.split('/');
  if (parts.length <= 2) return path;

  const fileName = parts.pop();
  const parentFolder = parts.pop();
  return `${'../'.repeat(parts.length)}${parentFolder}/${fileName}`;
};

export const interpolateColor = (value) => {
  // Ensure the value is between 0 and 1
  value = Math.max(0, Math.min(1, value));
  // Calculate red and green components
  const red = Math.round(255 * value);
  const green = Math.round(255 * (1 - value));
  // Convert to hexadecimal and pad with zeros if necessary
  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  // Construct and return the color string
  return `#${redHex}${greenHex}00`;
}

export const getAIAssetScanStrokeColor = (severity) => {

  if (severity) {


    if (severity === "HIGH") {
      return GlobalConst.AI_CHART_COLORS[1];
    }
    else if (severity === 'CRITICAL') {
      return GlobalConst.AI_CHART_COLORS[0];
    }
    else if (severity === 'MEDIUM') {
      return GlobalConst.AI_CHART_COLORS[2];
    }
    else if (severity === 'LOW') {
      return GlobalConst.AI_CHART_COLORS[3];
    }
  }
}


export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getCloudVMStatusColorCode = (status) => {
  if (status.toLowerCase() === 'running') {
    return {
      tagBackgroundColor: '#00A261',
      fontColor: '#212121'
    };
  }
  else {
    return {
      tagBackgroundColor: GlobalConst.CHART_COLORS[12],
      fontColor: '#212121'
    };
  }
}

export const validateActivePlans = (data) => {
  if (_.isArray(data.activePlans) && data.activePlans.length <= 1) {
    const [plan] = data.activePlans;
    if (_.isObject(plan) && plan.planName === "Free") {
      return false; // Ignore the record
    }
  }
  return true; // Valid record
};

export function getPlanName(returnedProduct) {
  if (!_.isEmpty(returnedProduct)) {
    let splitedPlanName = returnedProduct.split('_');
    // console.log("SplitedPlan Name", splitedPlanName);
    if (!_.isEmpty(splitedPlanName) && splitedPlanName.length > 0) {
      // console.log("I am the plan nameLL", splitedPlanName[1].trim().toLowerCase());
      return splitedPlanName[1].trim().toLowerCase();
    }
    return null;
  }
  else {
    return false;
  }
}

export function getPlanTypeFromProductKey(returnedProduct) {
  if (!_.isEmpty(returnedProduct)) {
    let splitedPlanName = returnedProduct.split('_');
    // console.log("SplitedPlan Name", splitedPlanName);
    if (!_.isEmpty(splitedPlanName) && splitedPlanName.length > 0) {
      // console.log("I am the plan nameLL", splitedPlanName[1].trim().toLowerCase());
      return splitedPlanName[0].trim().toLowerCase();
    }
    return null;
  }
  else {
    return false;
  }
}

export const isProductSubscribed = (activePlans, productKey) => {
  if (!_.isEmpty(activePlans)) {
    const matchingRecord = _.find(activePlans, plan => getPlanName(plan.sProductKey) === productKey)
    return !_.isEmpty(matchingRecord)
  } else {
    return false
  }
}

export const getProductSubscription = (activePlans, productKey) => {
  if (!_.isEmpty(activePlans)) {
    const planSubscription = _.find(activePlans, plan => getPlanName(plan.sProductKey) === productKey)
    return planSubscription
  } else {
    return null
  }
}

export const getCloudIcon = (iconName, target) => {
  if (target === 'aws') {
    return getAWSIcon(iconName);
  }
  if (target === 'gcp') {
    return getGCPIcon(iconName);
  }
  if (target === 'k8s') {
    return getK8sIcon(iconName);
  }
  else {
    return cloudIcon;
  }
}

export const getScanStatusBackgroundColor = (status) => {
  switch (status.toUpperCase()) {
    case 'COMPLETED':
      return '#13352A'; // green
    case 'INITIATED':
      return "#182958"; // blue
    case 'FAILED':
      return '#491C23'; // red
    default:
      return '#6b6b6b'; // grey
  }
};

export const getScanStatusTextColor = (status) => {
  switch (status.toUpperCase()) {
    case 'COMPLETED':
      return '#41C272'; // green
    case 'INITIATED':
      return "#5EA1F5"; // blue
    case 'FAILED':
      return '#F67070'; // red
    default:
      return '#ffffff'; // grey
  }
};

export function getOverallStatus(inventoryStatus, securityStatus) {

  if (!inventoryStatus) {
    return securityStatus || "COMPLETED";
  }
  if (!securityStatus) {
    return inventoryStatus || "COMPLETED";
  }

  // If both are present, follow the prioritized logic
  if (inventoryStatus === "FAILED" || securityStatus === "FAILED") {
    return "FAILED";
  }
  if (inventoryStatus === "INITIATED" || securityStatus === "INITIATED") {
    return "INITIATED";
  }
  if (inventoryStatus === "SCANNING" || securityStatus === "SCANNING") {
    return "SCANNING";
  }

  // If we reach here, both are COMPLETED
  return "COMPLETED";
}

export function getCloudSecurityScanStatus(allStatus) {
  // Return early if allStatus is empty
  if (!allStatus || Object.keys(allStatus).length === 0) {
    return '';
  }

  let excludeList = !_.isEmpty(GlobalConst.EXCLUDED_CLOUD_SCAN_STATUS) ? GlobalConst.EXCLUDED_CLOUD_SCAN_STATUS : [];
  // Convert to array of entries and filter out excluded scans
  const eligibleScans = Object.entries(allStatus)
    .filter(([key]) => !excludeList.includes(key));

  // Find all scans that are INITIATED or SCANNING
  const inProgressScans = eligibleScans
    .filter(([_, value]) => ['INITIATED', 'SCANNING'].includes(value.status));

  if (inProgressScans.length > 0) {
    // Sort by scanTime in descending order (most recent first)
    inProgressScans.sort(([, a], [, b]) =>
      new Date(b.scanTime).getTime() - new Date(a.scanTime).getTime()
    );
    // Return the most recent in-progress scan
    return formatScanKey(inProgressScans[0][0]);
  }

  // If no in-progress scans, return the first non-excluded scan
  return eligibleScans.length > 0 ? formatScanKey(eligibleScans[0][0]) : '';
}

// Helper function to format the scan key
export function formatScanKey(key) {
  return key
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function calculatePercentageDifference(currentValue, previousValue) {
  // Validate inputs
  if (!_.isNumber(currentValue) || !_.isNumber(previousValue)) {
      throw new Error('Both inputs must be valid numbers');
  }
  
  // Handle division by zero
  if (previousValue === 0) {
      if (currentValue === 0) return 0;
      return 100; // If previous is 0 and current is not, represents 100% increase
  }
  
  const difference = currentValue - previousValue;
  const percentageDifference = (difference / previousValue) * 100;
  
  return _.round(percentageDifference, 2);
}