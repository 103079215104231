import axios from "axios";
import { useEffect, useState } from "react";
import _ from 'lodash';
import { Tag, Steps, Tooltip } from "antd";
import { getDummyHeader, getFontColor, getStrokeColor } from '../../shared/helper/genHelper';
import Loader from "../../shared/sharedComponents/loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCheckCircle, faCaretRight, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { GlobalConst } from "../../shared/appConfig/globalConst";

export default function DastHistoryTabView({ reportDetails, id }) {

    const [loading, setLoading] = useState(false);
    const [scanHistoryItems, setScanHistoryItems] = useState([]);

    useEffect(() => {
        if (!_.isEmpty(reportDetails)) {
            getHistoryData();
        }
    }, [reportDetails]);

    const getHistoryData = () => {
        const postBody = {
            "assetUrl": reportDetails.assetUrl
        };
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/dast/asset/history`, postBody)
            .then(op => {
                setLoading(false);
                updateScanHistoryItems(op.data);
            })
            .catch(e => { setLoading(false); })
    }

    const handleShowReportClick = (reportId) => {
        window.location.href = `/dast-advance-dashboard/${reportId}`;
    }

    const updateScanHistoryItems = (historyData) => {

        let data = [];
        if (!_.isEmpty(historyData)) {
            // const dataArray = _.map(historyData, (value, date) => ({ date, ...value }));
            const dataArray = _.cloneDeep(historyData);

            // Sort the array by date
            const descendingArray = _.orderBy(dataArray, ['scanDateTime'], ['asc']);

            let lastSuccessTotalCVE = 0;
            let newDataArray = [];
            // newDataArray = newDataArray.reverse();
            descendingArray.forEach((el, key) => {
                let delta = null;
                const total = el.vulnerabilityCounts.critical + el.vulnerabilityCounts.high + el.vulnerabilityCounts.medium + el.vulnerabilityCounts.low;
                if (total > lastSuccessTotalCVE) {
                    delta = key > 0 ? 'up' : null;
                } else if (total < lastSuccessTotalCVE) {
                    delta = key > 0 ? 'down' : null;
                }

                newDataArray.push({ ...el, delta, deltaDiff: Math.abs(lastSuccessTotalCVE - total) });

                if (el.status.toLowerCase() === 'completed') {
                    lastSuccessTotalCVE = total;
                }
            });
            newDataArray = newDataArray.reverse();
            _.forEach(newDataArray, (value, key) => {
                if (!_.isEmpty(value)) {
                    data.push({
                        title: <div style={{ fontSize: 14 }} className={`d-flex align-items-center justify-content-center flex-row px-3 py-1 card card-custom timelineCard ${(!_.isEmpty(id) && id === value.reportId) ? 'active' : ''} ${(value.status.toLowerCase() === 'failed') ? 'disabled' : ''}`} >
                            <div style={{ minWidth: 230 }} className="d-flex align-items-center justify-content-between">
                                <div>
                                    {(!_.isEmpty(id) && id === value.reportId) && <FontAwesomeIcon className="me-2 text-primary-emphasis" icon={faCaretRight} />}  {moment.utc(value.scanDateTime).local().fromNow()}
                                    {(!_.isEmpty(value.branch)) && <span className="ms-2 text-secondary"><i>&lt;{value.branch}&gt;</i></span>}
                                    {(value.status.toLowerCase() === 'failed') && <Tooltip title={value.errorMessage}><FontAwesomeIcon className="ms-2 text-danger" icon={faCircleXmark} style={{ fontSize: 12 }} /></Tooltip>}
                                    {(value.status.toLowerCase() === 'completed') && <Tooltip title={"Scan Success"}><FontAwesomeIcon className="ms-2 text-success" icon={faCheckCircle} style={{ fontSize: 12 }} /></Tooltip>}
                                </div>
                                <div>
                                    {value.delta === 'up' && <Tag><FontAwesomeIcon icon={faArrowUp} className="text-danger me-1" /> {value.deltaDiff}</Tag>}
                                    {value.delta === 'down' && <Tag><FontAwesomeIcon icon={faArrowDown} className="text-success me-1" /> {value.deltaDiff}</Tag>}
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <div style={{ minWidth: 460 }}>
                                    <Tag className="ms-2">{value.vulnerabilityCounts.critical + value.vulnerabilityCounts.high + value.vulnerabilityCounts.medium + value.vulnerabilityCounts.low} Total vulnerabilities</Tag>
                                    <Tag className="ms-2" style={{ color: getFontColor('CRITICAL'), fontWeight: 700 }} color={getStrokeColor('CRITICAL')}>{value.vulnerabilityCounts.critical} Critical</Tag>
                                    <Tag style={{ color: getFontColor('HIGH'), fontWeight: 700 }} color={getStrokeColor('HIGH')}>{value.vulnerabilityCounts.high} High</Tag>
                                    <Tag style={{ color: getFontColor('MEDIUM'), fontWeight: 700 }} color={getStrokeColor('MEDIUM')}>{value.vulnerabilityCounts.medium} Medium</Tag>
                                    <Tag style={{ color: getFontColor('LOW'), fontWeight: 700 }} color={getStrokeColor('LOW')}>{value.vulnerabilityCounts.low} Low</Tag>
                                </div>
                                <div className="text-secondary-emphasis" style={{fontSize: 12}}><small><i><b>Run by</b> - {value.userId}</i></small></div>
                                {(value.status.toUpperCase().toLowerCase() === 'completed') && <a className="ms-5 btn-link" onClick={(e) => { e.preventDefault(); handleShowReportClick(value.reportId) }} href="#">View Report</a>}
                            </div>
                        </div>,
                        description: <></>,
                    });
                }
            });
            setScanHistoryItems(data);
        }
    }

    if (loading) {
        return <Loader />
    }
    else {
        return (
            <section className="my-2">
                <h3 className="text-white">Scan History</h3>
                <div className="mt-3">
                    <Steps
                        progressDot
                        current={0}
                        direction="vertical"
                        className="text-white scan-history-timeline"
                        items={scanHistoryItems}
                    />
                </div>
            </section>
        )
    }
}