import { useEffect, useState } from "react";
import _ from 'lodash';
import moment from "moment";
import { Button, Input, Tooltip, Tag, Space, Table, Popconfirm, Modal as AntModal, message } from "antd";
import { getFontColor, getStrokeColor } from "../../shared/helper/genHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGitAlt } from "@fortawesome/free-brands-svg-icons";
import { useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
// import Loader from "../welcomeScreen/loader";
import { setDastScanTriggeredState, addUpdateDastDashboardTableData } from "../../shared/redux/reducers/dastReducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Flex, Progress } from 'antd';
import { faArrowsRotate, faChevronDown, faChevronUp, faCircleStop, faStop } from "@fortawesome/free-solid-svg-icons";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Modal, Button as BootstrapButton } from 'react-bootstrap';
import Loader from "../../shared/sharedComponents/loader";
import UrlDiscoveries from "./urlDiscovery";
import ProcessLoader from "../../shared/sharedComponents/processLoader";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { RiQrScan2Line } from "react-icons/ri";
import { AiOutlineDashboard } from "react-icons/ai";

export default function DastStatisticsTable({ }) {

    const dispatch = useDispatch();
    const dastReducerState = useSelector(state => state.dastReducer);
    const [urlDiscoveries, setUrlDiscoveries] = useState([]);
    const [isURLDiscoveryLoading, setIsURLDiscoveryLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [scanStatus, setScanStatus] = useState('');
    const [scanProgress, setScanProgress] = useState(0);
    const [scanResult, setScanResult] = useState(null);
    const [currentUrl, setCurrentUrl] = useState('');
    const [pollingInterval, setPollingInterval] = useState(null);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [viewVulnerabilityModal, setViewVulnerabilityModal] = useState(false);

    const handleCloseViewVulnerabilityModal = () => {
        setViewVulnerabilityModal(false);
    }

    const handleReScanClick = (e, toScanRow) => {
        e.preventDefault();
        triggerRescan(toScanRow);
    }


    const triggerRescan = (toScanRow) => {
        if (!_.isEmpty(toScanRow)) {
            dispatch(setDastScanTriggeredState(true));
            const postBody = { "configId": toScanRow.configId, "url": toScanRow.assetUrl };
            axios
                .post(`${GlobalConst.API_URL}/auth/dast/rescan`, postBody)
                .then((op) => {
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        dispatch(addUpdateDastDashboardTableData(op.data));
                    }
                })
                .catch((e) => { });
        }
        else {
            // show a alert message to tell that invalid row selected to scan
        }

    }


    const isScanning = (recordId) => {
        const isPresent = dastReducerState.dastTriggeredScans.find(opRecord => opRecord === recordId);
        if (isPresent) {
            return true;
        }
        else {
            return false;
        }
    }

    const openChildPage = (e, reportId) => {
        e.preventDefault();
        window.open(`/dast-advance-dashboard/${reportId}`, '_blank');
    }

    const handleExpand = (isExpanding, record) => {
        if (isExpanding) {
            if (_.isEmpty(urlDiscoveries.find(({ reportId }) => reportId === record.reportId)))
                getSiteURLDiscovery(record.reportId, record.configId)
        }
    }

    const getSiteURLDiscovery = (reportId, configId) => {
        setIsURLDiscoveryLoading(true);
        const postBody = { reportId };
        axios
            .post(`${GlobalConst.API_URL}/auth/dast/url-discovery`, postBody)
            .then(response => {
                setUrlDiscoveries([...urlDiscoveries,
                {
                    reportId,
                    configId,
                    discoveries: response.data.resources
                }])
                setIsURLDiscoveryLoading(false);
            })
            .catch(e => {
                console.log("Exception:", e);
                setIsURLDiscoveryLoading(false);
            });
    }

    const startScan = (url, configId) => {
        const postBody = {
            url,
            configId
        };
        setIsModalVisible(true);
        setScanStatus('Initiating scan...');
        setScanProgress(0);
        setCurrentUrl(url);
        axios
            .post(`${GlobalConst.API_URL}/auth/dast/scan/targeted-scan`, postBody)
            .then(response => {
                // console.log("Scan initiated:", response.data);
                message.success('Scan initiated successfully');
                setScanStatus('Scan in progress...');
                pollScanStatus(response.data[0].scanId, url);
            })
            .catch(e => {
                console.log("Scan Exception:", e);
                message.error('Failed to initiate scan');
                setIsModalVisible(false);
            });
    };

    const pollScanStatus = (scanId, url) => {
        const interval = setInterval(() => {
            axios.post(`${GlobalConst.API_URL}/auth/dast/scan/targeted-scan/report`,
                { url: url, scanId: scanId }
            )
                .then(response => {
                    if (response.data.status === 100) {
                        clearInterval(interval);
                        setScanStatus('Scan complete');
                        setScanProgress(100);
                        // Add a slight delay before showing "Loading results"
                        setTimeout(() => {
                            setScanStatus('Scan complete. Loading results...');
                            // Add another slight delay before setting the scan result
                            setTimeout(() => {
                                setScanResult(response.data);
                                setScanStatus('Scan completed');
                                setPollingInterval(null);
                            }, 1000);
                        }, 1000);
                    } else {
                        setScanStatus(`Scan in progress`);
                        setScanProgress(response.data.status);
                    }
                })
                .catch(e => {
                    console.log("Polling Exception:", e);
                    clearInterval(interval);
                    setScanStatus('Error checking scan status');
                    setIsModalVisible(false);
                    setPollingInterval(null);
                    message.error('Error checking scan status');
                });
        }, 5000);
        setPollingInterval(interval);
    };

    const handleModalClose = () => {
        if (pollingInterval || scanStatus !== 'Scan completed') {
            setIsConfirmModalVisible(true);
        } else {
            closeModal();
        }
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setScanStatus('');
        setScanProgress(0);
        setScanResult(null);
        setCurrentUrl('');
        if (pollingInterval) {
            clearInterval(pollingInterval);
            setPollingInterval(null);
        }
    };


    const handleStopClick = (e, row) => {
        e.preventDefault();
        // console.log("I am the row::", row);

        if (row.reportId) {
            // console.log("dastReducerState", dastReducerState);

            const postData = {
                "url": row.assetUrl,
                "reportId": row.reportId

            };
            // return;
            axios
                .post(`${GlobalConst.API_URL}/auth/dast/ascan/stop`, postData)
                .then(op => {
                    if (!_.isEmpty(op.data) && !_.isEmpty(op.data[0].message) && op.data[0].message === 'Scan Stopped') {
                        window.location.reload();
                    }
                    // console.log("Output", JSON.stringify(op));

                })
                .catch(e => {
                    console.log("Exception: ", e);
                })
        }


    }
    const customExpandIcon = (props) => {
        if (props.expanded) {
            return <FontAwesomeIcon icon={faChevronUp} onClick={e => props.onExpand(props.record, e)} style={{ cursor: 'pointer' }} className="me-2" />;
        } else {
            return <FontAwesomeIcon icon={faChevronDown} onClick={e => props.onExpand(props.record, e)} style={{ cursor: 'pointer' }} className="me-2" />;
        }
    };

    const handleViewVulnerabilitiesClick = (e, row) => {
        e.preventDefault();
        console.log("Row is ::", row);
        setViewVulnerabilityModal(true);
    }

    const getSplitedText = (text) => {
        let splitedText = text.split(":");

        if (splitedText.length > 0) {
            return splitedText[1];
        }
        else {
            return '-';
        }
    }

    const columns = [
        {
            title: 'Sno',
            key: 'sno',
            render: (_, __, index) => index + 1,
            width: 20,
            // width: 80,
            // align: 'center',
        },
        {
            title: "Asset Name",
            dataIndex: 'assetUrl',
            key: 'assetUrl',
            width: 350,
            render: (text, record, index, expand) => (
                <span>
                    <span>
                        {!isScanningOrInitiated(record) ?
                            customExpandIcon({
                                expanded: expandedRowKeys.includes(record.key),
                                onExpand: (record, event) => {
                                    event.stopPropagation();
                                    const expanded = !expandedRowKeys.includes(record.key);
                                    handleExpand(expanded, record);
                                    setExpandedRowKeys(
                                        expanded
                                            ? [...expandedRowKeys, record.key]
                                            : expandedRowKeys.filter(key => key !== record.key)
                                    );
                                },
                                record
                            }) :
                            <span style={{ visibility: 'hidden' }}>{customExpandIcon({ expanded: false })}</span>
                        }
                    </span>
                    <a onClick={(e) => openChildPage(e, record.reportId)} href="#" className="me-3 details-hover-animate" style={{ fontSize: 14 }}>{text}
                        {/* <small><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></small> */}
                    </a>
                </span>
            )
        },
        {
            title: "Total",
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: 95,
            render: (text, row) => {
                if (isScanningOrInitiated(row)) {
                    // console.log("Row is: ", row);
                    return {

                        children:

                            <>



                                <div className="d-flex align-items-start justify-content-center w-100">

                                    <div className="w-100 me-4">

                                        <Progress percent={(row.scanProgress) ? row.scanProgress : 0} status="active" className="mb-2 me-4" size={['100%', 20]} percentPosition={{ align: 'center', type: 'inner' }} />
                                        {!_.isEmpty(row.scanProgressReport) && <div className="d-flex align-items-center justify-content-center w-100">
                                            <p className="mb-0 d-flex align-items-center justify-content-center">{row.scanProgressReport} <span className="ms-2" style={{ width: 15, height: 15, display: 'inline-block' }}><ProcessLoader /></span></p>
                                            {/* <p className="mb-0"><a href="#" onClick={(e) => { handleViewVulnerabilitiesClick(e, row) }}>View Vulnerabilities</a></p> */}
                                            {/* <p className="mb-0">Total Vulnerabilities - <strong>0</strong></p> */}
                                        </div>}

                                        <div className="d-flex align-items-center justify-content-around mt-3 w-100 p-2" style={{ background: '#3a3f47', borderRadius: 8 }}>

                                            <div className="mb-0 text-center me-5">
                                                <h4 className="text-primary mb-0">{row.assetsCount}</h4>
                                                <p className="mb-0">Assets Identified</p>
                                            </div>

                                            <div className="mb-0 text-center">
                                                <h4 className="text-primary mb-0">{!_.isEmpty(row.scanProgressReport) && <>{getSplitedText(row.scanProgressReport)}</>}</h4>
                                                <p className="mb-0">Current Scan Type</p>
                                            </div>
                                            <div className="mb-0 text-center">
                                                <h4 className="text-primary mb-0">{row.inProgressVulnCount}</h4>
                                                <p className="mb-0">Vulnerabilities Found</p>
                                            </div>

                                        </div>

                                    </div>
                                    <div>

                                        {
                                            row.reportId &&
                                            <Tooltip title={'Stop Scan'}>
                                                <Popconfirm
                                                    title="Are you sure you want to stop this scanning?"
                                                    onConfirm={(e) => handleStopClick(e, row)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                >
                                                    <FontAwesomeIcon icon={faCircleStop} className="text-danger lower-hover-opacity mt-1" style={{ cursor: "pointer", fontSize: 20 }} />
                                                </Popconfirm>
                                            </Tooltip>
                                        }


                                    </div>


                                </div>





                                {/* <div className="d-flex align-items-end w-100">

                                    <div className="me-2 ps-2" style={{ width: '70%' }}>
                                        <div className="d-flex align-items-end justify-content-center w-100">
                                            <div className="d-flex align-items-center flex-column w-100 me-3">
                                                <Progress percent={(row.scanProgress) ? row.scanProgress : 0} status="active" className="mb-0 me-0" />
                                                {!_.isEmpty(row.scanProgressReport) && <div className="d-flex align-items-center justify-content-between w-100">
                                                    <p className="mb-0 d-flex align-items-center justify-content-start">{row.scanProgressReport} <span className="ms-2" style={{ width: 20, height: 20, display: 'inline-block' }}><ProcessLoader /></span></p>
                                                    <p className="mb-0">Total Vulnerabilities - <strong>0</strong></p>
                                                </div>}

                                            </div>
                                            {
                                                row.reportId &&
                                                <Tooltip title={'Stop Scan'}>
                                                    <Popconfirm
                                                        title="Are you sure you want to stop this scanning?"
                                                        onConfirm={(e) => handleStopClick(e, row)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                    >
                                                        <FontAwesomeIcon icon={faCircleStop} className="text-danger lower-hover-opacity" style={{ cursor: "pointer", fontSize: 20 }} />
                                                    </Popconfirm>
                                                </Tooltip>
                                            }
                                        </div>
                                    </div>

                                </div> */}
                            </>,
                        props: {
                            colSpan: 6,
                        },
                    };
                }
                return text;
            },
        },
        {
            title: "Critical",
            dataIndex: 'critical',
            key: 'critical',
            align: 'center',
            width: 105,
            render: (text, row) => (
                isScanningOrInitiated(row) ? {
                    children: null,
                    props: {
                        colSpan: 0,
                    },
                } : <Tag
                    style={{
                        color: getFontColor("CRITICAL"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("CRITICAL")}
                    className={"mx-auto text-center"}
                >
                    {text || 0}
                </Tag>
            )
        },
        {
            title: "High",
            dataIndex: 'high',
            key: 'high',
            width: 85,
            align: 'center',
            render: (text, row) => (
                isScanningOrInitiated(row) ? {
                    children: null,
                    props: {
                        colSpan: 0,
                    },
                } : <Tag
                    style={{
                        color: getFontColor("HIGH"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("HIGH")}
                    className={"mx-auto text-center"}
                >
                    {text || 0}
                </Tag>
            )
        },
        {
            title: "Medium",
            dataIndex: 'medium',
            key: 'medium',
            width: 105,
            align: 'center',
            render: (text, row) => (
                isScanningOrInitiated(row) ? {
                    children: null,
                    props: {
                        colSpan: 0,
                    },
                } : <Tag
                    style={{
                        color: getFontColor("MEDIUM"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("MEDIUM")}
                    className={"mx-auto text-center"}
                >
                    {text || 0}
                </Tag>
            )
        },
        {
            title: "Low",
            dataIndex: 'low',
            key: 'low',
            width: 95,
            align: 'center',
            render: (text, row) => (
                isScanningOrInitiated(row) ? {
                    children: null,
                    props: {
                        colSpan: 0,
                    },
                } : <Tag
                    style={{
                        color: getFontColor("LOW"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("LOW")}
                    className={"mx-auto text-center"}
                >
                    {text || 0}
                </Tag>
            )
        },
        {
            title: "Last Scan",
            dataIndex: 'scanDateTime',
            width: 150,
            key: 'scanDateTime',
            render: (text, row) => isScanningOrInitiated(row) ? {
                children: null,
                props: {
                    colSpan: 0,
                },
            } : moment.utc(row.scanDateTime).local().fromNow()
        },
        {
            title: "Action",
            key: 'action',
            fixed: "right",
            width: 160,
            render: (_, row) => <Space>
                <a
                    onClick={(e) => handleReScanClick(e, row)}
                    className={`me-2 details-hover-animate ${isScanningOrInitiated(row)
                        ? "disabled"
                        : ""
                        }`}
                >
                    <Tooltip title={"Rescan"}>
                        <RiQrScan2Line style={{ fontSize: 20 }} />
                    </Tooltip>
                </a>
                <a onClick={(e) => openChildPage(e, row.reportId)} href={`#`} className={`me-2 details-hover-animate ${isScanningOrInitiated(row)
                    ? "disabled"
                    : ""
                    }`}>
                    <Tooltip title="Project Dashboard">
                        <AiOutlineDashboard style={{ fontSize: 20 }} />
                    </Tooltip>
                </a>
            </Space>,
        },
        // {
        //     title: ''
        // }
    ]

    const resultColumns = [
        {
            title: 'Alert Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Risk',
            dataIndex: 'risk',
            key: 'risk',
            render: (val, row) => {
                switch (val.toLowerCase()) {
                    case 'low':
                        return (<Tag
                            style={{
                                color: getFontColor("LOW"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("LOW")}
                            className={"mx-auto text-center"}
                        >LOW</Tag>);
                    case 'medium':
                        return (<Tag
                            style={{
                                color: getFontColor("MEDIUM"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("MEDIUM")}
                            className={"mx-auto text-center"}
                        >MEDIUM</Tag>);
                    case 'high':
                        return (<Tag
                            style={{
                                color: getFontColor("HIGH"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("HIGH")}
                            className={"mx-auto text-center"}
                        >HIGH</Tag>);
                    case 'critical':
                        return (<Tag
                            style={{
                                color: getFontColor("CRITICAL"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("CRITICAL")}
                            className={"mx-auto text-center"}
                        >CRITICAL</Tag>);
                    default:
                        <Tag>{val}</Tag>
                }
            }
        },
        {
            title: 'Confidence',
            dataIndex: 'confidence',
            key: 'confidence',
            render: (val, row) => {
                switch (val.toLowerCase()) {
                    case 'low':
                        return (<Tag
                            style={{
                                color: getFontColor("LOW"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("LOW")}
                            className={"mx-auto text-center"}
                        >LOW</Tag>);
                    case 'medium':
                        return (<Tag
                            style={{
                                color: getFontColor("MEDIUM"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("MEDIUM")}
                            className={"mx-auto text-center"}
                        >MEDIUM</Tag>);
                    case 'high':
                        return (<Tag
                            style={{
                                color: getFontColor("HIGH"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("HIGH")}
                            className={"mx-auto text-center"}
                        >HIGH</Tag>);
                    case 'critical':
                        return (<Tag
                            style={{
                                color: getFontColor("CRITICAL"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("CRITICAL")}
                            className={"mx-auto text-center"}
                        >CRITICAL</Tag>);
                    default:
                        <Tag>{val}</Tag>
                }
            }
        },
    ];

    const data = Array.isArray(dastReducerState.dastDashTableData)
        ? dastReducerState.dastDashTableData.map((row, index) => ({
            key: index,
            ...row
        }))
        : [];

    const urlDiscoveryData = urlDiscoveries.map((data, index) => ({
        key: index,
        ...data
    }));

    const getSelectedScanUrlDiscovery = reportId => urlDiscoveryData.find((data) => data.reportId === reportId)
    const isScanningOrInitiated = (record) => record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toUpperCase().toLowerCase() === 'initiated'

    return (
        <>
            {isURLDiscoveryLoading && <Loader />}
            <Table
                columns={columns}
                className='custom-table mb-0 fixedScrollCustomTable'
                dataSource={data}
                size="small"
                expandable={{
                    onExpand: (expanded, record) => {
                        handleExpand(expanded, record);
                    },
                    expandedRowRender: (record) => {
                        const data = getSelectedScanUrlDiscovery(record.reportId)
                        return !_.isEmpty(data) ?
                            <div style={{ marginLeft: 35, marginTop: 10, marginBottom: 10, marginRight: 30 }}>
                                <UrlDiscoveries discoveries={data.discoveries} configId={data.configId} startScan={startScan} />
                            </div> : null
                    },
                    rowExpandable: row => !isScanningOrInitiated(row),
                    // expandIcon: customExpandIcon
                    expandIcon: () => null, // This hides the default expand icon
                    expandedRowKeys,
                    showExpandColumn: false
                }}
                locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                // onRow={(record) => ({
                //     onClick: () => {
                //         if (!isScanningOrInitiated(record)) {
                //             const expanded = !expandedRowKeys.includes(record.key);
                //             handleExpand(expanded, record);
                //             setExpandedRowKeys(
                //                 expanded
                //                     ? [...expandedRowKeys, record.key]
                //                     : expandedRowKeys.filter(key => key !== record.key)
                //             );
                //         }
                //     },
                // })}
                pagination={{
                    // showTotal: (total, range) => {
                    //     return (
                    //         <span className="text-white">
                    //             {range[0]}-{range[1]} of {total && total.format()}{" "}
                    //             repositories
                    //         </span>
                    //     );
                    // },
                    // current: page,
                    // onChange(current) {
                    //     dispatch(setCurrentDashboardPageNumber(current));
                    // },
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination px-0",
                    pageSizeOptions: [10, 20, 25],
                }}
            />
            {isModalVisible && <AntModal
                title={<h3 className='pb-2 border-bottom'>Scan Results</h3>}
                open={isModalVisible}
                onCancel={handleModalClose}
                footer={null}
                width={800}
                size={'large'}
                maskClosable={false}
                closeIcon={<CloseOutlined onClick={handleModalClose} />}
                zIndex={1000}
            >
                <h5 className='mt-4 mb-2'>{`Scan status for: ${currentUrl}`}</h5>
                {scanStatus === 'Scan completed' ? (
                    <div>
                        {scanResult && scanResult.alerts ? (
                            <Table
                                columns={resultColumns}
                                dataSource={scanResult.alerts.map((alert, index) => ({ ...alert, key: index }))}
                                expandable={{
                                    expandedRowRender: (record) => {
                                        const columns = [
                                            { title: 'URL', dataIndex: 'url', key: 'url' },
                                        ];
                                        const data = record.urls.map((url, index) => ({
                                            key: index,
                                            url: url,
                                        }));
                                        return <Table columns={columns} dataSource={data} pagination={false} locale={{ emptyText: <NoDataFound title={'No Data'} /> }} />;
                                    },
                                    rowExpandable: record => record.urls && record.urls.length > 0,
                                }}
                                size="small"
                                className='custom-table mb-0'
                                pagination={false}
                                locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                            />
                        ) : (
                            <p>No alerts found in the scan result.</p>
                        )}
                    </div>
                ) : (
                    <div className='mt-4' style={{ textAlign: 'center' }}>
                        <Progress
                            type="circle"
                            percent={scanProgress}
                            className='mb-2'
                            status={scanProgress === 100 ? "success" : "active"}
                        />
                        <p>{scanStatus}</p>
                    </div>
                )}
                <div style={{ textAlign: 'right', marginTop: '20px' }}>
                    <Button onClick={handleModalClose}>Close</Button>
                </div>
            </AntModal>}
            {isConfirmModalVisible && <Modal show={isConfirmModalVisible} onHide={() => setIsConfirmModalVisible(false)} backdropClassName="custom-backdrop-dast" style={{ zIndex: 1000030 }}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Close</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    The scan is still in progress. Are you sure you want to close and terminate the ongoing process?
                </Modal.Body>
                <Modal.Footer>
                    <BootstrapButton variant="secondary" onClick={() => setIsConfirmModalVisible(false)}>
                        Cancel
                    </BootstrapButton>
                    <BootstrapButton variant="primary" onClick={() => {
                        closeModal();
                        setIsConfirmModalVisible(false);
                    }}>
                        Yes, close
                    </BootstrapButton>
                </Modal.Footer>
            </Modal>}
            {viewVulnerabilityModal && <Modal show={viewVulnerabilityModal} onHide={() => setViewVulnerabilityModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Vulnerabilities</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    The scan is still in progress. Are you sure you want to close and terminate the ongoing process?
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>}
        </>
    );
}