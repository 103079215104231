import React, { forwardRef } from 'react';
import { Table, Tooltip, Tag, Input } from 'antd';
import { MdOutlineRefresh } from "react-icons/md";
import { IoIosDoneAll } from "react-icons/io";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { AiOutlineDashboard } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
import _ from 'lodash';
import moment from "moment";
import NoDataFound from '../../shared/sharedComponents/noDataFound';
import { formatScanKey, getCloudSecurityScanStatus, getFontColor, getStrokeColor } from '../../shared/helper/genHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { TbBinaryTree } from "react-icons/tb";
import { RiQrScan2Line } from "react-icons/ri";
import { TiWarning } from "react-icons/ti";
import { getAssetIcon, getTooltipName } from '../../shared/helper/getAssetImagesHelper';
import { addUpdateCloudSecurityDashboardTableData, setCloudSecurityScanTriggeredState } from '../../shared/redux/reducers/cloudSecurityReducer';
import { GlobalConst } from '../../shared/appConfig/globalConst';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import TableColumnSearch from '../../shared/sharedComponents/antdTableComponents/tableColumnSearch';

const DashboardIcon = forwardRef((props, ref) => (
    <span ref={ref}>
        <AiOutlineDashboard {...props} />
    </span>
));

const ComponentTable = ({ }) => {

    const dispatch = useDispatch();
    const cloudSecurityReducer = useSelector(state => state.cloudSecurityReducer);
    const { getColumnSearchProps } = TableColumnSearch();

    const handleReScanClick = (e, toScanRow) => {
        e.preventDefault();
        triggerRescan(toScanRow);
    }


    const triggerRescan = (toScanRow) => {
        if (!_.isEmpty(toScanRow)) {
            dispatch(setCloudSecurityScanTriggeredState(true));
            const postBody = {
                "integrationId": toScanRow.integrationId,
                "targetSystem": toScanRow.integrationType
            };
            axios
                .post(`${GlobalConst.API_URL}/auth/cloud-api/ascan`, postBody)
                .then((op) => {
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        dispatch(addUpdateCloudSecurityDashboardTableData(op.data));
                    }
                })
                .catch((e) => { });
        }
        else {
            // show a alert message to tell that invalid row selected to scan
        }
    }

    const handleAdvanceDashboardBtnClick = (e, reportId) => {
        e.preventDefault();
        window.open(`/cloud-security-advance-dashboard/${reportId}`, '_self');
    }

    const scanStatusList = (scanStatuses) => {
        if (!scanStatuses || Object.keys(scanStatuses).length === 0) {
            return null;
        }

        return (
            <ul className="list-group list-group-flush">
                {Object.entries(scanStatuses).map(([key, value]) => (
                    <li key={key} className="list-group-item bg-transparent p-2">
                        <div className='d-flex align-items-center justify-content-start'>
                            {value.status.toLowerCase() === 'completed' ? <div className='me-1'><IoCheckmarkDoneSharp className='text-success' /></div> : <div className='me-1'><IoIosCloseCircleOutline className='text-danger' /></div>}
                            <div><strong>{formatScanKey(key)}</strong></div>
                        </div>
                        <div>
                            (Last Scan: {moment.utc(value.scanTime).local().fromNow()})
                        </div>
                        {value.errorMessage && (
                            <div className="text-danger">
                                Error: {value.errorMessage}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        );
    };

    const getScanStatus = (status, statuses) => {
        if (!_.isEmpty(status)) {
            if (status.toLowerCase() === 'failed') {
                return <Tooltip title={scanStatusList(statuses)}>
                    <span><FontAwesomeIcon className="text-danger" icon={faTimesCircle} /></span>
                </Tooltip>;
            }
            else if (status.toLowerCase() === 'unknown') {
                return '';
            }
            else if (status.toLowerCase() === 'partial') {
                return <Tooltip title={scanStatusList(statuses)}>
                    <span><TiWarning className="text-warning" /></span>
                </Tooltip>;
            }
        }
        else {
            return '';
        }
    }

    const customCloudRender = (text, record, { highlightComponent }) => (
        <>
            <Tooltip title={getTooltipName(record.integrationType)}>
                <span className="text-secondary">
                    {getAssetIcon(record.integrationType)}
                </span>
            </Tooltip>
            <a
                onClick={(e) => handleAdvanceDashboardBtnClick(e, record.reportId)}
                href="#"
                className="me-2 details-hover-animate"
            >
                {highlightComponent || text}
            </a>
            {(!_.isEmpty(record.status) && (record.status.toLowerCase() === 'failed') || record.status.toLowerCase() === 'unknown' || record.status.toLowerCase() === 'partial') &&
                <span>
                    {getScanStatus(record.status, record.scanStatuses)}
                </span>
            }
        </>
    );

    const dataTableColumns = [
        {
            title: 'Sno',
            dataIndex: 'sno',
            width: 60
        },
        {
            title: 'Cloud',
            dataIndex: 'projectId',
            key: 'projectId',
            // width: 290,
            ...getColumnSearchProps('projectId', customCloudRender, 'Cloud')
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            defaultSortOrder: ["ascend", "descend"],
            sorter: (a, b) => a.total - b.total,
            render: (text, record) => {

                // if (getStatus(record) === 'scanning' || getStatus(record) === 'initiated') {
                //     return <div className="d-flex align-items-center justify-content-center"><Loader /> </div>;
                // }
                // else {
                return (<>{text}</>);
                // }
            }
        },
        {
            title: 'Critical',
            dataIndex: 'critical',
            key: 'critical',
            align: 'center',
            defaultSortOrder: ["ascend", "descend"],
            sorter: (a, b) => a.critical - b.critical,
            render: (text, record) => (
                // (getStatus(record) === 'scanning' || getStatus(record) === 'initiated')
                //     ? <div className="d-flex align-items-center justify-content-center"><Loader /> </div>
                //     : 
                <Tag
                    style={{
                        color: getFontColor("CRITICAL"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("CRITICAL")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'High',
            dataIndex: 'high',
            key: 'high',
            align: 'center',
            defaultSortOrder: ["ascend", "descend"],
            sorter: (a, b) => a.high - b.high,
            render: (text, record) => (
                // (getStatus(record) === 'scanning' || getStatus(record) === 'initiated')
                //     ? <div className="d-flex align-items-center justify-content-center"><Loader /> </div>
                //     : 
                <Tag
                    style={{
                        color: getFontColor("HIGH"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("HIGH")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'Medium',
            dataIndex: 'medium',
            key: 'medium',
            align: 'center',
            defaultSortOrder: ["ascend", "descend"],
            sorter: (a, b) => a.medium - b.medium,
            render: (text, record) => (
                // (getStatus(record) === 'scanning' || getStatus(record) === 'initiated')
                // ? <div className="d-flex align-items-center justify-content-center"><Loader /></div>
                // : 
                <Tag
                    style={{
                        color: getFontColor("MEDIUM"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("MEDIUM")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'Low',
            dataIndex: 'low',
            key: 'low',
            align: 'center',
            defaultSortOrder: ["ascend", "descend"],
            sorter: (a, b) => a.low - b.low,
            render: (text, record) => (
                // (getStatus(record) === 'scanning' || getStatus(record) === 'initiated')
                //     ? <div className="d-flex align-items-center justify-content-center"><Loader /> </div>
                //     : 
                <Tag
                    style={{
                        color: getFontColor("LOW"),
                        borderRadius: 50,
                        width: 40,
                        fontSize: 14,
                    }}
                    color={getStrokeColor("LOW")}
                    className={"mx-auto text-center"}
                >
                    {text}
                </Tag>
            ),
        },
        {
            title: 'Last Scan',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
            sortDirections: ["ascend", "descend"],
            align: 'center',
            render: (text, row) => {
                if (row.status.toLowerCase() === 'scanning' || row.status.toLowerCase() === 'initiated') {
                    return (
                        <div className='d-flex align-items-center justify-content-center'>
                            <div className='' style={{ color: '#00e0e9' }}>
                                {getCloudSecurityScanStatus(row.scanStatuses)}
                                <div className='loader-moving-line'></div>
                            </div>
                        </div>
                    );
                }
                else {
                    return (moment.utc(text).local().fromNow());
                }
            },
            ellipsis: true,
        },
        {
            title: 'Actions',
            fixed: 'right',
            width: 130,
            align: 'center',
            render: (text, record) => (
                <>
                    <Tooltip title={'Rescan'}>
                        <a
                            onClick={(e) => handleReScanClick(e, record)}
                            className={`me-3 details-hover-animate ${record.status.toLowerCase() === 'initiated' ||
                                record.status.toLowerCase() === 'scanning'
                                ? "disabled"
                                : ""
                                }`}
                        >
                            <RiQrScan2Line style={{ fontSize: 20 }} />
                        </a>
                    </Tooltip>
                    <Tooltip title="Advance Dashboard">
                        <a onClick={(e) => handleAdvanceDashboardBtnClick(e, record.reportId)} href={`#`} className="details-hover-animate me-3">
                            <DashboardIcon style={{ fontSize: 20 }} />
                        </a>
                    </Tooltip>
                    <Tooltip title="Cloud Tree">
                        <a onClick={(e) => handleAdvanceDashboardBtnClick(e, record.reportId)} href={`#`} className="details-hover-animate">
                            <TbBinaryTree style={{ fontSize: 20 }} />
                        </a>
                    </Tooltip>
                </>
            ),
        }
    ];



    const data = cloudSecurityReducer.cloudSecurityDashTableData.map((row, index) => {

        const keys = ['critical', 'high', 'medium', 'low'];
        const total = _.sum(_.map(keys, key => _.toNumber(_.get(row, key, 0))));

        return {
            key: `cloud-security-table-${index}`,
            sno: index + 1,
            total: total,
            ...row,
        };
    }

    );


    return (
        <div>
            <Table
                size='small'
                className="custom-table mb-0 fixedScrollCustomTable table-hover component-view-table"
                dataSource={!_.isEmpty(data) ? data : []}
                columns={dataTableColumns}
                locale={{
                    emptyText: <NoDataFound title={'No Data'} />
                }}
                pagination={{
                    showTotal: (total, range) => {
                        return (
                            <span className="text-white">
                                {range[0]}-{range[1]} of {total && total.format()}{" "}
                                issues
                            </span>
                        );
                    },
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    position: ["bottom", "left"],
                    className: "searchPagePagination px-0",
                    pageSizeOptions: [10, 20, 25],
                }}
            />
        </div>
    );
};
export default ComponentTable;