import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCheck, faFile, faFileCode, faFileZipper, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';
import axios from 'axios';
import {
    loaderState, setScanModalViewState, setScanInProgress, setScanGuidResponse,
    getDashboardDataState, setFirstTrigger, setScannedRecords, setCurrentDashboardPageNumber,
    setAllUserRepoList,
    setSanningFromDrag,
    setUserListPageNumber
} from '../../redux/reducers/scannerReducer';
import { Input, Modal, Collapse, Tooltip, Divider } from 'antd';
import { faRegistered } from "@fortawesome/free-regular-svg-icons";

import SelectRepository from "./selectRepository";
import SelectBranch from "./selectBranch";
import { faBitbucket, faDocker, faGithub, faGitlab } from "@fortawesome/free-brands-svg-icons";

import SelectRepositoryDataTable from "./selectRepositoryDataTable";

import { getIntegratedState, removeGitSuffix } from '../../helper/genHelper';

import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { GlobalConst } from "../../appConfig/globalConst";
import NoDataFound from "../noDataFound";
// import PollingComponent from './poling';




export default function ScanModal() {

    const dispatch = useDispatch();
    const reduxState = useSelector(state => state);
    const { Search } = Input;
    const scanModalState = useSelector((state) => state.scannerReducer.scanModalViewStatus);
    const [searchVal, setSearchVal] = useState('');

    const [showMore, setShowMore] = useState(false);

    // const [repositoryList, setRepositoryList] = useState([]);
    // const [repositoryListCopy, setRepositoryListCopy] = useState([]);
    const [selectedRepo, setSelectedRepo] = useState([]);
    const [loadingRepos, setLoadingRepos] = useState(false);

    const [tagsList, setTagsList] = useState([]);
    const [selectedTag, setSelectedTag] = useState({});
    const [loadingTags, setLoadingTags] = useState(false);
    const [unscannedRepoState, setUnscannedRepoState] = useState(true);


    const [branchList, setBranchList] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({});
    const [loadingBranches, setLoadingBranches] = useState(false);

    const [activeCollapse, setActiveCollapse] = useState();

    const [uploadedFile, setUploadedFile] = useState(null);


    const [activeTab, setActiveTab] = useState(false);
    const [integratedSystems, setIntegratedSystems] = useState({});


    // const items = [
    //     // {
    //     //     key: '1',
    //     //     label: <div>
    //     //         {/* <h5>
    //     //             {!_.isEmpty(selectedRepo) && <FontAwesomeIcon className="me-2" icon={faCheck} style={{ color: '#2dcb73' }} />}Select Repository
    //     //         </h5> */}
    //     //         {/* {!_.isEmpty(selectedRepo) && <div className="text-muted ms-4">
    //     //             <small>Selected Repository:
    //     //                 <span className="text-white ms-1">{selectedRepo.owner}<span className="text-muted">/</span>{selectedRepo.name}</span>
    //     //             </small>
    //     //         </div>
    //     //         } */}
    //     //     </div>,
    //     //     children: <SelectRepository repositoryList={repositoryList} loadingRepos={loadingRepos} selectedRepo={selectedRepo} setSelectedRepo={setSelectedRepo} />,
    //     //     collapsible: 'header'
    //     // },
    //     // {
    //     //     key: '2',
    //     //     label: <div>
    //     //         <h5>{!_.isEmpty(selectedBranch) && <FontAwesomeIcon className="me-2" icon={faCheck} style={{ color: '#2dcb73' }} />} Select Branches/Tags</h5>
    //     //         {!_.isEmpty(selectedBranch) && <div className="text-muted ms-4">
    //     //             <small>Selected Branch:
    //     //                 <span className="text-white ms-1">{selectedBranch.name}</span>
    //     //             </small>
    //     //         </div>
    //     //         }
    //     //         {
    //     //             !_.isEmpty(selectedTag) && <div className="text-muted ms-4">
    //     //                 <small>Selected Tag:
    //     //                     <span className="text-white ms-1">{selectedTag.name}</span>
    //     //                 </small>
    //     //             </div>
    //     //         }
    //     //     </div>,
    //     //     children: <SelectBranch
    //     //         selectedBranch={selectedBranch}
    //     //         selectedRepo={selectedRepo}
    //     //         branchList={branchList}
    //     //         loadingBranches={loadingBranches}
    //     //         setSelectedBranch={setSelectedBranch}
    //     //         tagsList={tagsList}
    //     //         setSelectedTag={setSelectedTag}
    //     //         selectedTag={selectedTag}

    //     //     />,
    //     //     collapsible: (_.isEmpty(selectedRepo)) ? "disabled" : 'header'
    //     // },
    // ];


    const items = [
        {
            key: '1',
            label: (
                <span className={`p-2 me-2 rounded other-links ${showMore}`} onClick={(e) => { e.preventDefault(); setShowMore(state => state ? false : true); setActiveCollapse(['1']) }}>
                    <FontAwesomeIcon icon={faGithub} style={{ fontSize: 20, color: '#8dbafe' }} /> GitHub
                </span>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    2nd menu item (disabled)
                </a>
            ),
            icon: <SmileOutlined />,
            disabled: true,
        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    3rd menu item (disabled)
                </a>
            ),
            disabled: true,
        },
        {
            key: '4',
            danger: true,
            label: 'a danger item',
        },
    ];


    const { Dragger } = Upload;
    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        className: 'custom-dragger-upload',
        customRequest: (p1, p2, p3) => {
            // console.log("I am fired here", p1, p2, p3)
        },
        onChange(info) {
            // console.log("I am the file uploaded", info);

            setSearchVal('');
            setUploadedFile(info);
            // const FormData = require('form-data');
            // const fs = require('fs');
            message.info(`${info.file.name} file uploaded successfully, scanning in progress.`);



            let presentRecord = _.find(reduxState.scannerReducer.scannedRecords, function (o) { return removeGitSuffix(o.url) === removeGitSuffix(info.file.name); });


            if (!_.isEmpty(presentRecord)) {
                // when there is a record found so we need to move the record to the first place and trigger the scan 
                // console.log("I found the record now and i will shift the record and trigger the scan now.",);

                dispatch(setCurrentDashboardPageNumber(1)); // change the page number on the dashboard.
                var clonedRecords = _.cloneDeep(reduxState.scannerReducer.scannedRecords);

                // console.log("State records:", reduxState.scannerReducer.scannedRecords);

                // get the record on top of the array...

                presentRecord = {
                    ...presentRecord,
                    rowStatus: 'INITIATED'
                }
                // console.log("The present record again:", presentRecord);
                // _.pull(clonedRecords, presentRecord); // Remove the object from the array
                clonedRecords = _.remove(clonedRecords, (o) => o.key !== presentRecord.key);
                // console.log("ShiftedArray:", clonedRecords);
                clonedRecords.unshift(presentRecord); // Add the object to the beginning of the array

                // console.log("Moved Array: ", clonedRecords);

                // reset the key of the array by modifying the keys.
                let newData = [];

                if (!_.isEmpty(clonedRecords)) {
                    clonedRecords.forEach((el, ind) => {
                        newData.push({
                            ...el,
                            key: `dataRow-${ind}`,
                            serialNumber: ind + 1
                        })
                    })
                }
                // console.log("New data Created::", newData);

                dispatch(setScannedRecords(newData));
                // let requestList = [];
                // requestList.push({
                //     location: presentRecord.url,
                //     urlType: "github"
                // });
                // dispatch(setLoadingRows(requestList));
                // triggerScan(requestList);



            }
            else {


                let newRecord = {
                    key: `dataRow-new`,
                    serialNumber: 1,
                    name: decodeURIComponent(info.file.name),
                    owner: '',
                    org: '',
                    total: 0,
                    critical: 0,
                    high: 0,
                    medium: 0,
                    low: 0,
                    na: 0,
                    branch: '',
                    type: 'github',
                    url: info.file.name,
                    assetType: 'file',
                    subAssetType: 'sbom',
                    reportId: '',
                    scanDate: '',
                    scanningRow: false,
                    ecosystem: [],
                    rowStatus: 'INITIATED',
                    source: null
                };

                let updatedScanRecords = [{ ...newRecord }, ...reduxState.scannerReducer.scannedRecords];
                let newData = [];

                if (!_.isEmpty(updatedScanRecords)) {
                    updatedScanRecords.forEach((el, ind) => {
                        newData.push({
                            ...el,
                            key: `dataRow-${ind}`,
                            serialNumber: ind + 1
                        })
                    })
                }


                dispatch(setScannedRecords(newData)); // Update the redux state array to load the first record on top.


            }


            let data = new FormData();
            // console.log("I am the file obj", info.file.originFileObj);
            data.append('file', info.file.originFileObj);

            let config = {
                method: 'post',
                // maxBodyLength: Infinity,
                url: `${GlobalConst.API_URL}/auth/foss/scan/file`,
                headers: { 'content-type': 'multipart/form-data' },
                data: data
            };
            // console.log("Configuration::", config);
            // setIsScanningFromDrag(true);
            dispatch(setSanningFromDrag(true));
            dispatch(setScanModalViewState(false));
            axios.request(config)
                .then((response) => {
                    // console.log("I am the response here::", response.data);
                    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
                        // Add the file to the scannerList here.
                        // update the uuid in the table.
                        dispatch(setScanGuidResponse(response.data));
                        // message.success(`${info.file.name} file scanning complete.`);
                    }
                    // setIsScanningFromDrag(false);
                    // console.log(JSON.stringify(response.data));
                })
                .catch((e) => {
                    console.log("Exception", e);
                    // setIsScanningFromDrag(false);
                    dispatch(setSanningFromDrag(false));
                    message.error(`${info.file.name} is not a valid file.`);
                });
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };

    useEffect(() => {
        getUserIntegrations()
        // Now the api will be fired here.
        if (_.isEmpty(reduxState.scannerReducer.allUserRepoList)) {
            getRepositories();
        }
    }, []);

    // useEffect(() => {
    //     if (!_.isEmpty(selectedRepo)) {
    //         setSelectedBranch({});
    //         setSelectedTag({});
    //         setBranchList([]);
    //         setTagsList([]);
    //     }
    // }, [selectedRepo]);

    // useEffect(() => {
    //     if (activeTab === '1') {
    //         console.log("I am the active tab here")
    //         if (unscannedRepoState) {
    //             // when this is true need to filter all the repos with the scanned repo list and show the repo which are not scanned
    //             // console.log("copy list ::", repositoryListCopy);
    //             // console.log("Scanned data::", reduxState.scannerReducer.scannedRecords);
    //             let newRepoList = [];
    //             if (!_.isEmpty(repositoryList) && !_.isEmpty(reduxState.scannerReducer.scannedRecords)) {

    //                 let isPresent = false;
    //                 repositoryList.map((repo, index) => {
    //                     isPresent = _.find(reduxState.scannerReducer.scannedRecords, (o) => o.url === repo.url);
    //                     if (_.isEmpty(isPresent)) {
    //                         newRepoList.push(repo);
    //                     }
    //                 });

    //             }

    //             setRepositoryList(newRepoList);



    //         }
    //         else {
    //             // when this is false we need to show all the repos list sent from the backend.
    //             setRepositoryList(reduxState.scannerReducer.allUserRepoList);
    //         }
    //     }
    // }, [activeTab])

    // useEffect(() => {

    //     if (unscannedRepoState) {
    //         // when this is true need to filter all the repos with the scanned repo list and show the repo which are not scanned
    //         // console.log("copy list ::", repositoryListCopy);
    //         // console.log("Scanned data::", reduxState.scannerReducer.scannedRecords);
    //         let newRepoList = [];
    //         if (!_.isEmpty(repositoryList) && !_.isEmpty(reduxState.scannerReducer.scannedRecords)) {

    //             let isPresent = false;
    //             repositoryList.map((repo, index) => {
    //                 isPresent = _.find(reduxState.scannerReducer.scannedRecords, (o) => o.url === repo.url);
    //                 if (_.isEmpty(isPresent)) {
    //                     newRepoList.push(repo);
    //                 }
    //             });

    //         }

    //         setRepositoryList(newRepoList);



    //     }
    //     else {
    //         // when this is false we need to show all the repos list sent from the backend.
    //         setRepositoryList(reduxState.scannerReducer.allUserRepoList);
    //     }

    // }, [unscannedRepoState]);

    // useEffect(() => {
    //     if (!_.isEmpty(selectedRepo) && _.includes(activeCollapse, '2')) {

    //         getBranches(selectedRepo.name, selectedRepo.owner);
    //         getTags(selectedRepo.name, selectedRepo.owner);
    //     }

    // }, [activeCollapse, selectedRepo]);

    const getRepositories = (urlType, targetOrg) => {
        setLoadingRepos(true);
        dispatch(setUserListPageNumber(0));
        let reqBody = {
            "urlType": urlType,
            "page": reduxState.scannerReducer.userListPageNumber,
            "recordsPerPage": GlobalConst.GIT_REPO_LIST_PAGE_COUNT
        }
        if(!_.isEmpty(targetOrg)){
            reqBody = {
                ...reqBody,
                "owner": targetOrg,
            }
        }
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-repo-list`, reqBody)
            .then(op => {
                setLoadingRepos(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
                    // setRepositoryList(op.data.data);
                    // setRepositoryListCopy(op.data.data);
                    dispatch(setAllUserRepoList(op.data.data));
                }
                else {
                    // show user the error message that there is no repo list.
                    // setRepositoryList([]);
                    // setRepositoryListCopy([]);
                    dispatch(setAllUserRepoList([]));
                }
            })
            .catch(e => {
                console.log("Exception: ", e.response);
                setLoadingRepos(false);
                // setRepositoryList([]);
                // setRepositoryListCopy([]);
                dispatch(setAllUserRepoList([]));
            });
    }

    const getTags = (repoName, owner) => {
        setLoadingTags(true);
        const reqBody = {
            "urlType": reduxState.authReducer.loggedInFrom,
            "owner": owner,
            "repoName": repoName,
        }
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-tag-list`, reqBody)
            .then(op => {
                // console.log("I am the output:", op);
                setLoadingTags(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {

                }
                else {
                    // show user the error message that there is no repo list.
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                setLoadingTags(false);
            });
    }

    const getBranches = (repoName, owner) => {
        setLoadingBranches(true);
        const reqBody = {
            "urlType": reduxState.authReducer.loggedInFrom,
            "repoName": repoName,
            "owner": owner
        }
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/get-branch-list`, reqBody)
            .then(op => {
                // console.log("Branches list :: ", op);
                setLoadingBranches(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data) && !_.isEmpty(op.data.data)) {
                    setBranchList(op.data.data);
                }
                else {
                    // show user the error message that there is no repo list.
                    setBranchList([]);
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                setBranchList([]);
                setLoadingBranches(false);
            });
    }

    const handleInputChange = (e) => {
        setSearchVal(e.target.value);
        setUploadedFile(null);
    }

    const handleCancelCLick = () => {
        dispatch(setScanModalViewState(false));
    }

    const handleNextClick = () => {
        // console.log("Next Clicked::");
        setActiveCollapse(['2']);
    }

    const handleCollapseChange = (e) => {
        // console.log("eee", e);
        // if (!_.isEmpty(e)) {
        setActiveCollapse(e);
        // }

    }


    const updateGuids = async (op) => {
        let clonedGuid = (!_.isEmpty(reduxState.scannerReducer.scanGuidResponse)) ? [...reduxState.scannerReducer.scanGuidResponse] : [];
        // console.log("Redux ScanGuidResponse:", reduxState.scannerReducer.scanGuidResponse);
        // console.log("op is:", op);
        op.data.forEach((el) => {
            if (!_.isEmpty(el)) {
                clonedGuid.push(el);
            }
        });

        await dispatch(setScanGuidResponse(clonedGuid));
    }


    function isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    const scanApplicationFromText = () => {
        dispatch(setScanInProgress(true));
        dispatch(setScanModalViewState(false));

        if (!_.isEmpty(selectedRepo)) {
            // console.log("I am the scan repo selected repos", selectedRepo);
            let requestList = [];
            let presentRepos = [];

            selectedRepo.forEach((el) => {
                requestList.push({
                    location: el.url,
                    urlType: activeTab  !== '6' ? "github" : 'bitbucket'
                });
                // check if the record is present in the scan list. If the record is present then 
                const presentRecord = _.find(reduxState.scannerReducer.scannedRecords, function (o) { return removeGitSuffix(o.url) === removeGitSuffix(el.url); });
                if (!_.isEmpty(presentRecord)) {
                    presentRepos.push(presentRecord);
                }
            });

            // console.log("Present Repos:", presentRepos);
            if (!_.isEmpty(presentRepos)) {
                dispatch(setCurrentDashboardPageNumber(1));
                // There are present repos so we need to shift things in scanned records
                let clonedPresentRecords = [];
                presentRepos.forEach((presentRecord) => {
                    clonedPresentRecords.push({ ...presentRecord, rowStatus: 'INITIATED' });
                });
                // console.log("Cloned Present records with status changed", clonedPresentRecords);

                var clonedRecords = _.cloneDeep(reduxState.scannerReducer.scannedRecords);

                const comparator = (record1, record2) => record1.key === record2.key;

                // Remove matching records from the 'records' array
                _.pullAllWith(clonedRecords, clonedPresentRecords, comparator);

                // now that we have removed the existing records from the array join the array with new records.
                let newMergedRecords = [...clonedPresentRecords, ...clonedRecords];

                // now change their indexes

                let newData = [];

                newMergedRecords.forEach((el, ind) => {
                    newData.push({
                        ...el,
                        key: `dataRow-${ind}`,
                        serialNumber: ind + 1
                    })
                })

                // console.log("newData::", newData);
                dispatch(setScannedRecords(newData));
                triggerScan(requestList);

            }
            else {
                // when there are no present repos in the scanned list so we will directly add them in the scanned list and trigger the scans.
                let newRecords = [];
                selectedRepo.forEach((el) => {
                    let name = '';
                    if (isValidHttpUrl(el.url)) {
                        var pathname = new URL(el.url).pathname;
                        pathname = pathname.substring(1);
                        name = pathname;
                    }
                    else {
                        name = el.url;
                    }
                    newRecords.push({
                        key: `dataRow-new`,
                        serialNumber: 1,
                        name: decodeURIComponent(name),
                        owner: '',
                        org: '',
                        total: 0,
                        critical: 0,
                        high: 0,
                        medium: 0,
                        low: 0,
                        na: 0,
                        type: activeTab  !== '6' ? "github" : 'bitbucket',
                        url: el.url,
                        branch: '',
                        assetType: 'scm',
                        subAssetType: activeTab  !== '6' ? "github" : 'bitbucket',
                        reportId: '',
                        scanDate: '',
                        scanningRow: false,
                        ecosystem: [],
                        rowStatus: 'INITIATED',
                        source: null
                    })
                });

                let updatedScanRecords = [...newRecords, ...reduxState.scannerReducer.scannedRecords];
                let newData = [];

                if (!_.isEmpty(updatedScanRecords)) {
                    updatedScanRecords.forEach((el, ind) => {
                        newData.push({
                            ...el,
                            key: `dataRow-${ind}`,
                            serialNumber: ind + 1
                        })
                    })
                }
                dispatch(setScannedRecords(newData)); // Update the redux state array to load the first record on top.
                triggerScan(requestList);
            }
            return;
        }

        if (!_.isEmpty(searchVal)) {
            // first push the new record in the list
            // 1_ Go through the records and get the current record on top of the array
            let name = '';
            if (isValidHttpUrl(searchVal)) {
                var pathname = new URL(searchVal).pathname;
                pathname = pathname.substring(1);
                name = pathname;
            }
            else {
                name = searchVal;
            }
            // Before inserting the new record check if there is a same repo with the same url link.
            let presentRecord = _.find(reduxState.scannerReducer.scannedRecords, function (o) { return removeGitSuffix(o.url) === removeGitSuffix(searchVal); });

            if (!_.isEmpty(presentRecord)) {
                // when there is a record found so we need to move the record to the first place and trigger the scan
                dispatch(setCurrentDashboardPageNumber(1)); // change the page number on the dashboard.
                var clonedRecords = _.cloneDeep(reduxState.scannerReducer.scannedRecords);
                // get the record on top of the array...

                presentRecord = {
                    ...presentRecord,
                    rowStatus: 'INITIATED'
                }
                clonedRecords = _.remove(clonedRecords, (o) => o.key !== presentRecord.key);
                clonedRecords.unshift(presentRecord); // Add the object to the beginning of the array
                // reset the key of the array by modifying the keys.
                let newData = [];

                if (!_.isEmpty(clonedRecords)) {
                    clonedRecords.forEach((el, ind) => {
                        newData.push({
                            ...el,
                            key: `dataRow-${ind}`,
                            serialNumber: ind + 1
                        })
                    })
                }

                dispatch(setScannedRecords(newData));
                let requestList = [];
                requestList.push({
                    location: presentRecord.url,
                    urlType: activeTab  !== '6' ? "github" : 'bitbucket'
                });
                triggerScan(requestList);
            }
            else {
                // When there is no record found we need to add the new record to the scan records and then trigger the scan.
                let newRecord = {
                    key: `dataRow-new`,
                    serialNumber: 1,
                    name: decodeURIComponent(name),
                    owner: '',
                    org: '',
                    total: 0,
                    critical: 0,
                    high: 0,
                    medium: 0,
                    low: 0,
                    na: 0,
                    type: 'github',
                    branch: '',
                    url: searchVal,
                    assetType: 'scm',
                    subAssetType: 'github',
                    reportId: '',
                    scanDate: '',
                    scanningRow: false,
                    ecosystem: [],
                    rowStatus: 'INITIATED',
                    source: null
                };

                let updatedScanRecords = [{ ...newRecord }, ...reduxState.scannerReducer.scannedRecords];
                let newData = [];

                if (!_.isEmpty(updatedScanRecords)) {
                    updatedScanRecords.forEach((el, ind) => {
                        newData.push({
                            ...el,
                            key: `dataRow-${ind}`,
                            serialNumber: ind + 1
                        })
                    })
                }
                dispatch(setScannedRecords(newData)); // Update the redux state array to load the first record on top.
                let requestList = [];
                requestList.push({
                    location: searchVal,
                    urlType: activeTab  !== '6' ? "github" : 'bitbucket'
                });
                triggerScan(requestList);
                return;
            }
        }
        if (!_.isEmpty(uploadedFile)) {
            let data = new FormData();
            // console.log("I am the file obj", info.file.originFileObj);
            data.append('file', uploadedFile.file.originFileObj);


            let config = {
                method: 'post',
                // maxBodyLength: Infinity,
                url: `${GlobalConst.API_URL}/auth/foss/scan/file`,
                headers: { 'content-type': 'multipart/form-data' },
                data: data
            };
            // setIsScanningFromDrag(true);
            axios.request(config)
                .then((response) => {
                    // console.log("I am the response here::", response);
                    if (!_.isEmpty(response) && !_.isEmpty(response.data)) {
                        dispatch(getDashboardDataState(true));
                        // getDashboardData();
                        message.success(`${uploadedFile.file.name} file scanning complete.`);
                    }
                    // setIsScanningFromDrag(false);
                    // console.log(JSON.stringify(response.data));
                })
                .catch((e) => {
                    console.log("Exception", e);
                    // setIsScanningFromDrag(false);
                    message.error(`${uploadedFile.file.name} is not a valid file.`);
                });
        }
    }


    const triggerScan = (requestList) => {
        // console.log("I am the request list here::", requestList);
        // return;
        let requestBody = {
            "urlType": activeTab  !== '6' ? "github" : 'bitbucket',
            "scanRequestList": requestList
        };
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/ascan?enableSbom=true`, requestBody)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    dispatch(setScanGuidResponse(op.data));
                    dispatch(setFirstTrigger(true));
                }
            })
            .catch(e => { console.log("Exception: ", e) });
    }

    const getUserIntegrations = () => {
        axios
            .get(`${GlobalConst.API_URL}/auth/integrate/user-integrations`)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setIntegratedSystems(op.data);
                }

            })
            .catch(e => { 
                console.log("Exception:", e); 
            })
    }

    const handleBitbucketClick = (e) => { 
        e.preventDefault();
        if(!_.isEmpty(integratedSystems) && !_.isEmpty(integratedSystems['bitbucket']))
            getRepositories('bitbucket',integratedSystems['bitbucket'].targetorg)
        setActiveTab('6') 
    }

    const handleGithubClick = (e) => { 
        e.preventDefault(); 
        setActiveTab('1') 
        getRepositories('github')
    }

    return (
        <Modal
            title=""
            open={scanModalState}
            footer={null}
            onCancel={handleCancelCLick}
            closeIcon={<FontAwesomeIcon className="fs-5 text-white" icon={faXmark} />}
            // style={{background: '#1f242e'}}
            width={850}
            maskClosable={false}
            className="scan-modal"
        >
            <section className="m-1">
                <div className="">
                    <div className="text-white">
                        <div className="mb-3">
                            <h5 className="mb-0 text-white" style={{ fontWeight: 600 }}>iScan <small style={{ fontSize: '65%' }} className="mb-0 ms-2 text-muted">AI Powered Scans <FontAwesomeIcon icon={faRegistered} style={{ fontSize: 10 }} /></small></h5>
                            <div className="d-flex align-items-center justify-content-start"></div>
                        </div>
                        {/* <PollingComponent /> */}
                        {/* <div className="my-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Tooltip title="Scan Github Repo">
                                    <span className={`p-2 me-2 rounded other-links ${showMore}`} onClick={(e) => { e.preventDefault(); setShowMore(state => state ? false : true); setActiveCollapse(['1']) }}>
                                        <FontAwesomeIcon icon={faGithub} style={{ fontSize: 20 }} />
                                    </span>
                                </Tooltip>
                                <p className="mt-3" style={{ color: '#adb5bd' }}>
                                    <a style={{ textDecoration: 'underline' }} onClick={(e) => { e.preventDefault(); setShowMore(state => state ? false : true); setActiveCollapse(['1']) }} href={'#'}>
                                        Auto detected {!_.isEmpty(repositoryList) && repositoryList.length} repositories
                                    </a>
                                </p>
                            </div>
                        </div> */}
                        <div className="my-3 mb-1">
                            <div className="me-3 mb-2">Select asset to scan</div>
                            <div className="d-flex align-items-center justify-content-start">
                                {/* <div className="mx-3">
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                    >
                                        <a onClick={(e) => e.preventDefault()}>
                                            <Space>
                                                <span className={`p-2 me-3 rounded other-links ${showMore}`} onClick={(e) => { e.preventDefault(); setShowMore(state => state ? false : true); setActiveCollapse(['1']) }}>
                                                    <FontAwesomeIcon icon={faGithub} className="me-2" style={{ fontSize: 20 }} /> GitHub (7 repositories)
                                                </span>
                                                <DownOutlined />
                                            </Space>
                                        </a>
                                    </Dropdown>
                                </div> */}
                                <Tooltip title="Scan Github Repo">
                                    <div style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }} className={`p-2 rounded ${activeTab === '1' ? 'active-other-link' : 'border'} other-links`} onClick={handleGithubClick}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faGithub} className="icon" style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0 text-center"  style={{ width: 70 }}>Github</p>
                                    </div>
                                </Tooltip>
                                {/* <Tooltip title="Scan Docker Image/Container">
                                    <div className={`${activeTab === '2' ? 'active-other-link' : 'border'} p-2 rounded other-links`} style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }} onClick={(e) => { e.preventDefault(); setActiveTab('2') }}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faDocker} style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0">Docker</p>
                                    </div>
                                </Tooltip> */}
                                <Tooltip title="Scan by uploading file">
                                    {/* <span className="border p-2 rounded other-links" style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }}>
                                        <FontAwesomeIcon icon={faFileCode} style={{ fontSize: 20 }} />
                                    </span> */}
                                    <div className={`${activeTab === '3' ? 'active-other-link' : 'border'} p-2 rounded other-links`} style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }} onClick={(e) => { e.preventDefault(); setActiveTab('3') }}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faFileCode} style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0 text-center"  style={{ width: 70 }}>SBOM</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Scan a binary package">
                                    {/* <span className="border p-2 rounded other-links" style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }}>
                                        <FontAwesomeIcon icon={faFileZipper} style={{ fontSize: 20 }} />
                                    </span> */}
                                    <div className={`${activeTab === '4' ? 'active-other-link' : 'border'} p-2 rounded other-links`} style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }} onClick={(e) => { e.preventDefault(); setActiveTab('4') }}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faFileZipper} style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0 text-center"  style={{ width: 70 }}>Binary</p>
                                    </div>
                                </Tooltip>
                                {/* <Tooltip title="Scan a binary package">
                                    <div className={`${activeTab === '5' ? 'active-other-link' : 'border'} p-2 rounded other-links`} style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }} onClick={(e) => { e.preventDefault(); setActiveTab('5') }}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faPlus} style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0">More</p>
                                    </div>
                                </Tooltip> */}
                                {/* <Tooltip title="Scan any other format files"> */}
                                {/* <span className="border p-2 rounded other-links" style={{ cursor: 'pointer', opacity: 0.7 }}>
                                        <FontAwesomeIcon icon={faFile} style={{ fontSize: 20 }} />
                                    </span> */}
                                {/* <div className="border p-2 rounded other-links" style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }}>
                                    <p className="mb-0"><FontAwesomeIcon icon={faFile} style={{ fontSize: 20, color: '#8dbafe' }} /></p>
                                        <p className="mb-0">JSON</p>
                                        </div>
                                </Tooltip> */}
                                <Tooltip title="Scan Bitbucket Repo">
                                    <div style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7 }} className={`p-2 rounded ${activeTab === '6' ? 'active-other-link' : 'border'} other-links`} onClick={handleBitbucketClick}
                                    >
                                        <p className="mb-0"><FontAwesomeIcon icon={faBitbucket} className="icon" style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0 text-center" style={{ width: 70 }}>Bitbucket</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Scan Gitlab Repo">
                                    <div style={{ cursor: 'pointer', marginRight: 12, opacity: 0.4 }} className={`p-2 rounded ${activeTab === '7' ? 'active-other-link' : 'border'} other-links disabled pe-none`}
                                    >
                                        <p className="mb-0"><FontAwesomeIcon icon={faGitlab} className="icon" style={{ fontSize: 20 }} /></p>
                                        <p className="mb-0 text-center" style={{ width: 70 }}>GitLab</p>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            {(activeTab === '1' || activeTab === '6')  &&
                                <div className="mt-3">
                                    {/* {showMore && */}
                                    <>
                                        {/* <Collapse
                                            className="repo-branch-collapse"
                                            expandIconPosition={'end'}
                                            items={items}
                                            accordion
                                            ghost
                                            activeKey={activeCollapse}
                                            onChange={handleCollapseChange}
                                            expandIcon={({ isActive }) => <FontAwesomeIcon icon={faCaretRight} style={{ transform: (isActive) ? `rotate(90deg)` : `rotate(0deg)` }} />}
                                        /> */}
                                        {/* <div className="d-flex align-items-center justify-content-between"> */}
                                        {/* <p className="mt-3" style={{ color: '#adb5bd' }}> */}
                                        {/* <a style={{ textDecoration: 'underline' }} onClick={(e) => { e.preventDefault(); setShowMore(state => state ? false : true); setActiveCollapse(['1']) }} href={'#'}> */}
                                        {/* Auto detected {!_.isEmpty(repositoryList) && repositoryList.length} repositories */}
                                        {/* Select from auto-detected {!_.isEmpty(repositoryList) && repositoryList.length} repositories to scan. */}
                                        {/* </a> */}
                                        {/* </p> */}
                                        {/* </div> */}
                                        {/* <SelectRepositoryDataTable
                                            dataSet={repositoryList}
                                            loadingRepos={loadingRepos}
                                            selectedRepo={selectedRepo}
                                            setSelectedRepo={setSelectedRepo}
                                            unscannedRepoState={unscannedRepoState}
                                            setUnscannedRepoState={setUnscannedRepoState}
                                            repositoryListCopy={repositoryListCopy}
                                        /> */}
                                        {getIntegratedState(
                                        integratedSystems,
                                        activeTab !== '6' ? 'github' : 'bitbucket'
                                      ) ? <SelectRepository
                                            // repositoryList={repositoryList}
                                            loadingRepos={loadingRepos}
                                            selectedRepo={selectedRepo}
                                            setSelectedRepo={setSelectedRepo}
                                            unscannedRepoState={unscannedRepoState}
                                            setUnscannedRepoState={setUnscannedRepoState}
                                            // repositoryListCopy={repositoryListCopy}
                                            activeTab={activeTab}
                                            urlType={activeTab !== '6' ? 'github' : 'bitbucket'}
                                            integratedSystems={integratedSystems}
                                        /> : <NoDataFound
                                          title={`We could not found integrations for the scm`}
                                          desc={
                                            <a
                                              href={`/integrations-list/${activeTab !== '6' ? 'github' : 'bitbucket'}`}
                                              target="_blank"
                                            >
                                              Please click here to configure
                                            </a>
                                          }
                                          customInlineStyle={{
                                            image: {
                                              width: 54,
                                            },
                                            title: {
                                              fontSize: 14,
                                            },
                                            description: {
                                              fontSize: 12,
                                            },
                                          }}
                                        />}
                                        
                                        {/* {(!_.isEmpty(selectedRepo)) && <button disabled={(_.isEmpty(selectedRepo)) ? true : false} className={`sotcox-btn`} type="submit" onClick={scanApplication}>Scan<span></span></button>} */}
                                    </>
                                    {/* } */}
                                </div>
                            }
                            {activeTab === '2' && <div><h2 style={{ color: '#adb5bd' }}>Docker Tab</h2></div>}
                            {activeTab === '3' && <div>
                                {/* <h2 style={{ color: '#adb5bd' }}>SBOM Tab</h2> */}
                                <div className="my-3">
                                    <Dragger {...props} className="p-0 uploader-custom">
                                        {/* <p className="ant-upload-drag-icon mb-1">
                                                <InboxOutlined style={{ fontSize: 18 }} />
                                            </p> */}
                                        <p className="ant-upload-text mb-0  " style={{ color: '#adb5bd', fontSize: 14 }}>Upload a SBOM file</p>
                                        {/* <p className="ant-upload-hint" style={{ color: '#adb5bd' }}>
                                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                        banned files.
                                    </p> */}
                                    </Dragger>
                                </div>
                            </div>}
                            {activeTab === '4' && <div><h2 style={{ color: '#adb5bd' }}>Binary Tab</h2></div>}
                            {activeTab === '5' && <div><h2 style={{ color: '#adb5bd' }}>More Integration</h2></div>}
                            {/* <hr style={{ borderTop: '4px solid' }} /> */}
                            {/* <Divider className="divider-custom" /> */}
                            <div className="sotcox-osvs-form sotcox-input-field">
                                <form action="#" onSubmit={scanApplicationFromText}>
                                    {/* <Search
                                    placeholder="enter a artifact URL to scan for e.g. https://github.com/owner/repo"
                                    allowClear
                                    enterButton="Scan"
                                    size="large"
                                    value={searchVal}
                                    onChange={handleInputChange}
                                    onSearch={scanApplicationFromText}
                                    className="iscan-search"
                                /> */}
                                {activeTab && (
                                    <>
                                        <Divider className="divider-custom my-1" orientation="center"><small>or</small></Divider>
                                    <div className="">
                                        <div>
                                            <Input
                                                placeholder="enter an artifact URL to scan for e.g. https://github.com/owner/repo"
                                                allowClear
                                                // enterButton="Scan"
                                                size="large"
                                                value={searchVal}
                                                onChange={handleInputChange}
                                                // onSearch={scanApplicationFromText}
                                                className="iscan-search"
                                            />
                                        </div>
                                        {/* <div>
                                        <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                                            <Divider className="divider-custom my-1" orientation="center"><small>or</small></Divider> */}
                                        {/* <p className="text-center mb-0" style={{ color: '#adb5bd', fontSize: 18 }}>or</p> */}
                                        {/* </div>
                                    </div> */}
                                        {/* <div>
                                        <Dragger {...props} className="p-0 uploader-custom"> */}
                                        {/* <p className="ant-upload-drag-icon mb-1">
                                                <InboxOutlined style={{ fontSize: 18 }} />
                                            </p> */}
                                        {/* <p className="ant-upload-text mb-0  " style={{ color: '#adb5bd', fontSize: 14 }}>Drag a binary artifact or upload a SBOM file</p> */}
                                        {/* <p className="ant-upload-hint" style={{ color: '#adb5bd' }}>
                                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                        banned files.
                                    </p> */}
                                        {/* </Dragger>
                                    </div> */}
                                    </div>
                                    </>
                                )}
                                    {/* <div className="row">
                                    <div className="col-sm-7 pe-0">
                                    </div>
                                    <div className="col-sm-1">
                                    </div>
                                    <div className="col-sm-4 ps-0">
                                    </div>
                                </div> */}
                                    <div className="text-center ">
                                        <button className={`sotcox-btn mt-3`} type="submit">Scan {!_.isEmpty(selectedRepo) && `${selectedRepo.length} repo${(selectedRepo.length > 1) ? 's' : ''}`}<span></span></button>
                                    </div>
                                    {/* <div className="d-flex align-items-center justify-content-between flex-row">
                                    <Input
                                        placeholder="Enter Git Repo URL or direct path to raw POM URL"
                                        style={{ border: '1px solid #f3f3f3' }}
                                        value={searchVal}
                                        onChange={handleInputChange}
                                        className="mb-2"
                                    />
                                    <button disabled={(_.isEmpty(selectedRepo) && _.isEmpty(selectedBranch)) ? true : false} className={`sotcox-btn`} type="submit" onClick={scanApplication}>Scan<span></span></button>
                                    </div> */}
                                    {/* <div className="d-flex align-items-center justify-content-end flex-row"> */}
                                    {/* <button disabled={(_.isEmpty(selectedRepo) && _.isEmpty(selectedBranch)) ? true : false} className={`sotcox-btn`} type="submit" onClick={scanApplication}>Scan<span></span></button> */}
                                    {/* {(_.isEmpty(selectedRepo) || _.isEmpty(selectedBranch)) && <button disabled={(_.isEmpty(selectedRepo)) ? true : false} className={`sotcox-btn`} type="button" onClick={handleNextClick}>Next<span></span></button>} */}
                                    {/* {(!_.isEmpty(selectedRepo) && !_.isEmpty(selectedBranch)) && <button disabled={(_.isEmpty(selectedRepo) && _.isEmpty(selectedBranch)) ? true : false} className={`sotcox-btn`} type="submit" onClick={scanApplication}>Scan<span></span></button>} */}
                                    {/* </div> */}
                                </form>
                            </div>


                            {/* <Divider className="divider-custom" orientation="left">OR</Divider> */}


                            {/* <div className="text-center my-3"><small style={{color: '#adb5bd'}}>OR</small></div> */}



                        </div>
                        {/* <div className="my-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Tooltip title="Scan Github Repo">
                                    <span style={{ border: '1px solid #8dbafe' }} className={`p-2 me-2 rounded other-links ${showMore}`} onClick={(e) => { e.preventDefault(); setShowMore(state => state ? false : true); setActiveCollapse(['1']) }}>
                                        <FontAwesomeIcon icon={faGithub} style={{ fontSize: 20, color: '#8dbafe' }} />
                                    </span>
                                </Tooltip>
                                <Tooltip title="Scan Docker Image/Container">
                                    <span className="border p-2 me-2 rounded other-links">
                                        <FontAwesomeIcon icon={faDocker} style={{ fontSize: 20 }} />
                                    </span>
                                </Tooltip>
                                <Tooltip title="Scan by uploading file">
                                    <span className="border p-2 me-2 rounded other-links">
                                        <FontAwesomeIcon icon={faFileCode} style={{ fontSize: 20 }} />
                                    </span>
                                </Tooltip>
                                <Tooltip title="Scan a binary package">
                                    <span className="border p-2 me-2 rounded other-links">
                                        <FontAwesomeIcon icon={faFileZipper} style={{ fontSize: 20 }} />
                                    </span>
                                </Tooltip>
                                <Tooltip title="Scan any other format files">
                                    <span className="border p-2 rounded other-links">
                                        <FontAwesomeIcon icon={faFile} style={{ fontSize: 20 }} />
                                    </span>
                                </Tooltip>

                            </div>
                        </div> */}

                    </div>
                </div>

            </section>
        </Modal>
    );
}