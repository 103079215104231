// tableExportHelper.js
export const exportToCSV = (
    data,
    columns = [],
    subTableConfig = null, // optional subtable config
    filename = 'export.csv'
  ) => {
    // Prepare headers
    const headers = [...columns];
    if (subTableConfig) {
      subTableConfig.columns.forEach(column => {
        if (!headers.includes(column)) {
          headers.push(column);
        }
      });
    }
  
    // Prepare rows
    const rows = [];
    data.forEach(mainItem => {
      // Add main row
      const mainRow = {};
      headers.forEach(header => {
        mainRow[header] = mainItem[header] || '';
      });
      rows.push(mainRow);
  
      // Add subtable rows if config exists and item has subtable data
      if (subTableConfig && mainItem[subTableConfig.key]) {
        const subtableData = mainItem[subTableConfig.key];
        if (Array.isArray(subtableData) && subtableData.length > 0) {
          subtableData.forEach(subItem => {
            const subRow = {};
            // Empty values for main columns
            columns.forEach(column => {
              subRow[column] = '';
            });
            // Add subtable values
            subTableConfig.columns.forEach(column => {
              subRow[column] = subItem[column] || '';
            });
            rows.push(subRow);
          });
        }
      }
    });
  
    // Convert to CSV
    const csvContent = [
      headers.join(','),
      ...rows.map(row => 
        headers.map(header => {
          const value = row[header] || '';
          return typeof value === 'string' && (value.includes(',') || value.includes('"'))
            ? `"${value.replace(/"/g, '""')}"`
            : value;
        }).join(',')
      )
    ].join('\n');
  
    // Download file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };