import axios from "axios";
import { useEffect, useState } from "react";
import _ from 'lodash';
import { Table, Tag, Space } from "antd";
import { getFontColor, getStrokeColor } from '../../shared/helper/genHelper';
import Loader from "../../shared/sharedComponents/loader";
import FileDirectory from "./fileDirectory";
import NoDataFound from "../../shared/sharedComponents/noDataFound";
import { GlobalConst } from "../../shared/appConfig/globalConst";
import { LuHardDriveDownload } from "react-icons/lu";
import { exportToCSV } from "../../shared/helper/exportCSV";

const expandedRowRender = (props, setShowFileExplorerModal, setSelectedData) => {

    let subData = [];
    const columns = [
        {
            title: 'File Name',
            key: 'fileName',
            dataIndex: 'fileName',
            width: '30%',
        },
        {
            title: 'Line Number',
            dataIndex: 'lineNumber',
            key: 'lineNumber',
            width: '12%',
        },
        {
            title: 'Action',
            key: 'operation',
            width: '30%',
            render: (col, row) => {
                return (
                    <Space size="middle">
                        <a href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                setShowFileExplorerModal(true);
                                // console.log("I am the row", row);
                                setSelectedData({ fileId: row.fileId, lineNumber: row.lineNumber });
                            }}
                        >View Detail</a>
                    </Space>
                );
            },
        },
    ];

    if (!_.isEmpty(props) && !_.isEmpty(props.occurances)) {

        let newData = [];
        props.occurances.forEach((row, i) => {
            newData.push({
                key: i.toString(),
                lineNumber: row.lineNumber,
                fileName: row.fileName,
                fileId: row.fileId
            })
        })
        subData = newData;
    }
    else {
        subData = [];
    }
    return <Table columns={columns} dataSource={subData} pagination={false} locale={{ emptyText: <NoDataFound title={'No Data'} /> }} />;
};

export default function SastVulnerabilityTabView({ reportDetails, userPrefrences }) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showFileExplorerModal, setShowFileExplorerModal] = useState(false);
    const [selectedData, setSelectedData] = useState({});

    useEffect(() => {
        if (!_.isEmpty(reportDetails) && !_.isEmpty(reportDetails.reportId)) {
            getVulnerabilityViewData();
        }
    }, [reportDetails]);

    const getVulnerabilityViewData = () => {

        const postBody = {
            "reportId": reportDetails.reportId
        };
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/sast/asset/report/vulnerability-view`, postBody)
            .then(op => {
                setLoading(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    updateTableData(op.data);
                }
                else {
                    setData([]);
                }
            })
            .catch(e => { setLoading(false); })
    }

    const columns = [
        {
            title: <><span className='me-3'>Vulnerability</span></>,
            dataIndex: 'vulnerability',
            key: 'vulnerability',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            align: 'center'
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
            align: 'center',
            render: (val, row) => {

                switch (val.toLowerCase()) {
                    case 'critical':
                        return (<Tag
                            style={{
                                color: getFontColor("CRITICAL"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("CRITICAL")}
                            className={"mx-auto text-center"}
                        >CRITICAL</Tag>);
                    case 'high':
                        return (<Tag
                            style={{
                                color: getFontColor("HIGH"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("HIGH")}
                            className={"mx-auto text-center"}
                        >
                            HIGH
                        </Tag>);
                    case 'medium':
                        return (<Tag
                            style={{
                                color: getFontColor("MEDIUM"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("MEDIUM")}
                            className={"mx-auto text-center"}
                        >
                            MEDIUM
                        </Tag>);
                    case 'low':
                        return (<Tag
                            style={{
                                color: getFontColor("LOW"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("LOW")}
                            className={"mx-auto text-center"}
                        >
                            LOW
                        </Tag>);
                    default:
                        return (<Tag
                            style={{
                                color: getFontColor("NA"),
                                fontWeight: 600,
                                fontSize: 12,
                            }}
                            color={getStrokeColor("NA")}
                            className={"mx-auto text-center"}
                        >
                            NA
                        </Tag>);
                }
            }
        }
    ];

    const updateTableData = (vulRecords) => {
        let newData = [];

        if (!_.isEmpty(vulRecords.vulnerabilityDetails)) {
            _.forEach(vulRecords.vulnerabilityDetails, (el, key) => {
                newData.push({
                    key: key.toString(),
                    vulnerability: el.title,
                    count: el.count,
                    severity: el.severity,
                    occurances: el.occurrences
                })
            });
            setData(newData);
        }
        else {
            setData([]);
        }
    }

  const handleReportDownloadClick = () => {
    exportToCSV(
        data,
        ['vulnerability', 'count', 'severity'],
        {
          key: 'occurances',
          columns: ['fileName', 'lineNumber']
        },
        'SAST-vulnerability-report.csv'
      );
  }


    if (loading) {
        return (<Loader />)
    }
    else {
        return (
            <section className="my-2">
                <span style={{ position: 'absolute', top: 0, right: 0 }}>
                    <button className="btn btn-primary btn-sm d-flex align-items-center justify-content-center" onClick={() => handleReportDownloadClick()}><LuHardDriveDownload className="me-2" />Download</button>
                </span>
                <div>
                    <Table
                        className='custom-table'
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => expandedRowRender(record, setShowFileExplorerModal, setSelectedData)
                        }}
                        dataSource={data}
                        size="small"
                        locale={{ emptyText: <NoDataFound title={'No Data'} /> }}
                    />
                </div>
                <FileDirectory setShowFileExplorerModal={setShowFileExplorerModal} showFileExplorerModal={showFileExplorerModal} reportDetails={reportDetails} selectedData={selectedData} userPrefrences={userPrefrences} />
            </section>
        )
    }
}