import { useEffect, useState } from "react";
import _ from 'lodash';
import axios from "axios";
import { TbTextScan2 } from "react-icons/tb";
import { GlobalConst } from "../../../shared/appConfig/globalConst";
import NoDataFound from "../../../shared/sharedComponents/noDataFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Tag, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { addUpdateCloudSecurityDashboardTableData, setIScanModalState } from "../../../shared/redux/reducers/cloudSecurityReducer";
import gcpIcon from '../../../assets/img/scopy-tech-icons/gcp.svg';
import awsIcon from '../../../assets/img/scopy-tech-icons/aws.svg';
import azureIcon from '../../../assets/img/scopy-tech-icons/azure.svg';
import oracleIcon from '../../../assets/img/techIcons/oracle.png';
import { useHistory } from "react-router-dom";

export default function KubernetesTab({ integration, loading, setLoading }) {

    const dispatch = useDispatch();
    const [kubernetesDetails, setKubernetesDetails] = useState({});
    const history = useHistory()

    useEffect(() => {
        if (!_.isEmpty(integration) && !_.isEmpty(integration.integrationId)) {
            getIntegrationDetails(integration.integrationId);
        }
    }, []);

    const getIntegrationDetails = (integrationId) => {

        setKubernetesDetails({});
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/k8s/cluster/list `)
            .then(op => {

                if (!_.isEmpty(op.data) && !_.isEmpty(op.data.clusters)) {
                    setKubernetesDetails(op.data.clusters);
                }
                else {
                    setKubernetesDetails({});
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                setKubernetesDetails({});
            })
            .finally(o => {
                setLoading(false);
            })
    }

    const handleScanClick = (e, projectId) => {
        setLoading(true);

        const postData = {
            "integrationId": integration.integrationId,
            "targetSystem": integration.targetSystem
        };

        axios
            .post(`${GlobalConst.API_URL}/auth/cloud-api/k8s/ascan`, postData)
            .then(op => {
                // console.log("Output ", op);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    window.location.reload();
                }
            })
            .catch(e => {
                // console.log("Exception: ", e);
            })
            .finally(o => {
                setLoading(false);
            })
    }

    const handleConfigureClick = (integrationId) => {
        dispatch(setIScanModalState(false))
        history.push(`/integrations-list/k8s-config/${integrationId}`)
    }

    if (_.isEmpty(kubernetesDetails)) {
        if (loading) {
            return ('Loading Kubernetes Project Details .... ');
        }
        else {
            return (<NoDataFound title={"No Data"} />);
        }
    }
    else {
        return (
            <>
                <h6 className="mb-3">List of all configured Kubernetes Projects</h6>
                <div className="card custom-card">
                    <div className="card-body">
                        <table className="table table-hover table-sm mb-0">
                            <thead>
                                <tr>
                                    <th className="bg-transparent">Sno</th>
                                    <th className="bg-transparent">Cluster Name</th>
                                    <th className="bg-transparent">Project Id</th>
                                    <th className="bg-transparent">Provider</th>
                                    <th className="bg-transparent">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !_.isEmpty(kubernetesDetails) && kubernetesDetails.map((value, index) =>
                                        <tr key={`cloud-sec-${index}`}>
                                            <td className="bg-transparent">{index + 1}</td>
                                            <td className="bg-transparent">{value.clusterName}</td>
                                            <td className="bg-transparent">{value.projectId}</td>
                                            <td className="bg-transparent">{
                                            value.provider === 'gcp' ? <Tooltip title="Google">
                                                <span>
                                                    <img src={gcpIcon} style={{ width: 20, marginRight: 8 }} alt="GCP" />
                                                    GCP
                                                </span>
                                            </Tooltip> : value.provider === 'aws' ? <Tooltip title="Google">
                                                <span>
                                                    <img src={awsIcon} style={{ width: 20, marginRight: 8 }} alt="AWS" />
                                                    AWS
                                                </span>
                                            </Tooltip> : value.provider === 'oracle' ? <Tooltip title="Oracle">
                                                <span>
                                                    <img src={oracleIcon} style={{ width: 20, marginRight: 8 }} alt="Oracle" />
                                                    Oracle
                                                </span>
                                            </Tooltip> : <Tooltip title="Azure">
                                                <span>
                                                    <img src={azureIcon} style={{ width: 20, marginRight: 8 }} alt="Azure" />
                                                    Azure
                                                </span>
                                            </Tooltip>}
                                            </td>
                                            <td className="bg-transparent">{!value.isConnected ? <button className="btn btn-primary btn-sm" onClick={(e) => handleConfigureClick(value.integrationId)}><TbTextScan2 className={'me-1'} />Configure</button> : <button className="btn btn-primary btn-sm" onClick={(e) => handleScanClick(e, value.projectId)}><TbTextScan2 className={'me-1'} />Scan</button>}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}