import { configureStore } from "@reduxjs/toolkit";
import genReducer from './reducers/genReducer';
import authReducer from "./reducers/authReducer";
import scannerReducer from "./reducers/scannerReducer";
import integrationReducer from "./reducers/integrationReducer";
import sastReducer from './reducers/sastReducer';
import dastReducer from './reducers/dastReducer';
import threatVisionReducer from "./reducers/threatVisionReducer";
import cloudSecurityReducer from "./reducers/cloudSecurityReducer";
import containerSecurityReducer from "./reducers/containerSecurityReducer";
import productsReducer from "./reducers/productsReducer";
import kubernetesSecurityReducer from "./reducers/kubernetesSecurityReducer";
// import pollMiddleware from "./middleware/pollMiddleware";

export default configureStore({
    reducer: {
        genReducer: genReducer,
        authReducer: authReducer,
        scannerReducer: scannerReducer,
        integrationReducer: integrationReducer,
        sastReducer: sastReducer,
        dastReducer: dastReducer,
        threatVisionReducer: threatVisionReducer,
        cloudSecurityReducer: cloudSecurityReducer,
        contSecurityReducer: containerSecurityReducer,
        productsReducer: productsReducer,
        kubernetesSecurityReducer: kubernetesSecurityReducer
    },
    // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(pollMiddleware)
})