import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Modal, Tooltip, Button } from 'antd';
import { faRegistered } from "@fortawesome/free-regular-svg-icons";

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { faGithub } from "@fortawesome/free-brands-svg-icons";

import { setIScanModalState, setDastScanTriggeredState, addUpdateDastDashboardTableData } from "../../shared/redux/reducers/dastReducer";
import azureScm from '../../assets/img/scopy-tech-icons/azure-scm.svg';
import { handleDastFormSubmit } from "../../shared/helper/apiHelper";
import { OAuth2OptionsHelper } from "../../shared/sharedComponents/dastSharedComponents/oauth2OptionsHelper";

import { HeadersOptionsHelper } from "../../shared/sharedComponents/dastSharedComponents/headersOptionsHelper";

export default function IScanModal({ }) {

    const dispatch = useDispatch();
    const iscanReducer = useSelector(state => state.dastReducer);
    const formikRef = useRef(null);

    const ssPlaceholderUrl = 'https://example-secured.com/';
    const pwsPlaceholderUrl = 'https://example.com/';


    const [activeTab, setActiveTab] = useState('1');

    const [authType, setAuthType] = useState('');
    const [headers, setHeaders] = useState([{ name: '', value: '' }]);
    const closeIScanModal = () => {
        dispatch(setIScanModalState(false))
    }

    const handleFormSubmit = (values, setSubmitting) => {
        setSubmitting(true);
        dispatch(setDastScanTriggeredState(true));
        let scanAuthType = activeTab === '1' ? 'NO-AUTH' : authType;
        let scanType = activeTab === '1' || activeTab === '2' ? 'website' : "api";
        values.scanAuthType = scanAuthType;
        values.scanType = scanType;
        let response = handleDastFormSubmit(values, 'scan');
        console.log("iscan response : ", response);
        if (response) {
            dispatch(addUpdateDastDashboardTableData(response));
            dispatch(setIScanModalState(false));
        }
    }

    const validationSchema = Yup.object().shape({
        url: Yup.string().required('Website URL is required').url('Please enter a valid URL').test(
            'is-http-or-https',
            'Please enter a valid HTTPS URL or HTTP URL',
            value => value && (value.startsWith('https://') || value.startsWith('http://'))
        )
    });

    return (

        <Modal
            title=""
            open={iscanReducer.iScanModalState}
            footer={null}
            onCancel={closeIScanModal}
            closeIcon={<FontAwesomeIcon className="fs-5 text-white" icon={faXmark} />}
            width={850}
            maskClosable={false}
            className="scan-modal"
        >
            <section className="m-1">
                <div className="">
                    <div className="text-white">
                        <div className="mb-3">
                            <h5 className="mb-0 text-white" style={{ fontWeight: 600 }}>iScan DAST <small style={{ fontSize: '65%' }} className="mb-0 ms-2 text-muted">AI Powered Scans for DAST <FontAwesomeIcon icon={faRegistered} style={{ fontSize: 10 }} /></small></h5>
                            <div className="d-flex align-items-center justify-content-start"></div>
                        </div>
                        <div className="my-3 mb-1">
                            <div className="me-3 mb-2">Select asset to scan</div>
                            <div className="d-flex align-items-center justify-content-start mb-3">
                                <Tooltip title="Scan Public Website">
                                    <div style={{ cursor: 'pointer', marginRight: 12, opacity: 0.7, minWidth: 95 }} className={`p-2 rounded ${activeTab === '1' ? 'active-other-link' : 'border'} other-links`} onClick={(e) => { e.preventDefault(); setActiveTab('1'); setAuthType('NA') }}>
                                        <p className="mb-0"><FontAwesomeIcon icon={faGithub} style={{ fontSize: 20, color: '#adb5bd' }} /></p>
                                        <p className="mb-0">Public Website</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Scan Secure Website">
                                    <div className={`${activeTab === '2' ? 'active-other-link' : 'border'} p-2 rounded other-links`} style={{ cursor: 'pointer', opacity: 0.7, minWidth: 95 }} onClick={(e) => { e.preventDefault(); setActiveTab('2'); setAuthType('NA') }}>
                                        <p className="mb-0"><img src={azureScm} alt="azure-scm" style={{ width: 20 }} /></p>
                                        <p className="mb-0">Secured Site</p>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Scan API">
                                    <div className={`${activeTab === '3' ? 'active-other-link' : 'border'} p-2 rounded other-links`} style={{ marginLeft: 12, cursor: 'pointer', opacity: 0.7, minWidth: 95 }} onClick={(e) => { e.preventDefault(); setActiveTab('3'); setAuthType('NA') }}>
                                        <p className="mb-0"><img src={azureScm} alt="azure-scm" style={{ width: 20 }} /></p>
                                        <p className="mb-0">Scan API</p>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            <div className="sotcox-input-field">
                                <Formik
                                    initialValues={{
                                        url: '',
                                        authUrl: '',
                                        realmName: '',
                                        clientId: '',
                                        clientSecret: '',
                                        userId: '',
                                        password: '',
                                        schemaFile: null
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        handleFormSubmit(values, setSubmitting);
                                    }}
                                    innerRef={formikRef}
                                >
                                    {({ isSubmitting, setFieldValue, setTouched, values }) => (
                                        <Form>
                                            <div className={`mb-3 field is-horizontal`}>
                                                <div className="field-body">
                                                    <div className="field">
                                                        <div className="control is-expanded">
                                                            <div className="row mb-0">
                                                                <div className={`mb-3 ${(activeTab == 2) ? 'col-sm-8' : 'col-sm-12' }`}>
                                                                    <div className="input-group me-3">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon={faLink} /></span>
                                                                        <Field
                                                                            name="url"
                                                                            type="text"
                                                                            className="input input-rounded form-control"
                                                                            aria-describedby="Website URL"
                                                                            placeholder={activeTab === '1' || activeTab === '2' ?
                                                                                `Enter a website URL to trigger DAST scan for e.g. ${activeTab === '1' ? pwsPlaceholderUrl : ssPlaceholderUrl}` :
                                                                                'Enter API Server URL to trigger DAST scan for e.g. https://api.example.com'}
                                                                            style={{ padding: '10px 20px' }}
                                                                            onPaste={(e) => {
                                                                                e.preventDefault();
                                                                                const pastedText = e.clipboardData.getData('text');
                                                                                let inputValue = pastedText.trim();
                                                                                if (inputValue && !inputValue.startsWith('http://') && !inputValue.startsWith('https://')) {
                                                                                    inputValue = `https://${inputValue}`;
                                                                                }
                                                                                setFieldValue('url', inputValue);
                                                                            }} />
                                                                        
                                                                    </div>
                                                                    <ErrorMessage name="url">{msg => <div className="form-text text-danger mt-1"><small><FontAwesomeIcon className="me-1" icon={faTriangleExclamation} />{msg}</small></div>}</ErrorMessage>
                                                    
                                                                </div>
                                                                { /*<div className="col-sm-4">
                                                                    <div className="input-group">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon={faCodeBranch} /></span>
                                                                        <Field
                                                                            name="branch"
                                                                            type="text"
                                                                            className="input input-rounded form-control"
                                                                            aria-describedby="Branch"
                                                                            placeholder={`Enter Branch (optional)`}
                                                                            style={{ padding: '10px 20px' }}
                                                                        />
                                                                    </div>

                                                                </div>*/}
                                                                {activeTab == 2 && (
                                                                    <div className="col-sm-4">
                                                                        <div className="input-group mb-3">
                                                                            <Field
                                                                                as="select"
                                                                                name="authType"
                                                                                className="form-select"
                                                                                onChange={e => setAuthType(e.target.value)}
                                                                                style={{ padding: '10px 20px' }}
                                                                            >
                                                                                <option value="NA">Select Authentication Type</option>
                                                                                <option value="OAUTH2">OAUTH2</option>
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {activeTab == 3 && (
                                                                <div className="d-flex align-items-center justify-content-start w-100 gap-3">
                                                                    <div>Upload API Schema:</div>
                                                                    <div>
                                                                        <input
                                                                            id="schemaFile"
                                                                            name="schemaFile"
                                                                            type="file"
                                                                            accept=".json"
                                                                            className="input input-rounded orm-control"
                                                                            onChange={(event) => {
                                                                                setFieldValue("schemaFile", event.currentTarget.files[0]);
                                                                            }}
                                                                            style={{ padding: '10px 20px' }}
                                                                        />
                                                                    </div>
                                                                    <div>

                                                                            <div className="input-group w-100">
                                                                                <Field
                                                                                    as="select"
                                                                                    name="authType"
                                                                                    className="form-select"
                                                                                    onChange={e => setAuthType(e.target.value)}
                                                                                    style={{ padding: '10px 20px' }}
                                                                                >
                                                                                    <option value="NA">Select Authentication Type</option>
                                                                                    <option value="OAUTH2">OAuth2</option>
                                                                                    <option value="HEADERS">Headers</option>
                                                                                    <option value="APIKEY">API Key</option>
                                                                                </Field>
                                                                            </div>
                                                                        {/* </div> */}

                                                                    </div>
                                                                </div>
                                                            )}
                                                            {/* <div className="row">
                                                                <div className="col-sm-8">
                                                                    <div className="input-group">
                                                                        <div>Upload API Schema:</div>
                                                                        <input
                                                                            id="schemaFile"
                                                                            name="schemaFile"
                                                                            type="file"
                                                                            accept=".json"
                                                                            className="input input-rounded orm-control"
                                                                            onChange={(event) => {
                                                                                setFieldValue("schemaFile", event.currentTarget.files[0]);
                                                                            }}
                                                                            style={{ padding: '10px 20px' }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                                {activeTab == 3 && (
                                                                    <div className="col-sm-4">
                                                                        <div className="input-group">
                                                                            <Field
                                                                                as="select"
                                                                                name="authType"
                                                                                className="input input-rounded form-control"
                                                                                onChange={e => setAuthType(e.target.value)}
                                                                                style={{ padding: '10px 20px' }}
                                                                            >
                                                                                <option value="NA">Select Authentication Type</option>
                                                                                <option value="OAUTH2">OAuth2</option>
                                                                                <option value="HEADERS">Headers</option>
                                                                                <option value="APIKEY">API Key</option>
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div> */}
                                                        </div>
                                                        </div>
                                                </div>
                                            </div>

                                            {(activeTab == 2 || activeTab == 3) && authType === 'OAUTH2' && (
                                                <OAuth2OptionsHelper values={values} setFieldValue={setFieldValue} setTouched={setTouched} />
                                            )}
                                            {activeTab == 3 && authType === 'HEADERS' && (
                                                <HeadersOptionsHelper headers={headers} setHeaders={setHeaders} values={values} updateHeaders={setHeaders} />
                                            )}
                                            <div className="text-center mt-3">
                                                <Button htmlType="submit" type="primary" size={'large'} loading={isSubmitting} disabled={isSubmitting}>Scan<span></span></Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Modal >
    );
}