import React, { useEffect, useState, useRef } from "react";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, Button, Radio } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../shared/sharedComponents/loader";
import TrendGraph from './trendGraph';
import VulnerableRepoGraph from './vulnerableRepoGraph';
import HorizontalBarGraph from "./horizontalBarGraph";
import DashboardSeverityIndicator from "../../shared/sharedComponents/dashboardSeverityIndicator";
import IScanModal from "./iScanModal";
import { setIScanModalState, setDastDashboardTableData, setDastScanTriggeredState, updateDastTriggeredScans } from "../../shared/redux/reducers/dastReducer";
import dastWelcomeImg from '../../assets/img/dast.svg';
import { GlobalConst } from "../../shared/appConfig/globalConst";
import DastStatisticsTable from "./dastStatisticsTable"
import { capitalizeFirstLetter, getPlanTypeFromProductKey, getProductSubscription } from "../../shared/helper/genHelper";
import { TbGridScan } from "react-icons/tb";
import { VscDebugConsole } from "react-icons/vsc";


export default function DastDashboard() {
    const dispatch = useDispatch();
    const dastReducer = useSelector(state => state.dastReducer);
    const subscribedProductsReducer = useSelector(state => state.productsReducer);

    const [loadingRows, setLoadingRows] = useState(false);
    const [dastDashboardData, setDastDashboardData] = useState({});
    const [hasError, setHasError] = useState(false);
    const [trendValue, setTrendValue] = useState('7');
    const intervalId = useRef(null);

    const colorArray = [
        '#785add',
        '#2db46b',
        '#3f80e2'
    ];

    useEffect(() => {
        getDashboardSummary();
    }, [])

    useEffect(() => {
        if (dastReducer.dastScanTriggered === false) {
            getDashboardSummary();
        }
    }, [dastReducer.dastScanTriggered]);

    useEffect(() => {
        if (dastReducer && !_.isEmpty(dastReducer.dastTriggeredScans)) {
            if (intervalId.current) {
                clearInterval(intervalId.current);
            }
            intervalId.current = setInterval(() => {
                poll(dastReducer.dastTriggeredScans);
            }, GlobalConst.DAST_POLLING_LIMIT);
            return () => {
                if (intervalId.current) {
                    clearInterval(intervalId.current);
                }
            };
        }

    }, [dastReducer.dastTriggeredScans]);

    const poll = (reportIds) => {
        axios
            .post(`${GlobalConst.API_URL}/auth/dast/asset/report/status`, { reportId: reportIds })
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    const updatedDastTableData = dastReducer.dastDashTableData.map(record => {
                        const isPresent = op.data.find(opRecord => opRecord.reportId === record.reportId);
                        return isPresent ? isPresent : record;
                    });

                    dispatch(setDastDashboardTableData(updatedDastTableData));

                    const scanningOrInitiated = _.chain(updatedDastTableData)
                        .filter(record => record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toLowerCase() === 'initiated')
                        .map('reportId')
                        .value();

                    if (_.isEmpty(scanningOrInitiated)) {
                        clearInterval(intervalId.current);
                        dispatch(setDastScanTriggeredState(false));
                        dispatch(updateDastTriggeredScans([]));
                    }
                }
            })
            .catch(e => { console.log("Exception: ", e) });
    };

    const getDashboardSummary = () => {
        setLoadingRows(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/dast/dashboard/summary`)
            .then(op => {
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setDastDashboardData(op.data);
                    dispatch(setDastDashboardTableData(op.data.assetSummaries));

                    if (!_.isEmpty(op.data.assetSummaries)) {
                        const scanningOrInitiated = _.chain(op.data.assetSummaries)
                            .filter(record => record.scanStatus.toLowerCase() === 'scanning' || record.scanStatus.toLowerCase() === 'initiated')
                            .map('reportId')
                            .value();
                        if (!_.isEmpty(scanningOrInitiated)) {
                            dispatch(setDastScanTriggeredState(true));
                            dispatch(updateDastTriggeredScans(scanningOrInitiated));
                        }

                    }

                } else {
                    setHasError(true);
                }
            })
            .catch(e => {
                console.log("Exception: ", e);
                setDastDashboardData(null)
                setHasError(true);
            })
            .finally(() => {
                setLoadingRows(false);
            });
    };

    const handleTrendFilterChange = (e) => {
        setTrendValue(e.target.value);
    };

    const handleDashboardReload = () => {
        window.location.reload();
    };

    const handleiScanClick = () => {
        dispatch(setIScanModalState(true));
    };

    if (loadingRows) {
        return <Loader />;
    } else {
        return (
            <section className="mx-3">
                <section className="mb-0">
                    <div className="d-flex align-items-start justify-content-between">
                        <div className="d-flex">
                            <h2 className="sotcox-title mb-0 text-white d-flex align-items-center justify-content-start me-2">
                                <VscDebugConsole className="me-2" /> Dast Dashboard
                                <Tooltip title="Scan a repository">
                                    <button className="btn btn-warning py-1 ms-3" onClick={handleiScanClick}>
                                        <TbGridScan className={'me-2'} />
                                        iScan
                                    </button>
                                </Tooltip>
                                <Tooltip title="Refresh">
                                    <button className="btn btn-primary py-1 ms-2" onClick={handleDashboardReload}>
                                        <FontAwesomeIcon icon={faRotateRight} />
                                    </button>
                                </Tooltip>
                            </h2>
                        </div>
                        {!_.isEmpty(subscribedProductsReducer.subscribedProducts.activePlans) &&
                            !_.isEmpty(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.DAST))
                            && <div><span className="product-plan-text">Plan - <span className={`${getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.DAST).sProductKey) === 'free' ? 'free-plan' : 'non-free-plan'}`}>{capitalizeFirstLetter(getPlanTypeFromProductKey(getProductSubscription(subscribedProductsReducer.subscribedProducts.activePlans, GlobalConst.PRODUCT_KEYS.DAST).sProductKey))}</span></span></div>
                        }
                    </div>
                </section>

                {(!_.isEmpty(dastDashboardData) && !_.isEmpty(dastDashboardData.assetSummaries)) ?
                    <>
                        <section>
                            <div className="row">
                                <div className="col-lg-6 custom-border-right">
                                    <div className="p-3 ps-0 d-flex align-items-center w-100 h-100">
                                        <div className="card-body">
                                            <div className="d-flex flex-row align-items-start justify-content-between">
                                                <div className="me-3">
                                                    <p className="mb-0 text-secondary" style={{ fontSize: 12 }}>Total Vulnerabilities</p>
                                                    <h1 className="head-tag" style={{ lineHeight: 1.1 }}>{dastDashboardData.totalVulnerabilityCount}</h1>
                                                </div>
                                                <div className="d-flex flex-wrap flex-row align-items-start justify-content-center">
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Critical'} loadingRows={dastReducer.dastScanTriggered} dashboardData={dastDashboardData.critical} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'High'} loadingRows={dastReducer.dastScanTriggered} dashboardData={dastDashboardData.high} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Medium'} loadingRows={dastReducer.dastScanTriggered} dashboardData={dastDashboardData.medium} />
                                                    </div>
                                                    <div className="me-3 mb-2">
                                                        <DashboardSeverityIndicator severity={'Low'} loadingRows={dastReducer.dastScanTriggered} dashboardData={dastDashboardData.low} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="p-2">
                                        <div className="card-body">
                                            <div className="mb-3 border-0 d-flex align-items-center justify-content-between">
                                                <h5 className="fw-medium">Vulnerability Trend</h5>
                                                <div>
                                                    <Radio.Group value={trendValue} size="small" buttonStyle="solid" onChange={(e) => handleTrendFilterChange(e)}>
                                                        <Radio.Button value="7" style={{ fontSize: 12 }}>1 Week</Radio.Button>
                                                        <Radio.Button value="14" style={{ fontSize: 12 }}>2 Weeks</Radio.Button>
                                                        <Radio.Button value="30" style={{ fontSize: 12 }}>1 Month</Radio.Button>
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                            <div style={{ height: 300 }}>
                                                <TrendGraph trendValue={trendValue} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {!_.isEmpty(dastDashboardData.assetSummaries) && <section>
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <DastStatisticsTable dastTableData={dastDashboardData.assetSummaries} />
                                    </div>
                                </div>
                            </div>
                        </section>}
                        <section>
                            <div className="row">
                                {!_.isEmpty(dastDashboardData.topVulnerableApplication) && <div className="col-lg-6 custom-border-right">
                                    <div className="p-3">
                                        <div className="card-body">
                                            <div className="mb-3 border-0">
                                                <h5 className="fw-medium">Top 3 Vulnerable Web Applications</h5>
                                            </div>
                                            <div className="row d-flex align-items-center justify-content-start">
                                                {
                                                    dastDashboardData.topVulnerableApplication.map((row, index) => {
                                                        return (
                                                            <div className="col-6 col-sm-4" key={`vulnerable-repos-${index}`}>
                                                                <div className="p-3">
                                                                    <VulnerableRepoGraph data={row} assetUrl={row.assetUrl} vulnerabilityCount={row.vulnerabilityCount} totalCve={dastDashboardData.totalVulnerabilityCount} strokeColor={colorArray[index]} />
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {!_.isEmpty(dastDashboardData.topRuleFailures) && <div className="col-lg-6">
                                    <div className="p-3">
                                        <div className="card-body">
                                            <div className="mb-3 border-0">
                                                <h5 className="fw-medium">Top 3 Vulnerabilities</h5>
                                            </div>
                                            <div style={{ width: "100%", height: 200 }}>
                                                <HorizontalBarGraph data={dastDashboardData.topRuleFailures} />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </section>
                    </> :
                    <div className="text-center mx-4 mt-4">
                        {dastDashboardData === null ?
                            <div className="alert alert-danger">We encountered an issue retrieving the scanned applications. Please try again later.</div>
                            :
                            <>
                                <img src={dastWelcomeImg} alt="welcome" className="mb-3" style={{ width: 200 }} />
                                <p className="text-white mb-4">
                                    {dastReducer.dastScanTriggered ? <>Your first scan is in progress. Please wait while we complete scanning your application.</>
                                        : <>It looks like you haven't scanned any applications yet. Begin by scanning your first application to get started.</>}
                                </p>
                                <Button className="sotcox-btn-warning btn-warning btn" onClick={handleiScanClick} loading={dastReducer.dastScanTriggered}>Scan Application</Button>
                            </>
                        }
                    </div>}
                {dastReducer.iScanModalState && <IScanModal />}
            </section>
        );
    }
}