import React, { useEffect, useState } from 'react';
import _ from 'lodash';
// import dataSet from "./tree.json"
import TidyTreeView from './tree/tidyTree';
import { faArrowLeft, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { GlobalConst } from '../../../shared/appConfig/globalConst';
import Loader from "../../../shared/sharedComponents/loader";
import NoDataFound from '../../../shared/sharedComponents/noDataFound';
import { Button, Drawer, Switch, Tooltip } from 'antd';
import { getAssetIcon } from '../../../shared/helper/getAssetImagesHelper';
import { useHistory } from "react-router-dom";
import ComponentDataTable from './componentDataTable';
import VulnerabilityLegend from './tree/legend';

export default function VulTreeView({ reportId, showComponentTreeModal, setShowComponentTreeModal }) {
    const [treeData, setTreeData] = useState([]);
    const [reportDetails, setReportDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showAllComponents, setShowAllComponents] = useState(false);
    const [filteredTreeData, setFilteredTreeData] = useState([]);
    const [selectedSeverity, setSelectedSeverity] = useState([]);
    const [showPackageDrawer, setShowPackageDrawer] = useState(false);
    const [packageDetails, setPackageDetails] = useState({});

    const history = useHistory();

    useEffect(() => {
        if (!_.isEmpty(treeData) && !_.isEmpty(reportDetails)) {
            const tree = processTreeForReport(treeData, reportDetails);
            const filtered = filterTreeData(
                tree,
                showAllComponents ? false : true,
                selectedSeverity);
            setFilteredTreeData(filtered);
        }
    }, [treeData, showAllComponents, selectedSeverity, reportDetails]);

    useEffect(() => {
        if (!_.isEmpty(reportDetails.scannerResponseEntity) && !_.isEmpty(reportDetails.scannerResponseEntity.scanResult)) {
            getDependencyTree(reportDetails)
        }
    }, [reportDetails])

    const processTreeForReport = (tree, reportDetails) => {
        const convertPackageFormat = (componentName) => {
            // Return early if already in correct format
            if (componentName.includes('@')) {
                return componentName;
            }

            // Split the string by colons
            const parts = componentName.split(':');

            // If we don't have exactly 3 parts (group:artifact:version), return original
            if (parts.length !== 3) {
                return componentName;
            }

            // Convert format from "group:artifact:version" to "group/artifact@version"
            const [group, artifact, version] = parts;
            return `${group}/${artifact}@${version}`;
        };

        const getVulnerabilityType = (cves) => {
            let highestSeverity = 'NA'; // Default to lowest priority
            if (!cves || cves.length === 0) return highestSeverity;

            // Loop through each CVE
            cves.forEach((el, index) => {
                const existingSeverity = (!_.isEmpty(el) && el.cvssV3BaseSeverity) ? el.cvssV3BaseSeverity : el.cvssV2BaseSeverity
                if (existingSeverity &&
                    (highestSeverity === 'NA' || isHigherPriority(highestSeverity, existingSeverity))) {
                    highestSeverity = existingSeverity.toUpperCase();
                }
            });

            return highestSeverity
        }

        const checkDependencyPackageFromList = (dependencyComponent, packagesData) => {
            // Get scan result object
            // Convert the format
            const normalizedComponentName = convertPackageFormat(dependencyComponent);

            // Find the matching key in scanResult
            const objectKey = Object.keys(packagesData).find(key => {
                // Remove any package prefix (pkg:maven/, pkg:npm/, etc.) for comparison
                const normalizedKey = key.replace(/^pkg:[^/]+\//, '');
                return normalizedKey === normalizedComponentName;
            });

            return objectKey
        };

        const getNodeName = (nodeTitle) => {
            let splitStringCollection = nodeTitle.split(":")
            splitStringCollection.shift()
            return splitStringCollection.join("@")
        }

        const processNode = (node, isParent = false) => {
            // Create new node with same structure
            const newNode = {
                parent: node.parent,
                name: node.title,
                children: [],
                level: node.level
            };

            const { scanResult: packages, ...application } = reportDetails.scannerResponseEntity.scanResult[0]
            const packageDataKey = checkDependencyPackageFromList(node.title, packages)
            if (!_.isEmpty(packageDataKey)) {
                newNode.key = packageDataKey
                newNode.packageDetails = packages[packageDataKey]
                newNode.applicationData = application
                newNode.packageName = packageDataKey.split('/').pop()
                newNode.severity = getVulnerabilityType(packages[packageDataKey].cves);
                newNode.vulnerabilityCounts = calculateVulnerabilityCounts(packages[packageDataKey].cves)
            } else {
                newNode.packageName = reportDetails.scannerResponseEntity.appName
            }

            // Process children recursively
            if (node.children && node.children.length > 0) {
                newNode.children = node.children.map(child => processNode(child));
                // // Aggregate vulnerability counts from children
                // newNode.children.forEach(child => {
                //     if (child.vulnerabilityCounts) {
                //         Object.keys(child.vulnerabilityCounts).forEach(severity => {
                //             newNode.vulnerabilityCounts[severity] += child.vulnerabilityCounts[severity];
                //         });
                //     }
                // });
            }

            return newNode;
        }

        const calculateVulnerabilityCounts = (cves) => {
            const counts = {
                CRITICAL: 0,
                HIGH: 0,
                MEDIUM: 0,
                LOW: 0,
                NA: 0,
                total: 0
            };

            if (!cves || cves.length === 0) return counts;

            cves.forEach(cve => {
                const severity = (!_.isEmpty(cve) && cve.cvssV3BaseSeverity)
                    ? cve.cvssV3BaseSeverity.toUpperCase()
                    : (cve.cvssV2BaseSeverity ? cve.cvssV2BaseSeverity.toUpperCase() : 'NA');

                counts[severity] = (counts[severity] || 0) + 1;
                counts.total += 1;
            });

            return counts;
        };

        return processNode(tree, true);
    }

    const getDependencyTree = (reportDetails) => {
        // console.log("I am the search result here:", searchResults);
        // return;
        let reqBody = {
            location: reportDetails.scannerResponseEntity.assetUrl,
            scanPath: reportDetails.scannerResponseEntity.scanResult[0].scanFileLocation,
            reportId: reportDetails.scannerResponseEntity.reportId,
            branch: reportDetails.scannerResponseEntity.branch
        };
        // console.log("Request body::", reqBody);
        setLoading(true);
        axios
            .post(`${GlobalConst.API_URL}/auth/foss/dependency-tree`, reqBody)
            .then((op) => {
                // console.log("Output ::", op);
                setLoading(false);
                if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                    setTreeData(op.data)
                    // console.log("treeData: ", op.data);
                }


            })
            .catch(e => { console.log("Exception: ", e); setLoading(false); setTreeData(null) });
    }

    useEffect(() => {
        setLoading(true);
        if (reportId) {
            axios
                .get(`${GlobalConst.API_URL}/auth/foss/report/${reportId}?st=${false}`)
                .then((op) => {
                    setLoading(false);
                    // console.log("Output is ::", op);
                    if (!_.isEmpty(op) && !_.isEmpty(op.data)) {
                        setReportDetails(op.data)
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    console.log("Exception: ", e);
                });
        }
    }, [reportId]);

    const hideComponentTreeModal = () => {
        setShowComponentTreeModal();
    }

    const isHigherPriority = (current, newSeverity) => {
        const severityMap = { CRITICAL: 4, HIGH: 3, MEDIUM: 2, LOW: 1, NA: 0 };
        return (severityMap[newSeverity.toUpperCase()] || 0) > (severityMap[current.toUpperCase()] || 0);
    };

    const filterTreeData = (node, showOnlyVulnerable = false, selectedSeverity) => {
        if (!node) return null;
    
        // Check if the current node has CVEs
        let hasVulnerabilities = false;
        if (!_.isEmpty(node.packageDetails)) {
            hasVulnerabilities = node.packageDetails.cves && node.packageDetails.cves.length > 0;
        }
    
        // Handle severity filters
        let matchesSeverity = true;
        if (!_.isEmpty(selectedSeverity)) {
            // Only set matchesSeverity to false if the node has vulnerabilities
            if (hasVulnerabilities) {
                matchesSeverity = false;
    
                if (node.packageDetails?.cves) {
                    // Separate severity types
                    const hasKevFilter = selectedSeverity.includes("kev");
                    const hasZerodayFilter = selectedSeverity.includes("zeroday");
                    const regularSeverities = selectedSeverity.filter(s => !["kev", "zeroday"].includes(s));
                    
                    let matchesAnyFilter = false;
    
                    // Check KEV filter
                    if (hasKevFilter) {
                        const hasKevVuln = node.packageDetails.cves.some(cve => cve.kev === true);
                        matchesAnyFilter = matchesAnyFilter || hasKevVuln;
                    }
    
                    // Check Zeroday filter
                    if (hasZerodayFilter) {
                        const hasZeroDayVuln = node.packageDetails.cves.some(cve => cve.zeroDay === true);
                        matchesAnyFilter = matchesAnyFilter || hasZeroDayVuln;
                    }
    
                    // Check regular severity filters
                    if (regularSeverities.length > 0) {
                        const matchesSeverityLevel = regularSeverities.includes(node.severity);
                        matchesAnyFilter = matchesAnyFilter || matchesSeverityLevel;
                    }
    
                    // If no special filters are selected, fall back to regular severity matching
                    if (!hasKevFilter && !hasZerodayFilter && regularSeverities.length === 0) {
                        matchesAnyFilter = true;
                    }
    
                    matchesSeverity = matchesAnyFilter;
                }
            }
        }
    
        // Process children first
        let filteredChildren = [];
        if (node.children && node.children.length > 0) {
            filteredChildren = node.children
                .map(child => filterTreeData(child, showOnlyVulnerable, selectedSeverity))
                .filter(child => child !== null);
        }
    
        // Root node should always be included
        if (node.level === 0) {
            return {
                ...node,
                children: filteredChildren
            };
        }
    
        // If showing only vulnerable components
        if (showOnlyVulnerable) {
            if ((hasVulnerabilities && matchesSeverity) || filteredChildren.length > 0) {
                return {
                    ...node,
                    children: filteredChildren
                };
            }
            return null;
        }
    
        // For nodes with vulnerabilities, apply severity filtering
        if (hasVulnerabilities) {
            if (matchesSeverity || filteredChildren.length > 0) {
                return {
                    ...node,
                    children: filteredChildren
                };
            }
            return null;
        }
    
        // Include non-vulnerable components only if they have matching children
        if (filteredChildren.length > 0) {
            return {
                ...node,
                children: filteredChildren
            };
        }
    
        // When showing all components, include non-vulnerable components
        return !showOnlyVulnerable ? {
            ...node,
            children: filteredChildren
        } : null;
    };

    const onSelectedPackage = (item) => {

    };

    const handleAutoFixClick = () => {
        history.push(`/fix-vulnerabilities/${reportId}`);
    };

    const showButton = (sr) => {
        // console.log("Search Results:=>", sr);
        if (!_.isEmpty(sr) && !_.isEmpty(sr.scanResult)) {
            const containsEcosystem = _.some(sr.scanResult, obj => _.some(GlobalConst.AUTO_FIX_ECOSYSTEMS_TO_CHECK, ecosystem => _.includes(obj.scanType, ecosystem)));

            // Output the result
            if (containsEcosystem) {
                // console.log("The array contains an object with 'scanType' containing 'maven'.");
                return true;
            } else {
                // console.log("The array does not contain an object with 'scanType' containing 'maven'.");
                return false;
            }
        }
    }

    const onClose = () => {
        setShowPackageDrawer(false);
        setPackageDetails({})
    };

    const showComponentDetails = (selectedPackage) => {
        setShowPackageDrawer(true)
        setPackageDetails(selectedPackage)
    }

    const filterTreeBySeverity = (selected) => {
        setSelectedSeverity(selectedSeverity.includes(selected) ? selectedSeverity.filter((currentSeverity) => currentSeverity !== selected) : [...selectedSeverity, selected])
    }

    if (loading) {
        return <Loader />
    }
    return (<>
        <Modal show={showComponentTreeModal} fullscreen={true} onHide={hideComponentTreeModal} style={{ zIndex: 1060 }}>
            <Modal.Header closeButton>
                <a className='me-3' onClick={hideComponentTreeModal} href='#' style={{ fontSize: 22 }}><small ><FontAwesomeIcon icon={faArrowLeft} /></small></a>
                <Modal.Title>
                    {!_.isEmpty(reportDetails) &&
                        !_.isEmpty(reportDetails.scannerResponseEntity) && <span>
                            {getAssetIcon(reportDetails.scannerResponseEntity.assetSubtype)}
                            <span >
                                {reportDetails.scannerResponseEntity.appName}
                            </span>
                            <span className='ms-2'>software composition</span>
                            {/* {
                                showButton(reportDetails.scannerResponseEntity) && (
                                    <Tooltip title='Apply Auto Fix'>
                                        <Button
                                            type="primary"
                                            onClick={() => handleAutoFixClick()}
                                            className="mx-3 fix-btn"
                                        >
                                            <FontAwesomeIcon icon={faWandMagicSparkles} />
                                        </Button>
                                    </Tooltip>

                                )
                            } */}
                        </span>}
                </Modal.Title>
                <div className="position-absolute end-0 pe-4 me-4">
                    <Switch
                        checked={showAllComponents}
                        onChange={setShowAllComponents}
                    />{" "}
                    Show all components
                </div>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className='p-2 px-3 scroll--simple' style={{ height: 'calc(100vh - 56px)', overflowY: 'auto' }}>
                    <section className="mx-3">
                        <div className="mb-4">
                            <div className="row">
                                <div
                                    className="col-sm-12"
                                >
                                    {!loading && _.isEmpty(treeData) && _.isEmpty(reportDetails)
                                        && <NoDataFound title={'No Dependency Data Found'} desc={"No dependency data available for the selected project"} />}
                                    {!_.isEmpty(reportDetails) && !_.isEmpty(treeData) && (
                                        <>
                                            <div className='position-absolute start-0 mt-5'>
                                                <div className="d-flex justify-content-end align-items-center gap-3 mb-3 mt-5" style={{ paddingLeft: "82px" }}>
                                                    <VulnerabilityLegend
                                                        reportDetails={reportDetails}
                                                        onSelectFilter={filterTreeBySeverity}
                                                        selectedFilter={selectedSeverity}
                                                    />
                                                </div>
                                            </div>
                                            <TidyTreeView
                                                dataSet={filteredTreeData}
                                                showComponentDetails={showComponentDetails}
                                                handleAutoFixClick={handleAutoFixClick}
                                                selectedSeverity={selectedSeverity}
                                            />
                                        </>)}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </Modal.Body >
        </Modal >
        <Drawer
            open={showPackageDrawer}
            // title="Basic Drawer"
            title={
                <span className="text-white">{packageDetails.name}</span>
            }
            placement={'bottom'}
            onClose={onClose}
            closable={true}
            className="custom-black-drawer vul-drawer"
            rootStyle={{ zIndex: 1070 }}
        >
            <div className="d-flex flex-column">
                <div className="row">
                    <div className={`col-sm-12`}>
                        <ComponentDataTable
                            dataSet={packageDetails}
                        />
                    </div>
                </div>
            </div>
        </Drawer>
    </>);
}
