import React, { useState } from 'react';
import { Field } from 'formik';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// Define the functional component
export const OAuth2OptionsHelper = (values, setFieldValue, setTouched) => {
    const [showClientSecret, setShowClientSecret] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // console.log("oauth2 : ", values, setFieldValue, setTouched);

    return (
        <>
            <div className="row mb-2 align-items-center">
                <div className="col-sm-2">Auth URL</div>
                <div className="col-sm-10">
                    <Field name="authUrl" type="text" className="input input-rounded form-control" style={{ padding: '10px 20px' }} />
                </div>
            </div>
            <div className="row mb-2 align-items-center">
                <div className="col-sm-2">Realm Name</div>
                <div className="col-sm-10">
                    <Field name="realmName" type="text" className="input input-rounded form-control" style={{ padding: '10px 20px' }} />
                </div>
            </div>
            <div className="row mb-2 align-items-center">
                <div className="col-sm-2">Client ID</div>
                <div className="col-sm-10">
                    <Field name="clientId" type="text" className="input input-rounded form-control" style={{ padding: '10px 20px' }} />
                </div>
            </div>
            <div className="row mb-2 align-items-center">
                <div className="col-sm-2">Client Secret</div>
                <div className="col-sm-10">
                    <div className="input-group">
                        <Field
                            name="clientSecret"
                            type={showClientSecret ? 'text' : 'password'}
                            className="input input-rounded form-control"
                            style={{ padding: '10px 20px' }}
                        />
                        <span className="input-group-text" onClick={() => setShowClientSecret(!showClientSecret)}>
                            <FontAwesomeIcon icon={showClientSecret ? faEyeSlash : faEye} />
                        </span>
                    </div>
                </div>
            </div>
            <div className="row mb-2 align-items-center">
                <div className="col-sm-2">User ID</div>
                <div className="col-sm-10">
                    <Field name="userId" type="text" className="input input-rounded form-control" style={{ padding: '10px 20px' }} />
                </div>
            </div>
            <div className="row mb-2 align-items-center">
                <div className="col-sm-2">Password</div>
                <div className="col-sm-10">
                    <div className="input-group">
                        <Field
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            className="input input-rounded form-control"
                            style={{ padding: '10px 20px' }}
                        />
                        <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};


